import React from "react";
import { FieldArray, Form, ErrorMessage } from "formik";
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError";
import { Row, Col } from "reactstrap";
import SelectWithData from "views/pages/FormSupplier/Common/Inputs/SelectWithData";
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError";

const Step4 = (props) => {
  const { formRef, values, setFieldValue, contractors } = props;

  const duplicateInfo = (values) => {
    values.contactDetailsBuyers.forEach((buyer, i) => {
      setFieldValue(
        `contactDetailsBuyers.${i}.company`,
        values.contactDetailsBuyers[0].company
      );
      setFieldValue(
        `contactDetailsBuyers.${i}.lastname`,
        values.contactDetailsBuyers[0].lastname
      );
      setFieldValue(
        `contactDetailsBuyers.${i}.phone`,
        values.contactDetailsBuyers[0].phone
      );
      setFieldValue(
        `contactDetailsBuyers.${i}.email`,
        values.contactDetailsBuyers[0].email
      );
      setFieldValue(
        `contactDetailsBuyers.${i}.emailDuplicate`,
        values.contactDetailsBuyers[0].emailDuplicate
      );
      setFieldValue(
        `contactDetailsBuyers.${i}.function`,
        values.contactDetailsBuyers[0].function
      );
    });
  };

  return (
    <Form className="mb-4" id="step4" ref={formRef}>
      <ScrollToError />
      <h4>
        4.1 Coordonnées des interlocuteurs opérationnels visibles dans
        l’annuaire des acheteurs
      </h4>
      <p>
        Votre attention est attirée sur l’importance de renseigner avec
        exactitude et sans erreur les mails de diffusion au risque de ne pas
        recevoir les commandes par la suite.
      </p>
      <FieldArray
        name="contactDetailsBuyers"
        render={(arrayHelpers) => {
          const contactDetailsBuyers = values.contactDetailsBuyers;
          return (
            <div>
              <div className="sticky d-flex align-items-center">
                <Col xs="12" sm="2">
                  Entreprise
                </Col>
                <Col xs="12" sm="2">
                  Nom de l'interlocuteur
                </Col>
                <Col xs="12" sm="2">
                  Fonction
                </Col>
                <Col xs="12" sm="2">
                  Téléphone
                </Col>
                <Col xs="12" sm="2">
                  Mail sur lequel seront envoyés les bons de commande
                </Col>
                <Col xs="12" sm="2">
                  Mail duplicata sur lequel seront envoyés les bons de commande
                </Col>
              </div>
              {contactDetailsBuyers &&
                contactDetailsBuyers.map((contactDetailsBuyer, index) => (
                  <div className="card mb-2" key={index}>
                    <Row>
                      <Col xs="12">
                        <h5>
                          Lot : {contactDetailsBuyer.lot} -{" "}
                          {contactDetailsBuyer.department} -{" "}
                          {contactDetailsBuyer.departmentName}
                        </h5>
                        <hr />
                      </Col>
                      <Col xs="12" sm="2">
                        <SelectWithData
                          name={`contactDetailsBuyers.${index}.company`}
                          items={contractors}
                        />
                      </Col>
                      <Col xs="12" sm="2">
                        <FieldWithError
                          name={`contactDetailsBuyers.${index}.lastname`}
                          placeholder={"Nom de l'interlocuteur"}
                        />
                      </Col>
                      <Col xs="12" sm="2">
                        <FieldWithError
                          name={`contactDetailsBuyers.${index}.function`}
                          placeholder={"Fonction"}
                        />
                      </Col>
                      <Col xs="12" sm="2">
                        <FieldWithError
                          name={`contactDetailsBuyers.${index}.phone`}
                          placeholder={"Téléphone"}
                        />
                      </Col>
                      <Col xs="12" sm="2">
                        <FieldWithError
                          name={`contactDetailsBuyers.${index}.email`}
                          placeholder={
                            "Mail sur lequel seront envoyés les bons de commande"
                          }
                        />
                      </Col>
                      <Col xs="12" sm="2">
                        <FieldWithError
                          name={`contactDetailsBuyers.${index}.emailDuplicate`}
                          placeholder={
                            "Mail duplicata sur lequel seront envoyés les bons de commande"
                          }
                        />
                      </Col>
                      <Col xs="12">
                        {index === 0 && (
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn-white"
                              onClick={() => {
                                duplicateInfo(values);
                              }}
                            >
                              Dupliquer les informations sur les lots ci-dessous
                            </button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
            </div>
          );
        }}
      />

      <h4>
        4.2 Coordonnées bancaires du contractant unique ou du mandataire
        concernant le paiement des prestations
      </h4>
      <div className="card">
        <Row>
          <Col xs="12">
            <label>Nom du titulaire du compte</label>
            <FieldWithError
              name={"bankDetails.organization"}
              placeholder={""}
              readonly
            />
          </Col>
          <Col xs="12" sm="4">
            <label>IBAN</label>
            <FieldWithError name={"bankDetails.iban"} placeholder={""} />
          </Col>
          <Col xs="12" sm="4">
            <label>BIC</label>
            <FieldWithError name={"bankDetails.bic"} placeholder={""} />
          </Col>
          <Col xs="12" sm="4">
            <label>RIB (JPG ou PDF, taille max 8mo)</label>
            <br />
            {values?.bankDetails?.rib ? (
              <a
                href={values.bankDetails.rib}
                target="_blank"
                rel="noopener noreferrer"
                className="fileLink"
              >
                Voir mon fichier chargé
              </a>
            ) : null}
            <input
              className="mr-sm-1 mx-sm-1 form-group my-1"
              name={"bankDetails.ribUpload"}
              type="file"
              onChange={(event) => {
                setFieldValue(
                  "bankDetails.ribUpload",
                  event.currentTarget.files[0]
                );
              }}
            />
            <ErrorMessage
              name={"bankDetails.ribUpload"}
              component="div"
              className="error-red"
            />
          </Col>
        </Row>
      </div>

      <h4>
        4.3 Coordonnées des interlocuteurs concernant la rémunération de
        Centralis
      </h4>
      <div className="card">
        <Row>
          <Col xs="12">
            <label>Contractant unique ou Mandataire</label>
            <FieldWithError
              name={"contactDetailsCentralis.organization"}
              placeholder={""}
              readonly
            />
          </Col>
          <Col xs="12" sm="6">
            <label>Nom de l’interlocuteur</label>
            <FieldWithError
              name={"contactDetailsCentralis.lastname"}
              placeholder={""}
            />
          </Col>
          <Col xs="12" sm="6">
            <label>Fonction</label>
            <FieldWithError
              name={"contactDetailsCentralis.function"}
              placeholder={""}
            />
          </Col>
          <Col xs="12" sm="6">
            <label>Téléphone</label>
            <FieldWithError
              name={"contactDetailsCentralis.phone"}
              placeholder={""}
            />
          </Col>
          <Col xs="12" sm="6">
            <label>
              Mail sur lequel seront envoyées les factures de Centralis
            </label>
            <FieldWithError
              name={"contactDetailsCentralis.email"}
              placeholder={""}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

Step4.propTypes = {};

Step4.defaultProps = {};

export default Step4;
