import React from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {useHistory} from "react-router-dom";
import {Container, Row, Col} from "reactstrap";
import moment from "moment/moment";
import "moment/locale/fr";
import {sessionOperations} from "state/ducks/session";
import {modalsOperations} from "state/ducks/modal";
import {centimesToCurrency} from "utils/Utils";

const Banner = (props) => {
    const {cart, user} = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const session = useSelector((state) => state.session, shallowEqual);

const cartId = cart.postal + cart.id + "F";
    const confirmCart = () => {
        if (
            user &&
            user.email &&
            user.roles &&
            user.roles.includes("ROLE_SUPPLIER")
        ) {
            dispatch(
                modalsOperations.getModalStatus(
                    "send_cart_to_customer",
                    true,
                    "big",
                    null,
                    {
                        cartId: cartId,
                        cartType: "formalized",
                        email: cart?.proposalEmail,
                        cartAddress: cart.address,
                        cartCountArticles: cart.countArticles,
                    }
                )
            );
        } else if (!cart.address) {
            window.alert(
                "Merci de renseigner et sauvegarder l'adresse de l'opération et d'accepter les conditions générales avant de confirmer votre panier."
            );
        } else {
            if (
                window.confirm(
                    "Voulez-vous vraiment commander les articles de ce panier ?"
                )
            ) {
                dispatch(sessionOperations.confirmCart(cart.id));
            }
        }
    };

    /**
     * Permet au super de prendre la main
     */
    const takeCart = () => {
        if (
            window.confirm(
                "Voulez-vous vraiment prendre la main de ce panier ? Le compte à l'origine du panier ne pourra plus y avoir accès."
            )
        ) {
            dispatch(sessionOperations.takeCart(cart.id));
        }
    };

    /**
     * Donne le panier au super
     * Redirige vers la page des paniers ensuite et recharge
     */
    const giveCart = () => {
        if (
            window.confirm(
                "Voulez-vous vraiment envoyer ce panier à l'administrateur ? Vous n'y aurez plus accès ensuite."
            )
        ) {
            dispatch(sessionOperations.giveCart(cart.id)).then((response) => {
                history.push("/compte/paniers");
                window.location.reload();
            });
        }
    };
    return (
        <div
            id="section-infos-detail-panier"
            style={{
                position: "sticky",
                height: "100px",
                top: "66px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Container>
                <Row>
                    <Col md="4">
                        <div className="date-detail-panier">
                            Panier du <b>{moment(cart.date).locale("fr").format("L")}</b>
                        </div>
                        <div>
                            <b>{cart.countArticles && cart.countArticles}</b> articles |
                            Montant Total HT :{" "}
                            <b>{cart.price && centimesToCurrency(cart.price)}</b>
                        </div>
                    </Col>
                    {cart.address && cart.countArticles > 0 && session.user.roles.includes("ROLE_SUPPLIER")? (
                        <>
                            <Col md="3">
                                <h1 className="title-infos-detail-panier">{cart.name}</h1>
                            </Col>
                            <Col md="2">
                                <h1 className="title-infos-detail-panier">Numéro panier  {cart.postal}{cart.id}F
                                </h1>

                            </Col>

                        </>
                    ) : (
                        <>
                            <Col md="5">
                                <h1 className="title-infos-detail-panier">{cart.name}</h1>
                            </Col>
                        </>
                    )}
                    <Col md="3">
                        {cart.orderLots && cart.orderLots.length > 0 ? (
                            <>
                                {session.user.roles.includes("ROLE_SUPER_CUSTOMER") ? (
                                    <>
                                        {cart.customer.id !== session.user.id ? (
                                            <button className="btn-green" onClick={() => takeCart()}>
                                                Prendre la main sur le panier
                                            </button>
                                        ) : (
                                            <button
                                                className="btn-green"
                                                onClick={() => confirmCart()}
                                            >
                                                Commander le panier
                                            </button>
                                        )}
                                    </>
                                ) : !session.user.roles.includes("ROLE_SUPER_CUSTOMER") &&
                                session.user.roles.includes("ROLE_CUSTOMER") ? (
                                    <button className="btn-green" onClick={() => giveCart()}>
                                        Envoyer le panier pour approbation à l’administrateur du
                                        compte
                                    </button>
                                ) : session.user.roles.includes("ROLE_SUPPLIER") ? (
                                    <button className="btn-green" onClick={() => confirmCart()}>
                                        Envoyer le panier au client
                                    </button>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <button className="btn-green">Panier vide</button>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
        ;
};

Banner.propTypes = {};

Banner.defaultProps = {};

export default Banner;
