import React from "react";
import { FieldArray, ErrorMessage } from 'formik';
import Contractor from "./Contractor"
import { INITIAL_FORM_SUPPLIER } from "constants/constants"
import swal from 'sweetalert';

const Contractors = (props) => {

  const {
    values,
    setFieldValue,
    errors
  } = props

  const removeContractorConfirm = (arrayHelpers, index) => {
    swal({
      title: "Etes vous de sûr de vouloir faire cette suppression ? ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
          arrayHelpers.remove(index)
        }
    });
  }

  return (
    <>
    <FieldArray
      name="contractors"
      render={arrayHelpers => {
        const contractors = values.contractors
        return (
          <div>
            {contractors && contractors.length > 0 && (
              contractors.map((contractor, index) => (
                <div key={index}>
                  <div className="card mb-4">
                    <Contractor values={values} index={index} setFieldValue={setFieldValue} errors={errors}/>
                    { index !== 0 &&
                      <div className="text-right">
                        <button
                          className="btn-red mt-2 mb-2"
                          type="button"
                          onClick={() => removeContractorConfirm(arrayHelpers, index)}
                        >
                          Supprimer ce contractant
                        </button>
                      </div>
                    }
                  </div>
                </div>
              ))
            )}

            {((values.typeCandidate === "single" && values.contractors.length === 0) || (values.typeCandidate === "businessGroup")) &&
              <>
                <br/>
                <button type="button" onClick={() => arrayHelpers.push(INITIAL_FORM_SUPPLIER.step3.contractors[0])} className="btn-grey">
                  Ajouter un contractant
                </button>
              </>
            }
            {typeof errors.contractors === 'string'&& <ErrorMessage name={"contractors"} component="div" className="error-red"/> }
          </div>
        );
      }}
    />

    </>
  );

};

Contractors.propTypes = {
};

Contractors.defaultProps = {

};

export default Contractors;
