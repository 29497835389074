import React from "react";
import { FormGroup, Row, Col, Input } from "reactstrap";
import LoadingSvg from "assets/img/icon-preloader-connect.svg";
import { useDispatch } from "react-redux";
import { sessionOperations } from "state/ducks/session";
import { useFormik } from "formik";
import * as Yup from "yup";
import { modalsOperations } from "state/ducks/modal";

const Login = (props) => {
  const { session } = props;

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Ce champ est requis"),
      username: Yup.string()
        .required("Ce champ est requis"),
    }),
    onSubmit: (values) => {
      // Connecte l'utilisateur, si la connexion est un succès, recupère le type de l'utilisateur pour gérer sa redirection
      dispatch(sessionOperations.login(values.username, values.password)).then(
        (result) => {
          if (result?.data) {
            dispatch(sessionOperations.getUserType()).then((res) => {
              if (res?.data) {
                switch (res.data) {
                  case "free":
                    window.location.href = "/catalogue/libre";
                    break;
                  case "formalized":
                    window.location.href = "/catalogue";
                    break;
                  default:
                    break;
                }
              }
            });
          }
        }
      );
    },
  });

  return (
    <Row>
      <Col md="12">
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <Input
              name="username"
              id="username"
              placeholder="Identifiant"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="input-error">{formik.errors.username}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Input
              name="password"
              id="password"
              placeholder="Mot de passe"
              autoComplete="off"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="input-error">{formik.errors.password}</div>
            ) : null}
          </FormGroup>

          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            id="btn-connect-modal"
            type="submit"
            className="btn-green"
            disabled={props.submitting}
          >
            <div
              style={{ lineHeight: "1", width: "100%", textAlign: "center" }}
            >
              Se connecter
            </div>
            <div
              style={
                session.isLoading
                  ? { marginLeft: "0", opacity: "1" }
                  : { marginLeft: "0", opacity: "0" }
              }
              className="preloader-connect-user"
            >
              <img src={LoadingSvg} alt="Loader" />
            </div>
          </button>
        </form>
      </Col>
      <Col md="12">
        <p
          style={{
            textAlign: "center",
            marginBottom: "0px",
            marginTop: "1rem",
            color: "#DB7124",
            cursor: "pointer",
          }}
          onClick={() =>
            dispatch(modalsOperations.getModalStatus("resend", true, null))
          }
        >
          Mot de passe oublié ?<br />
        </p>
      </Col>
    </Row>
  );
};

export default Login;
