import React from 'react';

const BlocInfos = (props) => {
    return(
        <div style={{textAlign: "center"}} className="bloc-panier">
            <div className="header-bloc-panier">
                <img height="auto" style={{width: "50px", marginBottom: "30px"}} src={props.icon} alt="Icon Panier"/>
                <h4>{props.title}</h4>
            </div>
        </div>
    )
}

export default BlocInfos 
