import React from "react";
import { Row, Col } from "reactstrap";

const Customer = (props) => {

    const {
      customer,
    } = props;

    return (
        <div className="customers-block">
            <Row>
                <Col md="12" className="text-left">
                    <div className="customer-name"><b>{customer.name.toUpperCase()}</b></div>
                </Col>
            </Row>
        </div>
    );

}

export default Customer;
