import React from 'react';
import Banner from "views/universal/Banner/Banner";
import Layout from "views/universal/Layout/Layout";

const NotFound = (props) => {
  return (
    <Layout>
      <Banner titleBanner="Erreur 404" desc="Cette page n'existe pas/plus"/>
    </Layout>
  );
}

export default NotFound;
