export const INITIAL_FORM_SUPPLIER = {
  id: null,
  identicalOffer: null,
  prices: [],
  stepInprogress: 0,
  token: null,
  phase: null,
  accepted: false,
  step10Alt: {
    prices: [],
    identicalOffer: null,
    filters:{
      region:null,
      department:null
    }
  },
  step2: {
    technicalCategory: '',
    regions: [],
    departments: [],
    fruitlessnessRegions: [],
    fruitlessnessLots: [],
    departmentInterlocutorIds: []
  },
  step3: {
    typeCandidate: null, // string
    typeBusinessGroup: '', // string
    mandatarySolidarity: '', // string
    contractors: [
      {  // array with objects
       contractorId: null,
       organization: '',  // string
       firstname: '',  // string
       lastname: '',  // string
       function: '',  // string
       typeOrganization: '',  // string
       capital: '',  // string
       siret: '',  // string
       rcs: '',  // string
       addressId: '',
       addressNumber: '',  // string
       addressStreet: '',  // string
       addressPostalCode: '',  // string
       addressCity: '',  // string
       phone: '',  // string
       email: '',  // string
       logo: null,  // file
       logoUpload: null,  // file
       fiscalRegularityText: '',  // string
       fiscalRegularityFile: null,  // file
       fiscalRegularityFileUpload: null,  // file
       companyInsuranceName: '',  // string
       insurancePolicyNumber: '',  // string
       companyInsuranceFile: null,  // file
       companyInsuranceFileUpload: null,  // file
       companyInsuranceText: '',  // string
       is10insuranceCertificate: false,  // bool
       isLegalRedress: false, // bool
       legalRedressFile: null,  // File
       legalRedressFileUpload: null,  // File
       legalRedressText: '',  // string
       isSwornStatement: false, // bool
       authorizationMandataryFile: null,  // File
       authorizationMandataryFileUpload: null,  // File
       authorizationMandataryText: ''  // string
     }
   ],
  },
  step4: {
    contactDetailsBuyers: [
      {
        lot: "",
        company: "",
        lastname: "",
        function: "",
        phone: "",
        email: "",
        emailDuplicate: "",
        department: "",
        departmentName: ""
     }
   ],
   contactDetailsBuyersSecondary: [],
   bankDetails: {
     organization: "",
     iban: "",
     bic: "",
     rib: null,
     ribUpload: null,
   },
   contactDetailsCentralis: {
     organization: "",
     lastname: "",
     function: "",
     phone: "",
     email: "",
   }
 },
  step5: {
    activitiesAptitudeDetails:[{
        organization: '',
        name: '',
        proofFileUpload: null,
        proofFile: null,
        proofText: '',
      }]
    },
  step6: {
   economicCapacity:[{
       organization: '',
       y1Revenue: '',
       y1PartOfRevenue: '',
       y2Revenue: '',
       y2PartOfRevenue: '',
       y3Revenue: '',
       y3PartOfRevenue: ''
     }]
   },
  step7: {
    averageAnnualWorkforce:[{
      organization: '',
      y1Average: '',
      y1PartStaff: '',
      y2Average: '',
      y2PartStaff: '',
      y3Average: '',
      y3PartStaff: '',
    }],
    mainDeliveries:[{
      organization: '',
      name: '',
      budget: '',
      year: '',
      proof: null,
      proofUpload: null,
      deliveryId: null
    }]
  },
  step8: {
    outSourcing : '',
    otherOperators: [
      {
        interlocutorId: null,
        organization: null,
        name: '',
        siret: ''
      }
    ]
  },
  step9: {
    authorizationSEPA: '',
    presentationHuman: '',
    presentationOperative: '',
    presentationEnvironment: '',
    proofs: [
      {
        proof: null,
        proofId: null,
        proofUpload: null
      }
    ]
  },
  step10: {
    identicalOffer: '',
    allDepartments: [
      {
        ref: '',
        name: '',
        limitA: '',
        limitB: '',
        unit: '',
        priceA: '',
        priceAB: '',
        priceB: ''
      }
    ],
    departments: [
      {
        id: "",
        name: "",
        code: "",
        articles: [{
          id: null,
          ref: '',
          name: '',
          limitA: '',
          limitB: '',
          unit: '',
          priceA: '',
          priceAB: '',
          priceB: ''
        }]
      }
    ],
    secondaryDepartments: [
      {
        id: "",
        name: "",
        code: "",
        articles: [{
          ref: '',
          name: '',
          limitA: '',
          limitB: '',
          unit: '',
          priceA: '',
          priceAB: '',
          priceB: ''
        }]
      }
    ],
  },
  step11: {
    acceptDeal: false,
    madeIn: '',
    previewPDF: ''
  },
  step12: {
    closeAndGenerate: false,
    finalPDF: ''
  }
}

export const SUPPORTED_FORMATS_IMAGES = ['image/jpg', 'image/jpeg', 'image/png'];
export const SUPPORTED_FORMATS_IMAGES_MESSAGE = "Formats JPG et PNG autorisés";
export const SUPPORTED_FORMATS_DOCUMENTS = ['image/jpg', 'image/jpeg', 'application/pdf'];
export const SUPPORTED_FORMATS_DOCUMENTS_MESSAGE = "Formats JPG et PDF autorisés";
export const FILE_SIZE = 8000000;
