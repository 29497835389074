import React from "react";
import { Row, Col } from "reactstrap";
import montant from 'assets/img/about/montant.jpg';
import { modalsOperations } from 'state/ducks/modal'
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const FreePriceBloc = (props) => {

    const dispatch = useDispatch();

    const isAuthenticated = useSelector(
      (state) => state.session.isAuthenticated,
      shallowEqual
    )

    return (
      <section id="section8" className="container section-bloc-about">
        <Row className="bloc-about">
          <Col sm={3} className="p-0 content-bloc-img"
          style=
          {{
            backgroundImage :  `url('${montant}')`,
            position: "relative",
            overflow: "hidden",
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}>
          </Col>
          <Col sm={9} className="align-self-center content-bloc content-bloc" style={{backgroundColor: '#DB7124', color: 'white'}}>
            <div style={{"textAlign": "center"}}>
              <h2 className="title-section" style={{color: "white"}}><strong>Gratuit</strong> et sans engagement</h2>
              <h4 style={{textAlign: 'center', fontSize: '2rem'}}><strong>0,00 €</strong></h4>
              <br/>
              <p style={{"textAlign": "justify"}}>L’utilisation de Centralis est gratuite pour les acheteurs, qui payent <strong>uniquement le montant des prestations commandées</strong>, et ce directement auprès des entreprises concernées. Depuis 2021, il n’y a plus d’abonnement ou de cotisation à régler à Centralis.<br/><br/>
              Comme intermédiaire, Centralis est rémunéré par les entreprises prestataires, à proportion des commandes qui leur sont confiées.
              </p>
              <div>
                <table className="table table-about" style={{color: "white"}}>
                  <thead>
                    <tr>
                      <th style={{background: "#5e5c5c", textAlign: "center", border: "2px #484747 solid"}} ><h4>Pour commander via les prestataires libres</h4></th>
                      <th style={{background: "#5e5c5c", textAlign: "center", border: "2px #484747 solid"}}><h4>Pour commander via les prestataires titulaires</h4></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{verticalAlign:"baseline"}}>
                        <p style={{"textAlign": "left"}}>
                          Il est possible de s’inscrire à tout moment (compte libre).
                          {!isAuthenticated &&
                            <strong onClick={()=> dispatch(modalsOperations.getModalStatus('register', true, 'big'))} style={{color: "#5e5c5c", cursor:"pointer", fontSize: "1.4rem"}}> S'inscrire</strong>
                          }
                        </p>
                      </td>
                      <td style={{verticalAlign:"baseline"}}>
                        <p style={{"textAlign": "left"}}>
                        Il faut soit, <strong>s’inscrire avant le 31 juillet des années paires</strong> afin de pouvoir commander à partir du 1er janvier de l’année impaire qui suit (le compte libre est alors transformé en compte formalisé).<br/><br/>
                        Soit, être membre, satellite ou filiale d’un acheteur disposant déjà d’un compte formalisé. L’accès au compte formalisé est alors immédiat. Le compte formalisé permet d’accéder aux deux catalogues, libre et formalisé.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </Col>
        </Row>
      </section>
    );

}

export default FreePriceBloc;
