import React from "react";
import { useDispatch } from "react-redux";
import Step8 from "./Step8"
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";

// Capacités des opérateurs économiques sur lesquels le candidat individuel ou le membre du groupement s’appuie pour présenter sa candidature
const Step8Container = (props) => {

  const {
    formRef,
    initialValues,
    contractors
  } = props

  const dispatch = useDispatch();

  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    outSourcing:Yup.string().nullable().required('Ce champ est requis'),
    otherOperators: Yup.array().of(
      Yup.object().shape({
        organization: Yup.string().nullable().required("Ce champ est requis"),
        name:  Yup.string().nullable().required("Ce champ est requis"),
        siret:  Yup.string().nullable().required("Ce champ est requis")
      })),
  })

  return (
    <>
    <Formik
       initialValues={initialValues.step8}
       validationSchema={validationSchema}
       onSubmit={values => {dispatch(formSupplierOperations.setStep8(values, initialValues.id))}}
     >
       {({ errors, touched, values, setFieldValue }) => (
         <>
          <Step8 formRef={formRef} values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} initialValues={initialValues} contractors={contractors}/>
         </>
       )}
     </Formik>
     </>
  );

};

Step8Container.propTypes = {
};

Step8Container.defaultProps = {

};

export default Step8Container;
