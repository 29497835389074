import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container, FormGroup, Row, Col, Input, Label } from "reactstrap";
import LoadingSvg from "assets/img/icon-preloader-connect.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sessionOperations } from "state/ducks/session";
import { modalsOperations } from "state/ducks/modal";

const RegisterCustomer = (props) => {
  const { departments } = props;

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      firstname: "",
      lastname: "",
      phone: "",
      registerCgu: false,
      size: "",
      address: {
        street: "",
        postal: "",
        city: "",
        department: "",
      },
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Merci de renseigner votre mail")
        .required("Ce champ est requis"),
      name: Yup.string().required("Ce champ est requis"),
      firstname: Yup.string().required("Ce champ est requis"),
      lastname: Yup.string().required("Ce champ est requis"),
      phone: Yup.string().required("Ce champ est requis"),
      registerCgu: Yup.bool().oneOf([true], "Vous devez accepter nos CGU"),
      address: Yup.object({
        city: Yup.string().required("Ce champ est requis"),
        postal: Yup.string().required("Ce champ est requis"),
        department: Yup.string().required("Ce champ est requis"),
        street: Yup.string().required("Ce champ est requis"),
      }),
    }),
    onSubmit: (values) => {
      // Laisse la modle ouverte en cas d'erreur
      dispatch(sessionOperations.registerCustomer(values)).then(
        (result) => {
          dispatch(
            modalsOperations.getModalStatus(
              "message",
              true,
              null,
              "Merci pour votre inscription. Vous allez recevoir par mail votre identifiant et votre mot de passe dès que nous aurons vérifié votre identité."
            )
          );
        },
        (error) => {}
      );
    },
  });

  return (
    <Container>
      <Row style={{ paddingTop: "5px" }}>
        <Col md="12">
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md="12">
                <h4>Comment s'appelle votre organisme ?</h4>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    name="name"
                    id="name"
                    placeholder="Nom de l'organisme*"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="input-error">{formik.errors.name}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="12">
                <h4>Quelle est l'adresse du siège ?</h4>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    name="address.street"
                    id="street"
                    placeholder="N et Rue"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address.street}
                  />
                  {formik.touched.address &&
                  formik.touched.address.street &&
                  formik.errors.address &&
                  formik.errors.address.street ? (
                    <div className="input-error">
                      {formik.errors.address.street}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="select"
                    name="address.department"
                    id="department"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.department}
                  >
                    <option defaultValue>Choisir un département</option>
                    {departments.data.map((department, index) => (
                      <option value={department.code} key={index}>
                        {department.code} - {department.name}
                      </option>
                    ))}
                  </Input>
                  {formik.touched.address &&
                  formik.touched.address.department &&
                  formik.errors.address &&
                  formik.errors.address.department ? (
                    <div className="input-error">
                      {formik.errors.address.department}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    name="address.postal"
                    id="postal"
                    placeholder="Code postal*"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address.postal}
                  />
                  {formik.touched.address &&
                  formik.touched.address.postal &&
                  formik.errors.address &&
                  formik.errors.address.postal ? (
                    <div className="input-error">
                      {formik.errors.address.postal}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    name="address.city"
                    id="city"
                    placeholder="Ville*"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address.city}
                  />
                  {formik.touched.address &&
                  formik.touched.address.city &&
                  formik.errors.address &&
                  formik.errors.address.city ? (
                    <div className="input-error">
                      {formik.errors.address.city}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="12">
                <h4>Qui sera l'administrateur du compte Acheteur ?</h4>
                <p>
                  <i>
                    Une fois le compte créé, vous pourrez ajouter des utilisateurs mais seul l'administrateur pourra approuver un panier et le commander.
                  </i>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    name="lastname"
                    id="lastname"
                    placeholder="Nom*"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="input-error">{formik.errors.lastname}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    name="firstname"
                    id="firstname"
                    placeholder="Prénom*"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="input-error">{formik.errors.firstname}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    name="email"
                    id="email"
                    placeholder="Email*"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="input-error">{formik.errors.email}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    name="phone"
                    id="phone"
                    placeholder="Téléphone*"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="input-error">{formik.errors.phone}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <hr />
            <Row>
              <Col md="1">
                <FormGroup check>
                  <Label check>
                    <Input
                      id="registerCgu"
                      type="checkbox"
                      name="registerCgu"
                      checked={formik.values.registerCgu}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.registerCgu}
                    />{" "}
                    *
                  </Label>
                </FormGroup>
              </Col>
              <Col md="11">
                {" "}
                J'ai lu et j'accepte les{" "}
                <u>
                  <Link to="/conditions-generales"> Conditions générales</Link>
                </u>
                {formik.touched.registerCgu && formik.errors.registerCgu ? (
                  <div className="input-error">{formik.errors.registerCgu}</div>
                ) : null}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="12">
                <p
                  style={{
                    textAlign: "right",
                    position: "absolute",
                    bottom: "0",
                    right: "40px",
                    fontSize: ".8em",
                  }}
                >
                  <em>*Champ requis</em>
                </p>
              </Col>
              <Col md="12">
                Si vous ne recevez pas votre identifiant et votre mot de passe dans les 48h, merci de nous contacter
              </Col>
              <Col md="12">
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  id="btn-connect-modal"
                  type="submit"
                  className="btn-green"
                >
                  <div
                    style={{
                      lineHeight: "1",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Inscription (gratuite et sans engagements)
                  </div>
                  <div
                    style={
                      1 === 0
                        ? { marginLeft: "0", opacity: "1" }
                        : { marginLeft: "0", opacity: "0" }
                    }
                    className="preloader-connect-user"
                  >
                    <img src={LoadingSvg} alt="Loader" />
                  </div>
                </button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterCustomer;
