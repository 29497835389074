import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col } from "reactstrap";
import Account from "./../Account";
import moment from "moment/moment";
import "moment/locale/fr";
import arrowPanier from "assets/img/arrow-product.svg";
import { centimesToCurrency } from "utils/Utils";
import { sessionOperations } from "state/ducks/session";

const ConfirmedCarts = () => {
  const session = useSelector((state) => state.session, shallowEqual);

  const dispatch = useDispatch();

  const [setPage] = useState(1);

  const renderBlocDetails = (value) => {
    const blocPanier = document.querySelector(`#c${value}`);
    blocPanier.style.display =
      blocPanier.style.display === "block" ? "none" : "block";
  };

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (
      session.isLoading === false &&
      session.init === true &&
      session.confirmedCarts.init === false &&
      initialized === false
    ) {
      dispatch(
        sessionOperations.getConfirmedCarts(session.user.id, 1, "formalized")
      );
      setInitialized(true);
    }
  }, [dispatch, session, initialized]);

  const loadMore = () => {
    setPage(prevPage => {
      const newPage = prevPage + 1;
      dispatch(
          sessionOperations.getConfirmedCarts(session.user.id, newPage, "formalized")
      );
      return newPage;
    });
  };

  const detailsCard = (panier) => {
    return (
      <div id={"c" + panier.id} className="bloc-panier-table">
        <div className="bloc-panier-header">
          <div>N°Bon De Commande</div>
          <div>Statut</div>
          <div>Département</div>
          <div className="bloc-panier-header-lot">Lot</div>
          <div>Fournisseur</div>
          <div>Total HT</div>
          <div>Lien</div>
        </div>
        <div className="bloc-panier-content">
          {panier.orderLots.map((orderLot, indexF) => {
            return (
              <div key={indexF}>
                <div className="bloc-panier-content-lot">
                  <div className="bloc-panier-content-lot-header">
                    <div>
                      {orderLot.orderLotSuppliers.map((orderLotSupplier, iii) => {
                        return (
                          <p key={iii}>
                            {orderLotSupplier.status > 0 &&
                              orderLotSupplier.number}
                          </p>
                        );
                      })}
                    </div>

                    <div>
                      {orderLot.orderLotSuppliers.map((orderLotSupplier, iii) => {
                        return (
                          <p key={iii}>
                            {orderLotSupplier.status > 0 &&
                              orderLotSupplier.statusTextFR}
                          </p>
                        );
                      })}
                    </div>

                    <div>{panier.postal}</div>
                    <div className="bloc-panier-content-lot-header-lot">
                      {orderLot.lotCode} – {orderLot.lotName}
                    </div>

                    <div>
                      {orderLot.orderLotSuppliers.map((orderLotSupplier, iii) => {
                        return (
                          <p key={iii}>
                            {orderLotSupplier.status > 0 &&
                              orderLotSupplier.name}
                          </p>
                        );
                      })}
                    </div>

                    <div>
                      {orderLot.orderLotSuppliers.map((orderLotSupplier, iii) => {
                        return (
                          <p key={iii}>
                            {orderLotSupplier.status > 0 &&
                              centimesToCurrency(orderLotSupplier.price)}
                          </p>
                        );
                      })}
                    </div>

                    <div>
                      <a
                        href={orderLot.publicUrl && orderLot.publicUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Résumé & bons de commande
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSentPanier = (panier) => {
    return (
      <div key={panier.id}>
        <div className="bloc-panier bloc-panier-sent">
          <Row style={{ marginBottom: "30px" }}>
            <Col md={6} className="d-flex align-items-center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="date-panier">
                  Panier du :{" "}
                  <b>{moment(panier.date).locale("fr").format("L")}</b>
                </span>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <h3 className="title-panier">{panier.name}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={8} className="d-flex align-items-center">
              <ul className="list-infos-panier">
                <li>
                  <b>{panier.countArticles}</b>{" "}
                  {panier.countArticles > 1 ? "articles" : "article"}
                </li>
                <li>
                  Montant Total HT <b>{centimesToCurrency(panier.price)}</b>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <button
                onClick={() => renderBlocDetails(panier.id)}
                className="arrow"
              >
                <img src={arrowPanier} alt="flèche produit" />
              </button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            {panier.orderLots !== null ? detailsCard(panier) : null}
          </Col>
        </Row>
      </div>
    );
  };

  const renderPaniers = () => {
    return (
      <>
        <span>
          {session.confirmedCarts &&
            session.confirmedCarts.data &&
            session.confirmedCarts.data.length > 0 &&
            session.confirmedCarts.data.map((cart) => renderSentPanier(cart))}
        </span>
      </>
    );
  };

  return (
    <Account>
      {renderPaniers()}
      {session.confirmedCarts &&
        session.confirmedCarts.data &&
        session.confirmedCarts.data.length > 0 && (
          <button onClick={() => loadMore()} className="btn-green float-right">
            Charger plusss
          </button>
        )}
    </Account>
  );
};

ConfirmedCarts.propTypes = {};

ConfirmedCarts.defaultProps = {};

export default ConfirmedCarts;
