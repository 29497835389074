import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import Banner from "views/universal/Banner/Banner";
import CustomersList from "./CustomersList/CustomersList";
import { storeOperations } from "state/ducks/store";

const Customers = (props) => {
  const dispatch = useDispatch();

  const customers = useSelector((state) => state.store.customers, shallowEqual);

  useEffect(() => {
    if (!customers.init) {
      dispatch(storeOperations.getCustomers());
    }
  }, [dispatch, customers]);

  return (
    <Layout>
      {customers.isLoading ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <>
          <Banner
            titleBanner={"Les acheteurs "}
            desc={
              "Plusieurs centaines d’acheteurs utilisent notre centrale à travers la France. La liste ci-dessous n'est pas exhaustive."
            }
          />

          <CustomersList customers={customers} />
        </>
      )}
    </Layout>
  );
};

Customers.propTypes = {};

Customers.defaultProps = {};

export default Customers;
