import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { sessionOperations } from "state/ducks/session";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";

const Account = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  return (
    <Layout>
      {session.isLoading && (
        <GlobalLoader message={"Chargement en cours ..."} />
      )}
      <Container style={{ margin: "25px auto" }}>
        <Row>
          <Col md={3}>
            <ul className="nav-account">
              <li>
                <Link
                  to="/compte/paniers"
                  className={
                    location.pathname === "/compte/paniers" ? "active" : null
                  }
                >
                  Mes paniers
                </Link>
              </li>
              {session.user.roles.includes("ROLE_SUPER_CUSTOMER") &&
                session.user.type === "formalized" && (
                  <>
                    {/*<li>*/}
                    {/*  <Link*/}
                    {/*    to="/compte/paniers/valides"*/}
                    {/*    className={*/}
                    {/*      location.pathname === "/compte/paniers/valides"*/}
                    {/*        ? "active"*/}
                    {/*        : null*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    Mes paniers formalisés commandés*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </>
                )}
              {/*{session.user.roles.includes("ROLE_SUPER_CUSTOMER") && (*/}
              {/*  <>*/}
              {/*    <li>*/}
              {/*      <Link*/}
              {/*        to="/compte/paniers/libres/valides"*/}
              {/*        className={*/}
              {/*          location.pathname === "/compte/paniers/libres/valides"*/}
              {/*            ? "active"*/}
              {/*            : null*/}
              {/*        }*/}
              {/*      >*/}
              {/*        Mes paniers libres commandés*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*  </>*/}
              {/*)}*/}
              <li>
                <Link
                  to={"/compte/informations"}
                  className={
                    location.pathname === "/compte/informations" || location.pathname.startsWith("/compte/comptes")
                      ? "active"
                      : null
                  }
                >
                  Mon profil
                </Link>
              </li>
              {/* {session.user.roles.includes("ROLE_SUPER_CUSTOMER") && (
                  <li>
                    <Link
                        to={"/compte/comptes"}
                        className={
                          location.pathname.startsWith("/compte/comptes")
                              ? "active"
                              : null
                        }
                    >
                      Gestion des utilisateurs
                    </Link>
                  </li>
              )} */}
              <li
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(sessionOperations.logout())}
              >
                Déconnexion
              </li>
            </ul>
          </Col>
          <Col md={9}>{props.children}</Col>
        </Row>
      </Container>
    </Layout>
  );
};

Account.propTypes = {};

Account.defaultProps = {};

export default Account;
