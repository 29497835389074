import React from "react";
import { useDispatch } from "react-redux";
import Step2 from "./Step2"
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";
import { Formik } from 'formik';

// Objet de la candidature
const Step2Container = (props) => {

  const {
    formRef,
    departments,
    categoriesLot,
    lots,
    initialValues,
    regions,
    phaseCandidate
  } = props

  const dispatch = useDispatch();

  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    technicalCategory: Yup.string().nullable().required('Ce champ est requis'),
    regions: Yup.array().when('technicalCategory', (technicalCategory, schema) => {
      let l = categoriesLot.data.find(lot => parseInt(lot.id) === parseInt(technicalCategory));
      if (l !== undefined) {
        if (l.code.startsWith('1') && schema.tests.length < 1) {
          return schema.required('Ce champ est requis')
        }
      }
      return schema;
    }),
    departments: Yup.array().when('technicalCategory', (technicalCategory, schema) => {
      let l = categoriesLot.data.find(lot => parseInt(lot.id) === parseInt(technicalCategory));
      if (l !== undefined) {
        if (l.code.startsWith('2') && schema.tests.length < 1) {
          return schema.required('Ce champ est requis')
        }
      }
      return schema;
    }),
    fruitlessnessRegions: Yup.array(),
    fruitlessnessLots: Yup.array()
  })

  return (
    <Formik
       initialValues={initialValues.step2}
       validationSchema={validationSchema}
       onSubmit={values => {
         dispatch(formSupplierOperations.setStep2(values, initialValues.id));
         // Efface les prix dans le reducer si jamais modification
         dispatch(formSupplierOperations.resetCandidatePrices());
       }}
     >
       {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
         <Step2 formRef={formRef} departments={departments} categoriesLot={categoriesLot} lots={lots} regions={regions} values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} errors={errors} touched={touched} phaseCandidate={phaseCandidate}/>
       )}
     </Formik>
  );

};

Step2Container.propTypes = {
};

Step2Container.defaultProps = {

};

export default Step2Container;
