import * as types from "./types";
import { createReducer } from "../../utils";

const initialState = {
  init     : false,
  loaded   : false,
  isLoading: false,
};

const reducer = createReducer(initialState)({
  [types.UPLOAD_FILE]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.UPLOAD_FILE_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.UPLOAD_FILE_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false };
  },
});

export default reducer;
