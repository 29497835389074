import * as types from './types'

export const getModalStatus = (name, isOpen, size, message, data) => ( {
    type: types.GET_MODAL_STATUS,
    payload: {
      name,
      isOpen,
      size,
      message,
      data
    }
} );
