import * as actionTypes from "./types";

/**
 * Recupère les lots et leur détails
 */
export const getLots = (department, edition) => {
  let defaultEdition;
  if (!edition) {
    if (process.env.REACT_APP_EDITION === "current") {
      const date = new Date();
      let year = date.getFullYear();
      year = Number(year.toString().slice(-2));
      if (year >= 23) {
        defaultEdition = "23";
      } else {
        defaultEdition = "21";
      }
    } else {
      defaultEdition = process.env.REACT_APP_EDITION;
    }
  } else {
    defaultEdition = edition;
  }

  return {
    type: actionTypes.GET_LOTS,
    meta: {
      async: true,
      blocking: true,
      path: `products`,
      method: "GET",
      params: {
        departmentCode: department,
        edition: defaultEdition,
      },
    },
  };
};

/**
 * Recupère les lots sans leur détail (pour prendre les categories)
 */
export const getCategoriesLot = (edition) => {
  let defaultEdition;
  if (!edition) {
    if (process.env.REACT_APP_EDITION === "current") {
      const date = new Date();
      let year = date.getFullYear();
      year = Number(year.toString().slice(-2));
      if (year >= 23) {
        defaultEdition = "23";
      } else {
        defaultEdition = "21";
      }
    } else {
      defaultEdition = process.env.REACT_APP_EDITION;
    }
  } else {
    defaultEdition = edition;
  }

  return {
    type: actionTypes.GET_CATEGORIES_LOT,
    meta: {
      async: true,
      blocking: true,
      path: `lots`,
      method: "GET",
      params: {
        edition: defaultEdition,
      },
    },
  };
};

/**
 * Recupère les départements
 */
export const getDepartments = () => ({
  type: actionTypes.GET_DEPARTMENTS,
  meta: {
    async: true,
    blocking: true,
    path: `departments.json`,
    method: "GET",
  },
});

/**
 * Action pour filtrer les lots
 */
export const filtersLots = (category, prestation, edition) => ({
  type: actionTypes.FILTERS_LOTS,
  payload: {
    category,
    prestation,
    edition,
  },
});

/**
 * Recupère les lots et leur détails
 */
export const getSuppliers = (company, department, rank, lot, type, edition, limit, offset) => {
  let defaultEdition;
  if (!edition) {
    if (process.env.REACT_APP_EDITION === "current") {
      const date = new Date();
      let year = date.getFullYear();
      year = Number(year.toString().slice(-2));
      if (year >= 23) {
        defaultEdition = "23";
      } else {
        defaultEdition = "21";
      }
    } else {
      defaultEdition = process.env.REACT_APP_EDITION;
    }
  } else {
    defaultEdition = edition;
  }

  if (!limit) {
    limit = 10000;
  }

  return {
    type: actionTypes.GET_SUPPLIERS,
    meta: {
      async: true,
      blocking: true,
      path: `suppliers`,
      method: "GET",
      params: {
        departmentCode: department,
        company: company,
        lotCode: lot,
        rank: rank,
        type: type,
        edition: defaultEdition,
        limit: limit,
        offset: offset,
      },
    },
  };
};

/**
 * Action pour filtrer les lots
 */
export const filtersSuppliers = (
  company,
  department,
  rank,
  category,
  cartType,
  edition,
  limit,
  offset
) => ({
  type: actionTypes.FILTERS_SUPPLIERS,
  payload: {
    company,
    department,
    rank,
    category,
    cartType,
    edition,
    limit,
    offset
  },
});

export const resetFiltersSuppliers = () => ({
  type: actionTypes.RESET_FILTERS_SUPPLIERS,
  payload: {},
});

export const filtersFreeSuppliers = (
  company,
  department,
  rank,
  category,
  cartType,
  edition,
  limit,
  offset
) => ({
  type: actionTypes.FILTERS_FREE_SUPPLIERS,
  payload: {
    company,
    department,
    rank,
    category,
    cartType,
    edition,
    limit,
    offset
  },
});

export const resetFiltersFreeSuppliers = () => ({
  type: actionTypes.RESET_FILTERS_FREE_SUPPLIERS,
  payload: {},
});

/**
 * Recupère les clients et leur détails
 */
export const getCustomers = () => ({
  type: actionTypes.GET_CUSTOMERS,
  meta: {
    async: true,
    blocking: true,
    path: `customers`,
    method: "GET",
    params: {
      active: 1,
      order: {id: "DESC"}
    },
  },
});

/**
 * Recupère les documents (pour les téléchargements)
 */
export const getDocuments = (type) => ({
  type: actionTypes.GET_DOCUMENTS,
  meta: {
    async: true,
    blocking: true,
    path: `documents`,
    method: "GET",
    params: {
      type: type,
    },
  },
});

/**
 * Recupère les pages
 */
export const getPages = () => ({
  type: actionTypes.GET_PAGES,
  meta: {
    async: true,
    blocking: true,
    path: `pages`,
    method: "GET",
  },
});

/**
 * Recupère les categories
 */
export const getCategories = () => ({
  type: actionTypes.GET_CATEGORIES,
  meta: {
    async: true,
    blocking: true,
    path: `categories`,
    method: "GET",
  },
});

/**
 * Recupère les articles libres
 */
export const getFreeArticles = (categoryId, departmentId, pageNumber) => ({
  type: actionTypes.GET_FREE_ARTICLES,
  meta: {
    async: true,
    blocking: true,
    path: `free_articles`,
    method: "GET",
    params: {
      category: categoryId,
      departments: departmentId,
      page: pageNumber,
    },
  },
  payload: {
    pageNumber: pageNumber,
  },
});

/**
 * Action pour filtrer les articles libres
 */
export const filtersFreeArticles = (category, department) => ({
  type: actionTypes.FILTERS_FREE_ARTICLES,
  payload: {
    category,
    department,
  },
});

/**
 * Retourne les fournisseurs pour une catégorie d'article libre
 */
export const getSuppliersFromFreeArticle = (
  categoryId,
  departmentId,
  prestation
) => ({
  type: actionTypes.GET_SUPPLIERS_FROM_FREE_ARTICLE,
  meta: {
    async: true,
    blocking: true,
    path: `custom/free_articles/suppliers`,
    method: "GET",
    params: {
      category: categoryId,
      department: departmentId,
      prestation: prestation,
    },
  },
});

/**
 * Action pour filtrer les articles libres
 */
export const filtersFreeArticlesBySupplier = (category, department) => ({
  type: actionTypes.FILTERS_FREE_ARTICLES_BY_SUPPLIER,
  payload: {
    category,
    department,
  },
});

/**
 * Retourne les articles libres d'un fournisseur
 */
export const getFreeArticleSupplier = (supplierId) => ({
  type: actionTypes.GET_FREE_ARTICLES_SUPPLIER,
  meta: {
    async: true,
    blocking: true,
    path: `custom/free_articles/suppliers/${supplierId}`,
    method: "GET",
  },
});
