import React from "react";
import "./Banner.css";
import CartBanner from "views/universal/Banner/CartBanner/CartBanner";

// import style
import {Container, Row, Col} from "reactstrap";

class Banner extends React.Component {
    displayContent() {
        if (this.props.image) {
            return (
                <section>
          <span
              style={{
                  backgroundColor: "black",
                  backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${this.props.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  textAlign: "center",
                  alignItems: "center",
                  fontSize: 20,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "235px",
              }}
          >
            <span style={{color: "white", whiteSpace: "pre-line"}}>
              <h1>{this.props.titleBanner.split("<br/>").join("\n")}</h1>
                {this.props.desc
                    ? this.props.desc.split("<br/>").join("\n")
                    : null}
            </span>
          </span>
                </section>
            );
        } else {
            return (
                <section id="banner">
                    <Container>
                        <Row>
                            <Col md={{size: 12}} style={{whiteSpace: "pre-line"}}>
                                <h1 style={{ marginBottom: "12px"}}>{this.props.titleBanner.split("<br/>").join("\n")}</h1>
                                {this.props.desc
                                    ? this.props.desc.split("<br/>").join("\n")
                                    : null}
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
    }

    render() {
        return (
            <span>
        {this.displayContent()}
                <CartBanner/>
      </span>
        );
    }
}

export default Banner;
