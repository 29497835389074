import React, { useEffect } from "react";
import { Field, Form, ErrorMessage } from "formik";
import Contractors from "./Elements/Contractors";
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError";
import { INITIAL_FORM_SUPPLIER } from "constants/constants";
import swal from "sweetalert";

const Step3 = (props) => {
  const { formRef, values, setFieldValue, errors, touched, initialValues } =
    props;

  useEffect(() => {
    if (values?.contractors?.length === 0) {
      setFieldValue(
        "contractors.0",
        INITIAL_FORM_SUPPLIER.step3.contractors[0]
      );
    }
  }, [setFieldValue, values]);

  const warningChangeSingle = () => {
    if (
      values?.typeCandidate === "businessGroup" &&
      values?.contractors?.length > 1
    ) {
      swal({
        title:
          "Si vous passez en candidat seul, les renseignements des autres contractants seront supprimés",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirm) => {
        if (confirm) {
          setFieldValue("typeCandidate", "single");
          setFieldValue("contractors", [{ ...values.contractors[0] }]);
          setFieldValue("typeBusinessGroup", "");
          setFieldValue("mandatarySolidarity", "");
        }
      });
    } else {
      setFieldValue("typeCandidate", "single");
    }
  };

  return (
    <Form className="mb-4" id="step3" ref={formRef}>
      <ScrollToError />
      <h4>3.1 Forme</h4>
      <label className="mr-4">
        <Field
          type="radio"
          name="typeCandidate"
          value="single"
          onChange={() => warningChangeSingle()}
        />{" "}
        Le candidat se présente seul*
      </label>
      <label className="mt-1">
        <Field type="radio" name="typeCandidate" value="businessGroup" /> Le
        candidat est un groupement d’entreprises*
      </label>
      <ErrorMessage
        name="typeCandidate"
        component="div"
        className="error-red"
      />
      <hr />
      {values.typeCandidate === "businessGroup" && (
        <>
          <label className="mr-4">
            <Field type="radio" name="typeBusinessGroup" value="conjoint" />{" "}
            Conjoint*
          </label>
          <label className="mt-1">
            <Field type="radio" name="typeBusinessGroup" value="solidarity" />{" "}
            Solidaire*
          </label>
          <ErrorMessage
            name="typeBusinessGroup"
            component="div"
            className="error-red"
          />
          <hr />
          {values.typeBusinessGroup === "conjoint" && (
            <>
              <label>
                En cas de groupement conjoint, le mandataire est solidaire :
              </label>
              <br />
              <label className="mr-4">
                <Field type="radio" name="mandatarySolidarity" value="no" />{" "}
                Non*
              </label>
              <label className="mt-1">
                <Field type="radio" name="mandatarySolidarity" value="yes" />{" "}
                Oui*
              </label>
              <ErrorMessage
                name="mandatarySolidarity"
                component="div"
                className="error-red"
              />
            </>
          )}
        </>
      )}

      {values.typeCandidate && (
        <Contractors
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          initialValues={initialValues}
          touched={touched}
        />
      )}
    </Form>
  );
};

Step3.propTypes = {};

Step3.defaultProps = {};

export default Step3;
