import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import routes from "routes";
import PrivateRoute from "routes/PrivateRoute";
import ErrorBoundary from "utils/ErrorBoundary";
import { sessionOperations } from "state/ducks/session";

const Main = (props) => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  useEffect(() => {
    if (!session.init) {
      dispatch(sessionOperations.initializeSession());
    }
  }, [dispatch, session]);

  return (
    <Router>
      <ScrollToTop />
      <ErrorBoundary>
        <Switch>
          {session.loaded &&
            routes.map((route, key) =>
              route.private ? (
                <PrivateRoute
                  component={route.component}
                  path={route.path}
                  key={key}
                  roles={route.roles ? route.roles : []}
                  exact
                />
              ) : (
                <Route
                  component={route.component}
                  path={route.path}
                  key={key}
                  exact
                />
              )
            )}
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default Main;
