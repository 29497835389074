import * as types from './types'
import { createReducer } from '../../utils'

/* State shape
{
  connexion: [ boolean ]
}
*/
const initialState = {name: null, isOpen: false, size: null, message: null, data: null};

const modalsReducer = createReducer( initialState )( {
    [ types.GET_MODAL_STATUS ]: ( state, action ) => {
      const { name, isOpen, size, message, data } = action.payload;
      return { ...state, name: name, isOpen: isOpen, size: size, message: message, data: data }
    },
} );

export default modalsReducer;
