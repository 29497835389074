import React from "react";
import { useDispatch } from "react-redux";
import Step11 from "./Step11"
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";

// Mémoire technique
const Step11Container = (props) => {

  const {
    formRef,
    initialValues
  } = props

  const dispatch = useDispatch();

  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    acceptDeal: Yup.bool().oneOf([true], "Ce champ est requis"),
    madeIn: Yup.string().nullable().required("Ce champ est requis"),
  })

  return (
    <>
    <Formik
       initialValues={initialValues.step11}
       validationSchema={validationSchema}
       onSubmit={values => {dispatch(formSupplierOperations.setStep11(values, initialValues.id))}}
     >
       {({ errors, touched, values, setFieldValue }) => (
         <>
          <Step11 formRef={formRef} values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} initialValues={initialValues}/>
         </>
       )}
     </Formik>
     </>
  );

};

Step11Container.propTypes = {
};

Step11Container.defaultProps = {

};

export default Step11Container;
