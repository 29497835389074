import React from "react";
import {useDispatch} from "react-redux";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import "./ModalModel.css";
import LogIn from "./Models/Login";
import Register from "./Models/Register/Register";
import CartForm from "./Models/CartForm";
import Resend from "./Models/Resend";
import AddFreeArticle from "./Models/AddFreeArticle";
import SendCartToCustomer from "./Models/SendCartToCustomer";
import {modalsOperations} from "state/ducks/modal";

const ModalModel = (props) => {
    const {modal, session, departments, categories} = props;

    const dispatch = useDispatch();

    const renderModalTitle = () => {
        switch (modal.name) {
            case "message": {
                return "Message";
            }
            case "register": {
                return "Inscription";
            }
            case "addFreeArticle": {
                return "Ajout / modification d'un article";
            }
            case "login": {
                return "Connectez-vous !";
            }
            case "resend": {
                return "Mot de passe oublié";
            }
            case "panier_create": {
                return "Créer un nouveau panier";
            }
            case "panier_create_as_supplier": {
                return "Créer un nouveau panier pour un client";
            }
            case "panier_libre_create": {
                return "Créer un nouveau panier libre";
            }
            case "panier_libre_create_as_supplier": {
                return "Créer un nouveau panier libre pour un client";
            }
            case "remove_cart": {
                return "Êtes-vous sur de vouloir supprimer votre panier ?";
            }
            case "send_cart_to_customer": {
              return "Partager le numéro du panier";
            }
            case "send_cart_to_customer_tuto": {
                return "Envoyer mon panier tuto";
            }
            default:
                return "Title";
        }
    };

    const renderModalContent = () => {
        switch (modal.name) {
            case "message": {
                return <div>{modal.message}</div>;
            }
            case "register": {
                return <Register/>;
            }
            case "addFreeArticle": {
                if (modal.data && modal.data.article) {
                    return (
                        <AddFreeArticle
                            session={session}
                            departments={departments}
                            categories={categories}
                            article={modal.data.article}
                        />
                    );
                } else {
                    return (
                        <AddFreeArticle
                            session={session}
                            departments={departments}
                            categories={categories}
                        />
                    );
                }
            }
            case "login": {
                return <LogIn session={session}/>;
            }
            case "resend": {
                return <Resend/>;
            }
            case "panier_create": {
                return (
                    <CartForm
                        departments={departments}
                        session={session}
                        from="customer"
                    />
                );
            }
            case "panier_create_as_supplier": {
                return (
                    <CartForm
                        departments={departments}
                        session={session}
                        from="supplier"
                    />
                );
            }
            case "panier_libre_create": {
                return (
                    <CartForm
                        departments={departments}
                        session={session}
                        from="customer"
                        cartType="free"
                    />
                );
            }
            case "panier_libre_create_as_supplier": {
                return (
                    <CartForm
                        departments={departments}
                        session={session}
                        from="supplier"
                        cartType="free"
                    />
                );
            }
            case "send_cart_to_customer": {
                return (
                    <SendCartToCustomer
                        session={session}
                        cartId={modal.data.cartId}
                        cartType={modal.data.cartType}
                        email={modal.data.email}
                        cartAddress={modal.data.cartAddress}
                        cartCountArticles={modal.data.cartCountArticles}
                    />
                );
            }

            case "remove_cart": {
                return null;
            }
            default:
                return " ";
        }
    };

    const toggle = () => {
        dispatch(modalsOperations.getModalStatus(null, false, null));
    };

    return (
        <Modal
            isOpen={modal.isOpen}
            style={modal.size === "big" ? {maxWidth: "1000px"} : null}
        >
            {modal.name === "register" ? (
                <ModalHeader
                    toggle={toggle}
                    style={{fontSize: "35px", borderBottom: "0px"}}
                >
                    {renderModalTitle()}
                </ModalHeader>
            ) : (
                <ModalHeader toggle={toggle}>{renderModalTitle()}</ModalHeader>
            )}

            {modal.name === "register" ? (
                <ModalBody
                    style={{paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px"}}
                >
                    {renderModalContent()}
                </ModalBody>
            ) : (
                <ModalBody>{renderModalContent()}</ModalBody>
            )}
        </Modal>
    );
};

export default ModalModel;
