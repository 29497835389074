import * as types from './types'
import { createReducer } from '../../utils'
import { INITIAL_FORM_SUPPLIER } from "constants/constants"

/* State shape, pour le détail des objets aller voir dans les containers dédiés stepXContainer (Yup)
{
  stepInprogress: // Number,
  criticalError: {
    type: string
  },
  step2: {}, // Object
  step3: {} // Object
  step4: {} // Object
  step5: {} // Object
  step6: {} // Object
  step7: {} // Object
  step8: {} // Object
  step9: {} // Object
  step10: {} // Object
  step10Alt: {} // Object
  step11: {} // Object
  step12: {} // Object
  isLoading: bool
  init: bool
  loaded: bool,
  prices: [],
  submittedInputs: {},
}
*/

const initialState = {...INITIAL_FORM_SUPPLIER,
  isLoading: false,
  init: false,
  loaded: false,
  criticalError: null,
  submittedInputs: {}
};

const formSupplierReducer = createReducer( initialState )( {
    [types.SAVE_SUBMITTED_INPUTS]: (state, action) => {
      const values = action.payload;
      return { ...state, submittedInputs: values };
    },
    [types.INIT_CANDIDATE]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [types.INIT_CANDIDATE_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.INIT_CANDIDATE_COMPLETED ]: ( state, action ) => {
      return { ...initialState };
    },
    [types.GET_CANDIDATE]: (state, action) => {
      return { ...state, isLoading: true, init:true };
    },
    [types.GET_CANDIDATE_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.GET_CANDIDATE_COMPLETED ]: ( state, action ) => {
      return candidateApiToStore(state, action)
    },
    [types.GET_CANDIDATES]: (state, action) => {
      return { ...state, isLoading: true, init:true };
    },
    [types.GET_CANDIDATE_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.GET_CANDIDATES_COMPLETED ]: ( state, action ) => {
      return { ...state, isLoading: false };
    },
    [ types.SET_STEP_IN_PROGRESS ]: ( state, action ) => {
      const { values } = action.payload;
      return { ...state, stepInprogress: values };
    },
    [ types.SET_STEP_IS_LOADING ]: ( state, action ) => {
      const { values } = action.payload;
      return { ...state, isLoading: values };
    },
    [ types.SET_CRITICAL_ERROR ]: ( state, action ) => {
      const { values } = action.payload;
      return { ...state, criticalError: values };
    },
    [ types.RESET_CANDIDATE ]: ( state, action ) => {
      return { ...initialState };
    },
    [types.UPDATE_CANDIDATE]: (state, action) => {
      return { ...state, isLoading: true, init:true };
    },
    [types.UPDATE_CANDIDATE_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.UPDATE_CANDIDATE_COMPLETED ]: ( state, action ) => {
      return candidateApiToStore(state, action)
    },
    [types.GENERATE_PDF]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [types.GENERATE_PDF_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.GENERATE_PDF_COMPLETED ]: ( state, action ) => {
      const { payload } = action;
      if (payload.type === 'preview') {
        return { ...state,
          step11: {
            ...state.step11,
            previewPDF: action?.payload?.result?.previewPDF?.contentUrl ? action.payload.result.previewPDF.contentUrl : null,
          },
          isLoading: false
        }
      };
      return { ...state, isLoading: false };
    },
    [types.GET_CANDIDATE_PRICES]: (state, action) => {
      return { ...state, isLoading: true};
    },
    [types.GET_CANDIDATE_PRICES_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.GET_CANDIDATE_PRICES_COMPLETED ]: ( state, action ) => {
      return { ...state,
        step10Alt: {
          ...state.step10Alt,
          prices: action.payload.result.prices
        },
        isLoading: false
      };
    },
    [ types.FILTER_CANDIDATE_PRICES]: ( state, action ) => {
      const { payload } = action;
      return { ...state,
        step10Alt: {
          ...state.step10Alt,
          filters: { ...payload}
        },
      };
    },
    [ types.RESET_CANDIDATE_PRICES]: ( state, action ) => {
      return { ...state,
        step10Alt: {
          ...state.step10Alt,
          prices: []
        },
      };
    },
    [types.UPDATE_CANDIDATE_PRICES]: (state, action) => {
      return { ...state, isLoading: true};
    },
    [types.UPDATE_CANDIDATE_PRICES_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.UPDATE_CANDIDATE_PRICES_COMPLETED ]: ( state, action ) => {
      return { ...state, isLoading: false };
    },
    [types.CHECK_CANDIDATE_PRICES]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [types.CHECK_CANDIDATE_PRICES_FAILED]: (state, action) => {
      return { ...state, isLoading: false };
    },
    [ types.CHECK_CANDIDATE_PRICES_COMPLETED ]: ( state, action ) => {
      return { ...state, isLoading: false };
    },
} );


const candidateApiToStore = (state, action) => {
  const result = action.payload.result;

  let isRegion = result?.lot?.code && result.lot.code.startsWith('1') ? true : false;

  let step2 = {
    technicalCategory: result?.lot?.id ? result.lot.id : null,
    departments:[],
    regions:[],
    fruitlessnessLots:[],
    fruitlessnessRegions: [],
    departmentInterlocutorIds:[]
  }

  let step4 = {
    contactDetailsBuyers: [],
    contactDetailsBuyersSecondary: [],
    contactDetailsCentralis: {
      id: result?.remunerationInterlocutor?.id ? result.remunerationInterlocutor.id : null,
      email: result?.remunerationInterlocutor?.email ? result.remunerationInterlocutor.email : null,
      phone: result?.remunerationInterlocutor?.phone ? result.remunerationInterlocutor.phone : null,
      lastname: result?.remunerationInterlocutor?.name ? result.remunerationInterlocutor.name : null,
      function: result?.remunerationInterlocutor?.function ? result.remunerationInterlocutor.function : null
    },
    bankDetails: {
      rib: result?.rib?.contentUrl ? result.rib.contentUrl : null,
      ribUpload: null,
      iban: result?.iban ? result.iban : null,
      bic: result?.bic ? result.bic : null
    }
  }

  result.departmentInterlocutors.forEach((departmentInterlocutor, index) => {

    // URI
    let contractorId = null;
    if (departmentInterlocutor?.contractor) {
      contractorId = departmentInterlocutor.contractor.split('/');
      contractorId = contractorId[3];
    }

    if (isRegion) {
      if (!departmentInterlocutor.secondary && departmentInterlocutor?.region?.id) {
        step2.regions.push(departmentInterlocutor?.region?.id);
        step2.departmentInterlocutorIds.push(departmentInterlocutor?.id);
      }
      else {
        if (departmentInterlocutor?.region?.id) {
          step2.fruitlessnessRegions.push(departmentInterlocutor?.region?.id);
          step2.departmentInterlocutorIds.push(departmentInterlocutor?.id);
        }
      }
    }
    else {
      if (!departmentInterlocutor.secondary && departmentInterlocutor?.department?.id) {
        step2.departments.push(departmentInterlocutor?.department?.id);
        step2.departmentInterlocutorIds.push(departmentInterlocutor?.id);
      }
      else {
        if (departmentInterlocutor?.department?.id) {
          step2.fruitlessnessLots.push(departmentInterlocutor?.department?.id);
          step2.departmentInterlocutorIds.push(departmentInterlocutor?.id);
        }
      }
    }

    if (!departmentInterlocutor.secondary) {
      step4.contactDetailsBuyers.push({
        id: departmentInterlocutor?.id ? departmentInterlocutor.id : null,
        interlocutorId: departmentInterlocutor?.interlocutor?.id ? departmentInterlocutor.interlocutor.id : null,
        department: (departmentInterlocutor?.department?.code && !isRegion) ? departmentInterlocutor.department.code : (departmentInterlocutor?.region?.code && isRegion) ? departmentInterlocutor.region.code : null,
        departmentName: (departmentInterlocutor?.department?.name && !isRegion) ? departmentInterlocutor.department.name : (departmentInterlocutor?.region?.name && isRegion) ? departmentInterlocutor.region.name : null,
        lot: result?.lot?.code ? result.lot.code : null,
        company: contractorId,
        lastname: departmentInterlocutor?.interlocutor?.name ? departmentInterlocutor.interlocutor.name : null,
        function: departmentInterlocutor?.interlocutor?.function ? departmentInterlocutor.interlocutor.function : null,
        phone: departmentInterlocutor?.interlocutor?.phone ? departmentInterlocutor.interlocutor.phone : null,
        email: departmentInterlocutor?.interlocutor?.email ? departmentInterlocutor.interlocutor.email : null,
        emailDuplicate: departmentInterlocutor?.emailDuplicate ? departmentInterlocutor.emailDuplicate : null,
      });
    }
    else {
      step4.contactDetailsBuyersSecondary.push({
        id: departmentInterlocutor?.id ? departmentInterlocutor.id : null
      });
    }

  });

  let step3 = {
    typeCandidate: result?.typeCandidate ? result.typeCandidate : null,
    typeBusinessGroup: result?.typeBusinessGroup ? result.typeBusinessGroup : null,
    mandatarySolidarity: result?.mandatarySolidarity ?
    result.mandatarySolidarity === true ? 'yes' : 'no'
    : null,
    contractors: []
  }
  let step5 = {
    activitiesAptitudeDetails: [],
  }
  let step6 = {
    economicCapacity: [],
  }
  let step7 = {
    averageAnnualWorkforce: [],
    mainDeliveries: []
  }
  let step8 = {
    outSourcing : result.considerSubcontracting === true ? 'yes' : 'no',
    otherOperators: []
  }

  result.contractors.forEach((contractor, index) => {

    step5.activitiesAptitudeDetails.push({
      organization: contractor?.organismName ? contractor.organismName : null,
      name: contractor?.qualificationCertificateName ? contractor.qualificationCertificateName : null,
      proofFileUpload: null,
      proofFile: contractor?.qualificationCertificateFile?.contentUrl ? contractor.qualificationCertificateFile.contentUrl : null,
      proofText: contractor?.qualificationCertificateText ? contractor.qualificationCertificateText : null,
    });

    step6.economicCapacity.push({
      organization: contractor?.organismName ? contractor.organismName : null,
      y1Revenue: contractor?.revenueY1 ? contractor.revenueY1 : null,
      y1PartOfRevenue: contractor?.revenuePercentY1 ? contractor.revenuePercentY1 : null,
      y2Revenue: contractor?.revenueY2 ? contractor.revenueY2 : null,
      y2PartOfRevenue: contractor?.revenuePercentY2 ? contractor.revenuePercentY2 : null,
      y3Revenue: contractor?.revenueY3 ? contractor.revenueY3 : null,
      y3PartOfRevenue: contractor?.revenuePercentY3 ? contractor.revenuePercentY3 : null
    });

    step7.averageAnnualWorkforce.push({
      organization: contractor?.organismName ? contractor.organismName : null,
      y1Average: contractor?.workforceY1 ? contractor.workforceY1 : null,
      y1PartStaff: contractor?.executivePercentY1 ? contractor.executivePercentY1 : null,
      y2Average: contractor?.workforceY2 ? contractor.workforceY2 : null,
      y2PartStaff: contractor?.executivePercentY2 ? contractor.executivePercentY2 : null,
      y3Average: contractor?.workforceY3 ? contractor.workforceY3 : null,
      y3PartStaff: contractor?.executivePercentY3 ? contractor.executivePercentY3 : null,
    });

    if (contractor?.deliveries) {
      contractor.deliveries.forEach((delivery, i) => {
        step7.mainDeliveries.push({
          organization: contractor?.id ? contractor.id : null,
          name: delivery?.name ? delivery.name : null,
          budget: delivery?.budget ? delivery.budget : null,
          year: delivery?.year ? delivery.year : null,
          proof: delivery?.proof?.contentUrl ? delivery?.proof.contentUrl : null,
          proofUpload: null,
          deliveryId: delivery?.id ? delivery.id : null,
        });
      });
    }

    if (contractor?.subcontractingInterlocutors) {
      contractor.subcontractingInterlocutors.forEach((subcontractingInterlocutor, i) => {
        step8.otherOperators.push({
          organization: contractor?.id ? contractor.id : null,
          name: subcontractingInterlocutor?.name ? subcontractingInterlocutor.name : null,
          siret: subcontractingInterlocutor?.siret ? subcontractingInterlocutor.siret : null,
          interlocutorId: subcontractingInterlocutor?.id ? subcontractingInterlocutor.id : null
        });
      });
    }

    step3.contractors.push({
      id: contractor?.id ? contractor.id : null,
      organization: contractor?.organismName ? contractor.organismName : null,
      firstname: contractor?.representativeFirstname ? contractor.representativeFirstname : null,
      lastname: contractor?.representativeLastname ? contractor.representativeLastname : null,
      function: contractor?.representativeFunction ? contractor.representativeFunction : null,
      typeOrganization: contractor?.organismType ? contractor.organismType : null,
      capital: contractor?.organismCapital ? contractor.organismCapital : null,
      siret: contractor?.siret ? contractor.siret : null,
      rcs: contractor?.rcs ? contractor.rcs : null,
      addressId: contractor?.address?.id ? contractor.address.id : null,
      addressNumber: contractor?.address?.streetNumber ? contractor.address.streetNumber : null,
      addressStreet: contractor?.address?.street ? contractor.address.street : null,
      addressPostalCode: contractor?.address?.postal ? contractor.address.postal : null,
      addressCity: contractor?.address?.city ? contractor.address.city : null,
      phone: contractor?.phone ? contractor.phone : null,
      email: contractor?.email ? contractor.email : null,
      logo: contractor?.logo?.contentUrl ? contractor.logo.contentUrl : null,
      logoUpload: null,
      fiscalRegularityText: contractor?.fiscalRegularityText ? contractor.fiscalRegularityText : null,
      fiscalRegularityFile: contractor?.fiscalRegularityFile?.contentUrl ? contractor.fiscalRegularityFile.contentUrl : null,
      fiscalRegularityFileUpload: null,
      companyInsuranceName: contractor?.companyInsuranceName ? contractor.companyInsuranceName : null,
      insurancePolicyNumber: contractor?.insurancePolicyNumber ? contractor.insurancePolicyNumber : null,
      companyInsuranceFile: contractor?.companyInsuranceFile?.contentUrl ? contractor.companyInsuranceFile.contentUrl : null,
      companyInsuranceFileUpload: null,
      companyInsuranceText: contractor?.companyInsuranceText ? contractor.companyInsuranceText : null,
      is10insuranceCertificate: contractor?.insurance10Certificate ? contractor.insurance10Certificate : null,
      isLegalRedress: contractor?.legalRedress ? contractor.legalRedress : null,
      legalRedressFile: contractor?.legalRedressFile?.contentUrl ? contractor.legalRedressFile.contentUrl : null,
      legalRedressFileUpload: null,
      legalRedressText: contractor?.legalRedressText ? contractor.legalRedressText : null,
      isSwornStatement: contractor?.swornStatement ? contractor.swornStatement : null,
      authorizationMandataryFile: contractor?.authorizationMandataryFile?.contentUrl ? contractor.authorizationMandataryFile.contentUrl : null,
      authorizationMandataryFileUpload: null,
      authorizationMandataryText: contractor?.authorizationMandataryText ? contractor.authorizationMandataryText : null,
    });

  });

  if (step3?.contractors.length > 0) {
    step4.bankDetails.organization             = step3.contractors[0].organization;
    step4.contactDetailsCentralis.organization = step3.contractors[0].organization;
  }

  let authorizationSEPA = null;
  if (result.authorizationSEPA === true) {
    authorizationSEPA = 'yes';
  }
  if (result.authorizationSEPA === false) {
    authorizationSEPA = 'no';
  }

  let step9 = {
    authorizationSEPA: authorizationSEPA,
    presentationEnvironment: result?.presentationEnvironment ? result.presentationEnvironment : result.presentationEnvironment,
    presentationHuman: result?.presentationHuman ? result.presentationHuman : result.presentationHuman,
    presentationOperative: result?.presentationOperative ? result.presentationOperative : result.presentationOperative,
    proofs: result.presentationHumanProofs.map(presentationHumanProof => {
      return {proof:presentationHumanProof?.contentUrl, proofUpload:null, proofId:presentationHumanProof?.id}
    })
  }

  let identicalOffer = null;
  if (result.identicalOffer === true) {
    identicalOffer = 'yes';
  }
  if (result.identicalOffer === false) {
    identicalOffer = 'no';
  }

  let step10 = {
    identicalOffer      : identicalOffer,
    allDepartments      : [],
    departments         : [],
    secondaryDepartments: []
  }

  let initialPrice = null;
  if(process.env.NODE_ENV === "development"){
    initialPrice = 1;
  }

  result.departmentInterlocutors.forEach((departmentInterlocutor, index) => {
    let articles = [];
    if (departmentInterlocutor?.prices && departmentInterlocutor.prices.length > 0) {
      departmentInterlocutor.prices.forEach((price, i) => {
        articles.push({
          id: price.id,
          articleId: price.article.id,
          ref: price.article.code,
          name: price.article.name,
          limitA: price.article.quantityB,
          limitB: price.article.quantityC,
          unit: price.article.unit,
          priceA: price?.priceA ? price.priceA / 100 : initialPrice,
          priceAB: price?.priceB ? price.priceB / 100 : initialPrice,
          priceB: price?.priceC ? price.priceC / 100 : initialPrice
        })
      });
    }
    else {
      articles = [];
    }

    // Prend le 1er des prix si même offre
    if (index === 0) {
      step10.allDepartments = articles;
    }

    if (isRegion) {
      if (departmentInterlocutor?.secondary && departmentInterlocutor.secondary === true) {
        step10.secondaryDepartments.push({
          id: departmentInterlocutor?.id,
          name: departmentInterlocutor?.region?.name,
          code: departmentInterlocutor?.region?.code,
          articles: articles
        });
      }
      else {
        step10.departments.push({
          id: departmentInterlocutor?.id,
          name: departmentInterlocutor?.region?.name,
          code: departmentInterlocutor?.region?.code,
          articles: articles
        });
      }
    }
    else {
      if (departmentInterlocutor?.secondary && departmentInterlocutor.secondary === true) {
        step10.secondaryDepartments.push({
          id: departmentInterlocutor?.id,
          name: departmentInterlocutor?.department?.name,
          code: departmentInterlocutor?.department?.code,
          articles: articles
        });
      }
      else {
        step10.departments.push({
          id: departmentInterlocutor?.id,
          name: departmentInterlocutor?.department?.name,
          code: departmentInterlocutor?.department?.code,
          articles: articles
        });
      }
    }
  });

  let step11 = {
    acceptDeal: result?.acceptDeal ? result.acceptDeal : false,
    madeIn: result?.contractors[0] && result?.contractors[0]?.address?.city ? result.contractors[0].address.city : '',
    previewPDF: result?.previewPDF?.contentUrl ? result.previewPDF.contentUrl : null,
    generatePDF: true
  }

  let step12 = {
    closeAndGenerate: result?.closeAndGenerate ? result.closeAndGenerate : false,
    finalPDF: result?.finalPDF?.contentUrl ? result.finalPDF.contentUrl : null,
  }

  return { ...state,
    isLoading: false,
    loaded:true,
    stepInprogress: result?.step ? parseInt(result.step) : 0,
    id: result.id,
    token: result.token,
    phase: result?.phase ? result.phase : '1',
    accepted: result?.accepted ? result.accepted : false,
    step2: step2,
    step3: step3,
    step4: step4,
    step5: step5,
    step6: step6,
    step7: step7,
    step8: step8,
    step9: step9,
    step10: step10,
    step11: step11,
    step12: step12,
    criticalError: null,
    identicalOffer: result.identicalOffer
  };
}
export default formSupplierReducer;
