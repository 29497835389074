import React from "react";
import { Row, Col } from "reactstrap";
import moment from "moment/moment";
import "moment/locale/fr";
import arrowPanier from "assets/img/arrow-product.svg";
import { centimesToCurrency } from "utils/Utils";

const OrderComponent = (props) => {
  const { orderLotSupplier } = props;

  // id, status, number, statusTextFR, postal
  const renderBlocDetails = (value) => {
    const blocPanier = document.querySelector(`#c${value}`);
    blocPanier.style.display =
      blocPanier.style.display === "block" ? "none" : "block";
  };

  const renderDetails = (orderLotSupplier) => {
    return (
      <div id={"c" + orderLotSupplier.id} className="bloc-panier-table">
        <div className="bloc-panier-header">
          <div>N°Bon De Commande</div>
          <div>Statut</div>
          <div>Département</div>
          <div className="bloc-panier-header-lot">Lot</div>
          <div>Total HT</div>
          <div>Lien</div>
        </div>
        <div className="bloc-panier-content">
          <div>
            <div className="bloc-panier-content-lot">
              <div className="bloc-panier-content-lot-header">
                <div>
                  <p>
                    {orderLotSupplier.status > 0 && orderLotSupplier.number}
                  </p>
                </div>

                <div>
                  <p>
                    {orderLotSupplier.status > 0 &&
                      orderLotSupplier.statusTextFR}
                  </p>
                </div>

                <div>{orderLotSupplier.postal}</div>
                <div className="bloc-panier-content-lot-header-lot">
                  {orderLotSupplier.lotCode} – {orderLotSupplier.lotName}
                </div>

                <div>
                  <p>
                    {orderLotSupplier.status > 0 &&
                      centimesToCurrency(orderLotSupplier.price)}
                  </p>
                </div>

                <div>
                  <a
                    href={
                      orderLotSupplier.publicUrl && orderLotSupplier.publicUrl
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Résumé & bons de commande
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="bloc-panier bloc-panier-sent">
        <Row style={{ marginBottom: "30px" }}>
          <Col md={6} className="d-flex align-items-center">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="date-panier">
                Panier du :{" "}
                <b>{moment(orderLotSupplier.date).locale("fr").format("L")}</b>
              </span>
            </div>
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <h3 className="title-panier">{orderLotSupplier.customerName}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={8} className="d-flex align-items-center">
            <ul className="list-infos-panier">
              <li>
                Montant Total HT{" "}
                <b>{centimesToCurrency(orderLotSupplier.price)}</b>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <button
              onClick={() => renderBlocDetails(orderLotSupplier.id)}
              className="arrow"
            >
              <img src={arrowPanier} alt="flèche produit" />
            </button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col md={12}>
          {orderLotSupplier !== null ? renderDetails(orderLotSupplier) : null}
        </Col>
      </Row>
    </div>
  );
};

OrderComponent.propTypes = {};

OrderComponent.defaultProps = {};

export default OrderComponent;
