import React from "react";
import { FieldArray, Form } from 'formik';
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError"
import { Row, Col } from 'reactstrap';
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError"

const Step6 = (props) => {

  const {
    formRef,
    values,
  } = props

  return (
    <Form className="mb-4" id="step6" ref={formRef}>
      <ScrollToError />
      <FieldArray
        name="economicCapacity"
        render={arrayHelpers => {
          const economicCapacity = values.economicCapacity;
          return (
            <div>
              {economicCapacity && economicCapacity.map((contractor, index) => (
                <div className="card mb-4" key={index}>
                  <Row>
                    <Col xs="12" sm="12">
                      <label>Entreprise</label>
                      <FieldWithError name={`economicCapacity.${index}.organization`} placeholder={""} readonly />
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Chiffre d’affaires global annuel en € (N-1)</label>
                      <FieldWithError name={`economicCapacity.${index}.y1Revenue`} placeholder={"En €"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Part du chiffre d’affaires des prestations similaires à l’objet du marché en %</label>
                      <FieldWithError name={`economicCapacity.${index}.y1PartOfRevenue`} placeholder={"En %"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Chiffre d’affaires global annuel en € (N-2)</label>
                      <FieldWithError name={`economicCapacity.${index}.y2Revenue`} placeholder={"En €"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Part du chiffre d’affaires des prestations similaires à l’objet du marché en %</label>
                      <FieldWithError name={`economicCapacity.${index}.y2PartOfRevenue`} placeholder={"En %"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Chiffre d’affaires global annuel en € (N-3)</label>
                      <FieldWithError name={`economicCapacity.${index}.y3Revenue`} placeholder={"En €"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Part du chiffre d’affaires des prestations similaires à l’objet du marché en %</label>
                      <FieldWithError name={`economicCapacity.${index}.y3PartOfRevenue`} placeholder={"En %"}/>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          );
        }}
      />

    </Form>

  );

};

Step6.propTypes = {
};

Step6.defaultProps = {

};

export default Step6;
