import React from "react";

// Préambule
const Step1 = (props) => {

  return (
    <>
      <p>
        Bienvenue sur le formulaire électronique en ligne de Centralis. Il permet de formaliser votre candidature et votre offre pour la consultation identifiée ci-dessous. Nous vous conseillons de vous munir de toutes les informations et documents indiqués au règlement avant d'entamer le remplissage. A la fin du formulaire, lorsque tous les champs obligatoires seront complétés, un fichier en PDF sera généré. Il conviendra de le déposer sur le profil acheteur avant la date échéance pour officialiser votre candidature et votre offre.
      </p>

      <h4>1.1 - Identification de l’acheteur</h4>
      <p>
        Association Centralis® (Centrale Nationale d’achats publics dédiée à la rénovation de patrimoine immobilier) inscrite dans le cadre de l’article L. 2113-2 du code de la commande publique, SIRET 893 375 386 00016, 229 Rue Saint Honoré 75001 Paris 1er, et agissant au nom et pour le compte de l’ensemble de ses utilisateurs acheteurs pour la passation de l’accord-cadre. Le détail des acheteurs pour lesquels Centralis agit en tant que mandataire est consultable en annexe du règlement de consultation. Centralis est responsable de la PASSATION des marchés. Une fois les contrats notifiés, chaque acheteur est l’unique responsable de l’EXECUTION des marchés pour les commandes qu’il émet vis-à-vis des titulaires concernés.
      </p>

      <h4>1.2 - Objet de la consultation</h4>
      <p>
        L'accord-cadre a pour objet la réalisation de prestations nécessaires aux acheteurs utilisateurs de la Centrale d’achat « Centralis » concernant leurs besoins relatifs à l’entretien et la rénovation de leur patrimoine immobilier et foncier.
      </p>

    </>
  );

};

Step1.propTypes = {
};

Step1.defaultProps = {

};

export default Step1;
