import React from "react";
import { FieldArray, Form, ErrorMessage } from 'formik';
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError"
import { Row, Col } from 'reactstrap';
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError"

const Step5 = (props) => {

  const {
    formRef,
    values,
    setFieldValue,
  } = props

  return (
    <Form className="mb-4" id="step5" ref={formRef}>
      <ScrollToError />
      <FieldArray
        name="activitiesAptitudeDetails"
        render={arrayHelpers => {
          const activitiesAptitudeDetails = values.activitiesAptitudeDetails

          return (
            <div>
              {activitiesAptitudeDetails && activitiesAptitudeDetails.map((activityAptitudeDetails, index) => (
                <div className="card mb-4" key={index}>
                  <Row>
                    <Col xs="12" sm="6">
                      <label>Entreprise</label>
                      <FieldWithError name={`activitiesAptitudeDetails.${index}.organization`} placeholder={""} readonly />
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Certificat de qualification professionnelle ou équivalent</label>
                      <FieldWithError name={`activitiesAptitudeDetails.${index}.name`} placeholder={"Nom du certificat"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Pièce jointe (JPG ou PDF, taille max 8mo)</label><br/>
                      {activityAptitudeDetails?.proofFile ? <a href={activityAptitudeDetails.proofFile} target="_blank" className="fileLink" rel="noopener noreferrer">Voir mon fichier chargé</a> : null}
                      <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`activitiesAptitudeDetails.${index}.proofFileUpload`} type="file"  onChange={(event) => {
                        setFieldValue(`activitiesAptitudeDetails.${index}.proofFileUpload`, event.currentTarget.files[0]);
                        setFieldValue(`activitiesAptitudeDetails.${index}.proofFile`, '')
                      }} />
                      <ErrorMessage name={`activitiesAptitudeDetails.${index}.proofFileUpload`} component="div" className="error-red"/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Autres preuves</label>
                      <FieldWithError as="textarea" name={`activitiesAptitudeDetails.${index}.proofText`} placeholder={"Le cas échéant, adresse internet à laquelle les documents justificatifs et moyens de preuve sont accessibles directement et gratuitement"}/>
                    </Col>

                  </Row>
                </div>
              ))}
            </div>
          );
        }}
      />

    </Form>

  );

};

Step5.propTypes = {
};

Step5.defaultProps = {

};

export default Step5;
