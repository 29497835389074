import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    session: object
}
*/

const initialState = {
  init     : false,
  loaded   : false,
  isLoading: false,
  customerAccounts: [],
  customerCarts: []
};

const reducer = createReducer(initialState)({
  [types.GET_CUSTOMER_ACCOUNTS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [types.GET_CUSTOMER_ACCOUNTS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.GET_CUSTOMER_ACCOUNTS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, customerAccounts: action.payload.result };
  },

  [types.ADD_CUSTOMER_ACCOUNT]: (state, action) => {
    return { ...state, isLoading: true};
  },
  [types.ADD_CUSTOMER_ACCOUNT_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.ADD_CUSTOMER_ACCOUNT_COMPLETED]: (state, action) => {
    return { ...state,
      customerAccounts: [...state.customerAccounts, action.payload.result],
      isLoading: false
    };
  },

  [types.UPDATE_CUSTOMER_ACCOUNT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.UPDATE_CUSTOMER_ACCOUNT_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.UPDATE_CUSTOMER_ACCOUNT_COMPLETED]: (state, action) => {
    const { customerId } = action.meta;
    if (customerId) {
      const index = state.customerAccounts.findIndex(el => el.id === customerId);
      return { ...state,
        isLoading: false,
        customerAccounts: [
          ...state.customerAccounts.slice(0, index),
          action.payload.result,
          ...state.customerAccounts.slice(index + 1),
        ]
      };
    }
    else {
      window.location.reload(true);
    }
  },
    [types.REMOVE_CUSTOMER_ACCOUNT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REMOVE_CUSTOMER_ACCOUNT_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.REMOVE_CUSTOMER_ACCOUNT_COMPLETED]: (state, action) => {
    const { customerId } = action.meta;
    if (customerId) {
      return { ...state,
        customerAccounts: state.customerAccounts.filter(a => a.id !== customerId),
        isLoading: false
      };
    }
    else {
      window.location.reload(true);
    }
  },

  [types.GET_CUSTOMER_CARTS]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.GET_CUSTOMER_CARTS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.GET_CUSTOMER_CARTS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false, customerCarts: action.payload.result };
  },
});

export default reducer;
