import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { modalsOperations } from "state/ducks/modal";
import { sessionOperations } from "state/ducks/session";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { centimesToCurrency } from "utils/Utils";
import "./CartBanner.css";

const CartBanner = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const session = useSelector((state) => state.session, shallowEqual);

  const user = session.user;
  const activeCart = session.activeCart;
  const activeFreeCart = session.activeFreeCart;
  const pendingCarts = session.pendingCarts;
  const pendingFreeCarts = session.pendingFreeCarts;

  let cartType, currentCart, currentPendingCarts;

  if (location.pathname.includes("/catalogue/libre")) {
    cartType = "free";
    currentCart = activeFreeCart;
    currentPendingCarts = pendingFreeCarts;
  } else if (location.pathname === "/catalogue") {
    cartType = "formalized";
    currentCart = activeCart;
    currentPendingCarts = pendingCarts;
  }

  return cartType && user ? (
    <Row className="cartBanner">
      <Row
        style={{
          maxWidth: "1245px",
          width: "100%",
          margin: "0 auto",
          padding: "10px 0 10px 0",
        }}
      >
        <Col md={2} style={{ margin: "auto" }}>
          {(currentCart !== null ||
            (currentPendingCarts && currentPendingCarts.length > 0)) && (
            <Row>
              <Col lg="12">
                <FormGroup style={{ margin: "0px" }}>
                  <Input
                    type="select"
                    name="select_panier"
                    id="select_panier"
                    onChange={(e) =>
                      dispatch(
                        sessionOperations.changeActiveCart(
                          user.id,
                          user.roles,
                          e.target.value,
                          cartType
                        )
                      )
                    }
                  >
                    {currentCart !== null ? (
                      <option
                        value={currentCart.id}
                        defaultValue={currentCart.id}
                      >
                        En cours: {currentCart.name}
                      </option>
                    ) : (
                      <option>Merci de choisir un panier</option>
                    )}
                    {currentPendingCarts &&
                      currentPendingCarts.length > 0 &&
                      currentPendingCarts.map(
                        (cart, key) =>
                          (currentCart === null ||
                            currentCart.id !== cart.id) && (
                            <option
                              value={cart.id}
                              key={key}
                              defaultValue={cart.id}
                            >
                              {cart.name}
                            </option>
                          )
                      )}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          )}
        </Col>
        {currentCart?.postal ? (
          <Col md={2} style={{ margin: "auto" }}>
            Département: <b>{currentCart.postal}</b>
          </Col>
        ) : (
          <Col md={2} style={{ margin: "auto" }}></Col>
        )}
        {currentCart?.price ? (
          <Col md={2} style={{ margin: "auto" }}>
            Total: <b>{centimesToCurrency(currentCart.price)}</b>
          </Col>
        ) : (
          <Col md={2} style={{ margin: "auto" }}></Col>
        )}
        {currentCart?.id ? (
          <Col md={2} style={{ margin: "auto" }}>
            <button
              className="btn-white btn btn-secondary"
              style={{ width: "100%" }}
            >
              <Link
                to={
                  cartType === "free"
                    ? `/compte/panier/libre/${currentCart.id}`
                    : `/compte/panier/${currentCart.id}`
                }
              >
                Voir le panier en cours
              </Link>
            </button>
          </Col>
        ) : (
          <Col md={2} style={{ margin: "auto" }}></Col>
        )}
        <Col md={2} style={{ margin: "auto" }}>
          <button
            className="btn-white btn btn-secondary"
            style={{ width: "100%" }}
          >
            <Link
              to={
                user.roles.includes("ROLE_SUPPLIER")
                  ? "/compte/fournisseur/paniers"
                  : "/compte/paniers"
              }
            >
              Tous mes paniers{" "}
              {cartType === "formalized" ? "formalisés" : "libres"}
            </Link>
          </button>
        </Col>
        <Col md={2} style={{ margin: "auto" }}>
          <button
            className="btn-white btn btn-secondary"
            style={{ width: "100%" }}
            onClick={() =>
              dispatch(
                modalsOperations.getModalStatus(
                  user.roles.includes("ROLE_SUPPLIER") &&
                    cartType === "formalized"
                    ? "panier_create_as_supplier"
                    : user.roles.includes("ROLE_SUPPLIER") &&
                      cartType === "free"
                    ? "panier_libre_create_as_supplier"
                    : user.roles.includes("ROLE_CUSTOMER") &&
                      cartType === "formalized"
                    ? "panier_create"
                    : user.roles.includes("ROLE_CUSTOMER") &&
                      cartType === "free"
                    ? "panier_libre_create"
                    : "",
                  true,
                  null
                )
              )
            }
          >
            Nouveau panier {cartType === "formalized" ? "formalisé" : "libre"}
          </button>
        </Col>
      </Row>
    </Row>
  ) : (
    <></>
  );
};

// export
export default CartBanner;
