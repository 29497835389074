import React, { Component } from "react";

// import style
import { Container, Row, Col } from "reactstrap";
import "./Footer.css";
import { Link } from "react-router-dom";

class Footer extends Component {
  /**<a id="btn-call" href="mailto:contact@centralis.site">Besoin d'une assistance ?</a>**/
  render() {
    return (
      <footer id="footer-app">
        <Container>
          <Row>
            <Col lg="6">
              <span style={{ fontFamily: "CocoGoo", fontSize: "1.25em" }}>Centralis</span><sup style={{verticalAlign:"bottom"}}>®</sup>
            </Col>
            <Col lg="6" className="text-right">
              <Link to="/mentions-legales">Mentions légales</Link> -
              <Link to="/conditions-generales"> Conditions générales</Link> -
              <Link to="/faq"> FAQ</Link> -
              <a href="mailto:contact@centralis.site"> Nous contacter</a>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

// export class
export default Footer;
