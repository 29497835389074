import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { storeOperations } from "state/ducks/store";
import { Link } from "react-router-dom";

const Suppliers = (props) => {
  const { categoryId, departmentId, cart, user } = props;
  const dispatch = useDispatch();

  const isSupplier =
    user?.roles && user?.roles.includes("ROLE_SUPPLIER") ? true : false;

  const freeArticlesBySupplier = useSelector(
    (state) => state.store.freeArticlesBySupplier,
    shallowEqual
  );

  const suppliers = useSelector(
    (state) => state.store.freeArticlesBySupplier.suppliers.data,
    shallowEqual
  );

  const hasArticlesForThisDepartment = (supplier) => {
    let hasArticle = false;
    if (departmentId === "all") {
      return true;
    }
    supplier.freeArticles.forEach((article) => {
      if (
        article.departments.filter((d) => d.id.toString() === departmentId)
          .length > 0
      ) {
        hasArticle = true;
      }
    });
    return hasArticle;
  };

  useEffect(() => {
    if (
      !freeArticlesBySupplier.suppliers.init &&
      !freeArticlesBySupplier.suppliers.isLoading
    ) {
      dispatch(
        storeOperations.getSuppliersFromFreeArticle(
          freeArticlesBySupplier.filters.category,
          freeArticlesBySupplier.filters.department
        )
      );
    } else if (freeArticlesBySupplier.filters.category !== categoryId) {
      dispatch(
        freeArticlesBySupplier.filtersFreeArticlesBySupplier(
          categoryId,
          departmentId
        )
      );
      dispatch(
        storeOperations.getSuppliersFromFreeArticle(categoryId, departmentId)
      );
    } else if (freeArticlesBySupplier.filters.department !== departmentId) {
      dispatch(
        freeArticlesBySupplier.filtersFreeArticlesBySupplier(
          categoryId,
          departmentId
        )
      );
      dispatch(
        storeOperations.getSuppliersFromFreeArticle(categoryId, departmentId)
      );
    }
  }, [dispatch, freeArticlesBySupplier, categoryId, departmentId]);

  const forbidAccessAsSupplier = (supplierId) => {
    let forbidAccessAsSupplier = false;
    if (supplierId && isSupplier) {
      if (parseInt(user.id) !== parseInt(supplierId) && !user?.admin) {
        forbidAccessAsSupplier = true;
      } else if (user?.admin?.id) {
        if (
          parseInt(user.id) !== parseInt(supplierId) &&
          parseInt(user.admin.id) !== parseInt(user.id)
        ) {
          forbidAccessAsSupplier = true;
        }
      }
    }
    return forbidAccessAsSupplier;
  };

  return (
    <>
      {suppliers.length < 1 && <div>Aucun prestataire trouvé</div>}
      {suppliers.map((supplier) => {
        return (
          <div className="bloc-lot" key={"ld-" + supplier.id}>
            {hasArticlesForThisDepartment(supplier) && isSupplier ? (
              <div className="title-bloc-lot">
                Vous n'avez pas d'article pour le département de votre panier
                actuel, Départment de votre panier:{" "}
                {cart?.department?.name && cart.department.name}
              </div>
            ) : (
              <>
                <div className="title-bloc-lot">
                  <p>
                    {supplier.name} -{" "}
                    {supplier?.address?.postal ? supplier.address.postal : ""}
                    <span style={{ paddingLeft: "84px" }}></span>
                  </p>
                  {forbidAccessAsSupplier(supplier.id) ? (
                    <Button className="btn-white" disabled={true}>
                      Réservé aux acheteurs libres
                    </Button>
                  ) : (
                    <Button className="btn-white">
                      <Link
                        to={
                          categoryId && departmentId
                            ? `/catalogue/libre/${supplier.id}?category=${categoryId}&department=${departmentId}`
                            : categoryId
                            ? `/catalogue/libre/${supplier.id}?category=${categoryId}`
                            : departmentId
                            ? `/catalogue/libre/${supplier.id}?department=${departmentId}`
                            : `/catalogue/libre/${supplier.id}`
                        }
                      >
                        Voir les articles du prestataire
                      </Link>
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

Suppliers.propTypes = {};

Suppliers.defaultProps = {};

export default Suppliers;
