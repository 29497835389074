import React from "react";
import PictoDoc from "assets/img/picto_doc.svg";
import { Container, Row, Col } from "reactstrap";
import "./Download.css";

const Download = (props) => {
  const { title, subTitle, mediaName, mediaUrl } = props;

  return (
    <section>
      <Container className="mb-5 mt-5">
        <Row>
          <Col sm={12} key={"id-1"}>
            <div className="bloc-download">
              <Row>
                <Col md="1">
                  <img
                    src={PictoDoc}
                    className="picto-user"
                    alt="Picto document"
                  />
                </Col>
                <Col md="6">
                  <p className="title-bloc-download">{title}</p>
                  <p className="subtitle-bloc-download">{subTitle}</p>
                </Col>
                <Col md="5">
                  <ul>
                    <li>
                      <a
                        href={mediaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {mediaName}
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

Download.propTypes = {};

Download.defaultProps = {};

export default Download;
