export const GET_SUPPLIER_ACCOUNTS = "session/GET_SUPPLIER_ACCOUNTS";
export const GET_SUPPLIER_ACCOUNTS_COMPLETED =
  "session/GET_SUPPLIER_ACCOUNTS_COMPLETED";
export const GET_SUPPLIER_ACCOUNTS_FAILED =
  "session/GET_SUPPLIER_ACCOUNTS_FAILED";

export const ADD_SUPPLIER_ACCOUNT = "session/ADD_SUPPLIER_ACCOUNT";
export const ADD_SUPPLIER_ACCOUNT_COMPLETED =
  "session/ADD_SUPPLIER_ACCOUNT_COMPLETED";
export const ADD_SUPPLIER_ACCOUNT_FAILED =
  "session/ADD_SUPPLIER_ACCOUNT_FAILED";

export const UPDATE_SUPPLIER_ACCOUNT = "supercustomer/UPDATE_SUPPLIER_ACCOUNT";
export const UPDATE_SUPPLIER_ACCOUNT_COMPLETED =
  "supercustomer/UPDATE_SUPPLIER_ACCOUNT_COMPLETED";
export const UPDATE_SUPPLIER_ACCOUNT_FAILED =
  "supercustomer/UPDATE_SUPPLIER_ACCOUNT_FAILED";

export const REMOVE_SUPPLIER_ACCOUNT = "session/REMOVE_SUPPLIER_ACCOUNT";
export const REMOVE_SUPPLIER_ACCOUNT_COMPLETED =
  "session/REMOVE_SUPPLIER_ACCOUNT_COMPLETED";
export const REMOVE_SUPPLIER_ACCOUNT_FAILED =
  "session/REMOVE_SUPPLIER_ACCOUNT_FAILED";

export const GET_SUPPLIER_ORDERS = "supersupplier/GET_SUPPLIER_ORDERS";
export const GET_SUPPLIER_ORDERS_COMPLETED =
  "supersupplier/GET_SUPPLIER_ORDERS_COMPLETED";
export const GET_SUPPLIER_ORDERS_FAILED =
  "supersupplier/GET_SUPPLIER_ORDERS_FAILED";

export const GENERATE_FREE_ARTICLES = "supersupplier/GENERATE_FREE_ARTICLES";
export const GENERATE_FREE_ARTICLES_COMPLETED =
  "supersupplier/GENERATE_FREE_ARTICLES_COMPLETED";
export const GENERATE_FREE_ARTICLES_FAILED =
  "supersupplier/GENERATE_FREE_ARTICLES_FAILED";
