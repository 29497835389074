import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

const ScrollToError = () => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;
  const [el, setEl] = useState(null);
  useEffect(() => {
    setEl(document.querySelector(".error-red, [data-error]"));
    if (el !== null) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [submitting, el]);
  return null;
};

export default ScrollToError;
