import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Collapse, Button, Input } from "reactstrap";
import { modalsOperations } from "state/ducks/modal";
import ArticleVariations from "./ArticleVariations";
import { centimesToCurrency } from "utils/Utils";

const Article = (props) => {
  const dispatch = useDispatch();

  const {
    orderFreeSupplier,
    orderFreeArticle,
    isAuthenticated,
    setDetailToggleArticleId,
    detailToggleArticleId,
    removeFreeArticle,
    seeAsSuper,
  } = props;

  // Toggle
  const [detailToggle, setDetailToggle] = useState(false);
  const updateToggle = (toggle, detailToggleArticleId) => {
    if (toggle) {
      setDetailToggleArticleId(detailToggleArticleId);
    } else {
      setDetailToggleArticleId(null);
    }
  };
  useEffect(() => {
    setDetailToggle(detailToggleArticleId === orderFreeArticle.id);
  }, [detailToggleArticleId, orderFreeArticle]);

  let priceMinus = orderFreeArticle.priceA;
  if (orderFreeArticle.priceC && orderFreeArticle.priceC < priceMinus) {
    priceMinus = orderFreeArticle.priceC;
  }
  if (orderFreeArticle.priceB && orderFreeArticle.priceB < priceMinus) {
    priceMinus = orderFreeArticle.priceB;
  }

  return (
    <div className="article-bloc">
      <Row>
        <Col md="3">
          <p>
            <b>{orderFreeArticle.freeArticleName}</b>
          </p>
        </Col>
        <Col md="2">
          <p>
            à partir de{" "}
            <b>
              {centimesToCurrency(priceMinus)} / {orderFreeArticle.unit}
            </b>
          </p>
        </Col>
        <Col md="1">
          <Input
            type="number"
            min="0"
            max="1000000"
            value={orderFreeArticle.quantity}
            disabled={true}
          />
        </Col>
        <Col md="2">
          <div>
            <span>
              Prix unitaire HT : <br />
              <b>{centimesToCurrency(orderFreeArticle.price)}</b>
            </span>
          </div>
        </Col>
        <Col md="1">
          <div>
            <span>
              Total HT : <br />
              <b>
                {centimesToCurrency(
                  orderFreeArticle.price * orderFreeArticle.quantity
                )}
              </b>
            </span>
          </div>
        </Col>
        <Col md="3">
          {!isAuthenticated ? (
            <div className="text-right" style={{ marginRight: "15px" }}>
              <Button
                className="btn-white"
                onClick={() =>
                  dispatch(modalsOperations.getModalStatus("login", true, null))
                }
              >
                Connectez-vous pour voir le détail
              </Button>
            </div>
          ) : !seeAsSuper ? (
            <div className="text-right" style={{ marginRight: "15px" }}>
              <Button
                onClick={(e) =>
                  removeFreeArticle(orderFreeSupplier, orderFreeArticle)
                }
                style={{ marginRight: "10px" }}
                className="btn-grey"
              >
                Retirer du panier
              </Button>

              <Button
                className="btn-white"
                onClick={() => updateToggle(!detailToggle, orderFreeArticle.id)}
              >
                Détails
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Collapse isOpen={detailToggle}>
            <div style={{ padding: "20px 10px" }}>
              <p style={{ marginBottom: "30px" }}>
                {orderFreeArticle.description}
              </p>
              {orderFreeArticle.priceA ? (
                <div>
                  <div>
                    <p>Prix unitaire:</p>
                  </div>
                  <ArticleVariations
                    quantityB={orderFreeArticle.quantityB}
                    quantityC={orderFreeArticle.quantityC}
                    priceA={orderFreeArticle.priceA}
                    priceB={orderFreeArticle.priceB}
                    priceC={orderFreeArticle.priceC}
                    unit={orderFreeArticle.unit}
                  />
                </div>
              ) : (
                <div>
                  <div>
                    <p>Aucun prestataire</p>
                  </div>
                </div>
              )}
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

Article.propTypes = {};

Article.defaultProps = {};

export default Article;
