import React from "react";
import { useDispatch } from "react-redux";
import Step5 from "./Step5"
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";
import {SUPPORTED_FORMATS_DOCUMENTS, SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, FILE_SIZE }from "constants/constants";

// Renseignements relatifs à l’aptitude à exercer l’activité professionnelle concernée par le contrat
const Step5Container = (props) => {

  const {
    formRef,
    initialValues
  } = props

  const dispatch = useDispatch();

  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    activitiesAptitudeDetails: Yup.array().of(
      Yup.object().shape({
        organization: Yup.string().required("Ce champ est requis"),
        name: Yup.string().nullable().required("Ce champ est requis"),
        proofText: Yup.string().nullable().when('proofFile', {
            is: null,
            then: Yup.string().required('Ce champ est requis si vous ne renseignez pas de pièce jointe.'),
        }),
        proofFile: Yup.string().nullable(),
        proofFileUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) )
      })),
  })

  return (
    <>
    <Formik
       initialValues={initialValues.step5}
       validationSchema={validationSchema}
       onSubmit={values => {dispatch(formSupplierOperations.setStep5(values, initialValues.id))}}
     >
       {({ errors, touched, values, setFieldValue }) => (
         <>
          <Step5 formRef={formRef} values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} initialValues={initialValues} />
         </>
       )}
     </Formik>
     </>
  );

};

Step5Container.propTypes = {
};

Step5Container.defaultProps = {

};

export default Step5Container;
