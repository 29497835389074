import * as types from "./types";

export const uploadFile = (file) => {
  var formData = new FormData();
  formData.append("file", file);
  return {
    type: types.UPLOAD_FILE,
    meta: {
      async: true,
      path: `media`,
      method: "POST",
      body: formData
    }
  }
}
