import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Collapse, Button } from 'reactstrap';
import { modalsOperations } from 'state/ducks/modal'
import { centimesToCurrency } from 'utils/Utils'
import ArticleVariations from "./ArticleVariations";
import { sessionOperations } from "state/ducks/session";

const Article = (props) => {

  const {
    article
  } = props

  const dispatch = useDispatch();

  // Toggle
  const [detailToggle, setDetailToggle] = useState(false);
  const updateToggle = (toggle) => {
    setDetailToggle(toggle)
  }

  const removeArticle = (article) => {
    if (window.confirm("Voulez-vous vraiment supprimer cet article ?")) {
      dispatch(sessionOperations.removeFreeArticle(article.id))
    }
  }

  let priceMinus = article.priceA;
  if (article.priceC && article.priceC < priceMinus) {
    priceMinus = article.priceC;
  }
  if (article.priceB && article.priceB < priceMinus) {
    priceMinus = article.priceB;
  }
  return (
    <>
      <div className="article-bloc">
        <Row>
          <Col md="4">
            <p>
              <b>{article.name}</b>
            </p>
          </Col>
          <Col md="2">
            <p>
              à partir de{" "}
              <b>
                {centimesToCurrency(priceMinus)} /{" "}
                {article.unit}
              </b>
            </p>
          </Col>
          <Col md="2">
            <div className="text-right" style={{ marginRight: "15px" }}>
              <Button
                className="btn-white"
                onClick={()=> dispatch(modalsOperations.getModalStatus('addFreeArticle', true, 'big', null, {article:article}))}
              >
                Modifier
              </Button>
            </div>
          </Col>
          <Col md="2">
            <div className="text-right" style={{ marginRight: "15px" }}>
              <Button
                className="btn-white"
                onClick={() => updateToggle(!detailToggle)}
              >
                Voir les prix
              </Button>
            </div>
          </Col>
          <Col md="2">
            <div className="text-right" style={{ marginRight: "15px" }}>
              <Button
                className="btn-white"
                onClick={() => removeArticle(article)}
              >
                Supprimer
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Collapse isOpen={detailToggle}>
              <div style={{ padding: "20px 10px" }}>
                <h4 style={{ fontSize: "20px", marginBottom: "20px" }}>
                  <b style={{ color: "#DB7124" }}></b> :{" "}
                  {article.category && article.category.name}
                </h4>
                <p style={{ marginBottom: "30px" }}>{article.description}</p>
                  <div>
                    <div>
                      <p>Prix unitaire avec palier:</p>
                    </div>
                    <ArticleVariations quantityB={article.quantityB} quantityC={article.quantityC} priceA={article.priceA} priceB={article.priceB} priceC={article.priceC} unit={article.unit} />
                  </div>
              </div>
            </Collapse>
          </Col>
        </Row>
      </div>
    </>
  );
};

Article.propTypes = {};

Article.defaultProps = {};

export default Article;
