import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormGroup, Label, Input, Spinner, Button } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { operations } from 'state/ducks/supercustomer';

const FormAddEdit = (props) => {

  const dispatch = useDispatch();

  const {
    admin,
    item,
    closeModal
  } = props

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
     id: item?.id ? item.id: null,
     firstname: item?.name ? item.firstname: '',
     lastname: item?.lastname ? item.lastname: '',
     email: item?.email ? item.email: '',
     phone: item?.phone ? item.phone: '',
     active: item?.active ? item.active: true,
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Merci de renseigner votre mail').required('Ce champ est requis'),
      firstname: Yup.string().required('Ce champ est requis'),
      lastname: Yup.string().required('Ce champ est requis')
    }),
    onSubmit: values => {
      setLoading(true);
      if (values.id) {
        dispatch(operations.updateCustomerAsSuper(values.id, values)).then(
          (response) => {
            closeModal();
          },
          (error) => {
            setLoading(false);
          }
        );
      }
      else {
        dispatch(operations.createCustomerAsSuper(admin.id, values)).then(
          (response) => {
            closeModal();
          },
          (error) => {
            setLoading(false);
          }
        );
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup
        check
        inline
      >
        <Input name="active" id="active" placeholder="Compte activé" type="checkbox" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.active} checked={formik.values.active}/>
        <Label for="active" check>Compte activé</Label>
        {formik.touched.active && formik.errors.active ? (
          <div className="input-error">{formik.errors.active}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="firstname">Prénom</Label>
        <Input name="firstname" id="firstname" placeholder="Prénom*" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstname} />
        {formik.touched.firstname && formik.errors.firstname ? (
          <div className="input-error">{formik.errors.firstname}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="lastname">Nom</Label>
        <Input name="lastname" id="lastname" placeholder="Nom*" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastname} />
        {formik.touched.lastname && formik.errors.lastname ? (
          <div className="input-error">{formik.errors.lastname}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input name="email" id="email" placeholder="Email*" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
        {formik.touched.email && formik.errors.email ? (
          <div className="input-error">{formik.errors.email}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="phone">Tel</Label>
        <Input name="phone" id="phone" placeholder="Tel" type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone} />
        {formik.touched.phone && formik.errors.phone ? (
          <div className="input-error">{formik.errors.phone}</div>
        ) : null}
      </FormGroup>
      <FormGroup className="text-right">
        {loading === true ?
          <Button
            className="btn-white"
            disabled
          >
            <Spinner animation="border" />
          </Button>
          :
          <Button
            type="submit"
            className="btn-white"
          >
            {formik?.values?.id ? "Modifier" : "Ajouter"}
          </Button>
        }
      </FormGroup>
    </Form>
  );
}

FormAddEdit.propTypes = {};

FormAddEdit.defaultProps = {};

export default FormAddEdit;
