import React from "react";
import { FieldArray, Form, ErrorMessage } from 'formik';
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError"
import { Row, Col } from 'reactstrap';
import SelectWithData from "views/pages/FormSupplier/Common/Inputs/SelectWithData"
import { INITIAL_FORM_SUPPLIER } from "constants/constants"
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError"
import swal from 'sweetalert';

const Step7 = (props) => {

  const {
    formRef,
    values,
    setFieldValue,
    contractors,
    errors
  } = props

  const removeContractorConfirm = (arrayHelpers, index) => {
    swal({
      title: "Etes vous de sûr de vouloir faire cette suppression ? ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
          arrayHelpers.remove(index)
        }
    });
  }

  return (
    <Form className="mb-4" id="step7" ref={formRef}>
      <ScrollToError />
      <h4>7.1 Effectifs moyens annuels (en ETP = Equivalents temps pleins) du candidat et l'importance du personnel d'encadrement pendant les trois dernières années :</h4>
      <FieldArray
        name="averageAnnualWorkforce"
        render={arrayHelpers => {
          const averageAnnualWorkforce = values.averageAnnualWorkforce;
          return (
            <div>
              {averageAnnualWorkforce && averageAnnualWorkforce.map((contractor, index) => (
                <div className="card mb-4" key={index}>
                  <Row>
                    <Col xs="12" sm="12">
                      <label>Entreprise</label>
                      <FieldWithError name={`averageAnnualWorkforce.${index}.organization`} placeholder={""} readonly />
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Effectifs moyens annuels (N-1)</label>
                      <FieldWithError name={`averageAnnualWorkforce.${index}.y1Average`} placeholder={"En ETP"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Part du personnel d'encadrement en %</label>
                      <FieldWithError name={`averageAnnualWorkforce.${index}.y1PartStaff`} placeholder={"En %"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Effectifs moyens annuels (N-2)</label>
                      <FieldWithError name={`averageAnnualWorkforce.${index}.y2Average`} placeholder={"En ETP"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Part du personnel d'encadrement en %</label>
                      <FieldWithError name={`averageAnnualWorkforce.${index}.y2PartStaff`} placeholder={"En %"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Effectifs moyens annuels (N-3)</label>
                      <FieldWithError name={`averageAnnualWorkforce.${index}.y3Average`} placeholder={"En ETP"}/>
                    </Col>
                    <Col xs="12" sm="6">
                      <label>Part du personnel d'encadrement en %</label>
                      <FieldWithError name={`averageAnnualWorkforce.${index}.y3PartStaff`} placeholder={"En %"}/>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          );
        }}
      />

      <h4>7.2 Principales livraisons effectuées ou principaux services fournis au cours des trois dernières années (les plus semblables au contrat) 10 maximum :</h4>

      <FieldArray
        name="mainDeliveries"
        render={arrayHelpers => {
          const mainDeliveries = values.mainDeliveries;
          return (
            <div>
              {mainDeliveries && mainDeliveries.map((mainDelivery, index) => (
                <>
                  <div className="card mb-4" key={index}>
                    <Row>
                      <Col xs="12" sm="12">
                        <label>Entreprise concernée</label>
                        <SelectWithData name={`mainDeliveries.${index}.organization`} items={contractors} />
                      </Col>
                      <Col xs="12" sm="12">
                        <label>Principale livraison effectuée ou principaux services fournis au cours des trois dernières années </label>
                        <FieldWithError as="textarea" name={`mainDeliveries.${index}.name`} placeholder={"Description ..."} />
                      </Col>
                      <Col xs="12" sm="4">
                        <label>Budget</label>
                        <FieldWithError name={`mainDeliveries.${index}.budget`} placeholder={"En €"}/>
                      </Col>
                      <Col xs="12" sm="4">
                        <label>Année</label>
                        <FieldWithError name={`mainDeliveries.${index}.year`} placeholder={""}/>
                      </Col>
                      <Col xs="12" sm="4">
                        <label>Attestation de bonne exécution (JPG ou PDF, taille max 8mo)</label>
                        <div className="form-inline">
                          {mainDelivery?.proof ? <a href={mainDelivery?.proof} target="_blank" rel="noopener noreferrer" className="fileLink">Voir mon fichier chargé</a> : null}
                          <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`mainDeliveries.${index}.proofUpload`} type="file" onChange={(event) => {
                            setFieldValue(`mainDeliveries.${index}.proofUpload`, event.currentTarget.files[0]);
                          }} />
                          <ErrorMessage name={`mainDeliveries.${index}.proofUpload`} component="div" className="error-red"/>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <button
                        className="btn-red mt-2 mb-2"
                        type="button"
                        onClick={() => removeContractorConfirm(arrayHelpers, index)}
                      >
                        Supprimer cette livraison
                      </button>
                    </div>
                  </div>
                </>
              ))}

              {values.mainDeliveries.length < 10 &&
                <>
                  <button type="button" onClick={() => arrayHelpers.push(INITIAL_FORM_SUPPLIER.step7.mainDeliveries[0])} className="btn-grey">
                    Ajouter une livraison
                  </button>
                </>
              }
            </div>
          );
        }}
      />
      {typeof errors.mainDeliveries === 'string'&& <ErrorMessage name="mainDeliveries" component="div" className="error-red"/>}
    </Form>

  );

};

Step7.propTypes = {
};

Step7.defaultProps = {

};

export default Step7;
