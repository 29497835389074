import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    session: object
}
*/

const initialState = {
  init: false,
  loaded: false,
  isLoading: false,
  supplierAccounts: [],
};

const reducer = createReducer(initialState)({
  [types.GET_SUPPLIER_ACCOUNTS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [types.GET_SUPPLIER_ACCOUNTS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.GET_SUPPLIER_ACCOUNTS_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      supplierAccounts: action.payload.result,
    };
  },

  [types.GET_SUPPLIER_ORDERS]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.GET_SUPPLIER_ORDERS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.GET_SUPPLIER_ORDERS_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [types.ADD_SUPPLIER_ACCOUNT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.ADD_SUPPLIER_ACCOUNT_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.ADD_SUPPLIER_ACCOUNT_COMPLETED]: (state, action) => {
    return {
      ...state,
      supplierAccounts: [...state.supplierAccounts, action.payload.result],
      isLoading: false,
    };
  },

  [types.UPDATE_SUPPLIER_ACCOUNT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.UPDATE_SUPPLIER_ACCOUNT_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.UPDATE_SUPPLIER_ACCOUNT_COMPLETED]: (state, action) => {
    const { supplierId } = action.meta;
    if (supplierId) {
      const index = state.supplierAccounts.findIndex(
        (el) => el.id === supplierId
      );
      return {
        ...state,
        isLoading: false,
        supplierAccounts: [
          ...state.supplierAccounts.slice(0, index),
          action.payload.result,
          ...state.supplierAccounts.slice(index + 1),
        ],
      };
    } else {
      window.location.reload(true);
    }
  },

  [types.REMOVE_SUPPLIER_ACCOUNT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REMOVE_SUPPLIER_ACCOUNT_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.REMOVE_SUPPLIER_ACCOUNT_COMPLETED]: (state, action) => {
    const { supplierId } = action.meta;
    if (supplierId) {
      return {
        ...state,
        supplierAccounts: state.supplierAccounts.filter(
          (a) => a.id !== supplierId
        ),
        isLoading: false,
      };
    } else {
      window.location.reload(true);
    }
  },

  [types.GENERATE_FREE_ARTICLES]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.GENERATE_FREE_ARTICLES_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.GENERATE_FREE_ARTICLES_COMPLETED]: (state, action) => {
    window.location.reload(true);
  },
});

export default reducer;
