export const SET_STEP_IN_PROGRESS = 'formSupplier/SET_STEP_IN_PROGRESS'
export const SET_STEP_IS_LOADING = 'formSupplier/SET_STEP_IS_LOADING'

export const SET_CRITICAL_ERROR = 'formSupplier/SET_CRITICAL_ERROR'

export const RESET_CANDIDATE = 'formSupplier/RESET_CANDIDATE'

export const INIT_CANDIDATE           = 'formSupplier/INIT_CANDIDATE'
export const INIT_CANDIDATE_COMPLETED = 'formSupplier/INIT_CANDIDATE_COMPLETED'
export const INIT_CANDIDATE_FAILED    = 'formSupplier/INIT_CANDIDATE_FAILED'

export const GET_CANDIDATE           = 'formSupplier/GET_CANDIDATE'
export const GET_CANDIDATE_COMPLETED = 'formSupplier/GET_CANDIDATE_COMPLETED'
export const GET_CANDIDATE_FAILED    = 'formSupplier/GET_CANDIDATE_FAILED'

export const GET_CANDIDATES           = "formSupplier/GET_CANDIDATES";
export const GET_CANDIDATES_COMPLETED = "formSupplier/GET_CANDIDATES_COMPLETED";
export const GET_CANDIDATES_FAILED    = "formSupplier/GET_CANDIDATES_FAILED";

export const UPDATE_CANDIDATE           = 'formSupplier/UPDATE_CANDIDATE'
export const UPDATE_CANDIDATE_COMPLETED = 'formSupplier/UPDATE_CANDIDATE_COMPLETED'
export const UPDATE_CANDIDATE_FAILED    = 'formSupplier/UPDATE_CANDIDATE_FAILED'

export const GENERATE_PDF           = 'formSupplier/GENERATE_PDF'
export const GENERATE_PDF_COMPLETED = 'formSupplier/GENERATE_PDF_COMPLETED'
export const GENERATE_PDF_FAILED    = 'formSupplier/GENERATE_PDF_FAILED'

export const GET_CANDIDATE_PRICES           = "formSupplier/GET_CANDIDATE_PRICES";
export const GET_CANDIDATE_PRICES_COMPLETED = "formSupplier/GET_CANDIDATE_PRICES_COMPLETED";
export const GET_CANDIDATE_PRICES_FAILED    = "formSupplier/GET_CANDIDATE_PRICES_FAILED";

export const UPDATE_CANDIDATE_PRICES           = "formSupplier/UPDATE_CANDIDATE_PRICES";
export const UPDATE_CANDIDATE_PRICES_COMPLETED = "formSupplier/UPDATE_CANDIDATE_PRICES_COMPLETED";
export const UPDATE_CANDIDATE_PRICES_FAILED    = "formSupplier/UPDATE_CANDIDATE_PRICES_FAILED";

export const CHECK_CANDIDATE_PRICES           = "formSupplier/CHECK_CANDIDATE_PRICES";
export const CHECK_CANDIDATE_PRICES_COMPLETED = "formSupplier/CHECK_CANDIDATE_PRICES_COMPLETED";
export const CHECK_CANDIDATE_PRICES_FAILED    = "formSupplier/CHECK_CANDIDATE_PRICES_FAILED";

export const FILTER_CANDIDATE_PRICES    = "formSupplier/FILTER_CANDIDATE_PRICES";

export const RESET_CANDIDATE_PRICES    = "formSupplier/RESET_CANDIDATE_PRICES";

export const SAVE_SUBMITTED_INPUTS    = "formSupplier/SAVE_SUBMITTED_INPUTS";
