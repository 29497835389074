import React from "react";
import Layout from "./Common/Layout"
import PropTypes from "prop-types";

const FormSupplierError = (props) => {

  const {
    errorType,
    handleAction
  } = props;

  return (
    <Layout>
      <div className="mt-3">
      {errorType === 'candidateNotFound' ?
        <p>
          Votre candidature est actuellement introuvable.<br/>
          Merci de nous contacter ou de recommencer une candidature en <button onClick={() => handleAction()} >cliquant ici.</button>
        </p>
        :
        <p>
        Erreur {errorType}
        </p>
      }
      </div>
    </Layout>
  );

};

FormSupplierError.propTypes = {
  errorType: PropTypes.string,
  handleAction: PropTypes.func
};

FormSupplierError.defaultProps = {
  errorType: null,
  handleAction: null
};

export default FormSupplierError;
