import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Article from "./Article";
import { sessionOperations } from "state/ducks/session";
import { centimesToCurrency } from "utils/Utils";

const Suppliers = (props) => {
  const { isAuthenticated, cart, seeAsSuper } = props;

  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  const [detailToggleArticleId, setDetailToggleArticleId] = useState(null);

  const removeOrderFreeSupplier = (orderFreeSupplierId) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce lot du panier ?")) {
      dispatch(sessionOperations.removeFreeSupplier(orderFreeSupplierId));
    }
  };

  const removeFreeArticle = (orderFreeSupplier, orderFreeArticle) => {
    dispatch(sessionOperations.removeArticle(orderFreeArticle.id, "free"));
  };

  return (
    <div>
      {cart.orderFreeSuppliers.map((orderFreeSupplier, indexL) => {
        return (
          <div
            style={{ marginBottom: "25px" }}
            className="bloc-lot"
            key={indexL}
          >
            <div className="title-bloc-lot">
              <p className="text-left">
                {orderFreeSupplier.supplierName} (
                {orderFreeSupplier.orderFreeArticles.length}{" "}
                {orderFreeSupplier.orderFreeArticles.length === 1
                  ? "article"
                  : "articles"}
                ) - Montants Total HT :{" "}
                {centimesToCurrency(orderFreeSupplier.price)}
              </p>
              {session?.user?.id &&
                cart?.customer?.id &&
                session.user.id === cart.customer.id &&
                !seeAsSuper && (
                  <button
                    className="btn-white"
                    onClick={() =>
                      removeOrderFreeSupplier(orderFreeSupplier.id)
                    }
                  >
                    Retirer le fournisseur du panier
                  </button>
                )}
            </div>
            {orderFreeSupplier.orderFreeArticles.map(
              (orderFreeArticle, indexA) => {
                return (
                  <div
                    key={
                      "ld-" +
                      orderFreeSupplier.id +
                      "-article-" +
                      orderFreeArticle.id
                    }
                  >
                    <Article
                      orderFreeSupplier={orderFreeSupplier}
                      orderFreeArticle={orderFreeArticle}
                      isAuthenticated={isAuthenticated}
                      setDetailToggleArticleId={setDetailToggleArticleId}
                      detailToggleArticleId={detailToggleArticleId}
                      removeFreeArticle={removeFreeArticle}
                      seeAsSuper={seeAsSuper}
                    />
                  </div>
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
};

Suppliers.propTypes = {};

Suppliers.defaultProps = {};

export default Suppliers;
