import React from "react";
import {Row, Col, Table} from "reactstrap";
import PropTypes from "prop-types";
import {centimesToCurrency} from "utils/Utils";

/**
 * Affiche les variations de prix d'un produit dans un tableau en fonction du rang et du palier
 */
const ArticleVariations = (props) => {
    const {prices, unit, quantityB, quantityC, supplierInfoOnThisLot} = props;

    let rank = null;
    let pricesFilter = prices;

    // Check if prices is defined and is an array
    if (!prices || !Array.isArray(prices)) {
        return <div>Error: Prices is not defined or not in the correct format.</div>;
    }

    if (supplierInfoOnThisLot !== undefined) {
        rank = supplierInfoOnThisLot.rank;
        if (rank - 1 < prices.length) {
            pricesFilter = prices.filter(function (p) {
                return prices.indexOf(p) === rank - 1;
            });
        }
    }

    // Check if pricesFilter is defined and is an array
    if (!pricesFilter || !Array.isArray(pricesFilter)) {
        return <div>Error: Filtered prices is not defined or not in the correct format.</div>;
    }
    return (
        <Row>
            <Col md="12">
                <Table>
                    <thead>
                    <tr>
                        <th></th>
                        {pricesFilter[0].map((palier, i) => {
                            let palierTxt;
                            if (i === 0) {
                                palierTxt = "1 à " + (quantityB - 1);
                            } else if (i === 1) {
                                palierTxt = quantityB + " à " + quantityC;
                            } else {
                                palierTxt = "> à " + quantityC;
                            }

                            return <th key={i}>{palierTxt}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {pricesFilter[0] && pricesFilter.map((palier, i) => {
                        if (pricesFilter[i].length > 0) {
                            return (
                                <tr key={i}>
                                    <th key={i}>
                                        {" "}
                                        {rank !== null
                                            ? `Prestataire Rang ${rank}`
                                            : `Prestataire Rang ${i + 1}`}
                                    </th>
                                    {pricesFilter[i].map((keyPrice, rang) => {
                                        return (
                                            <td key={rang}>
                                                {keyPrice >= 0
                                                    ? centimesToCurrency(keyPrice) + " / " + unit
                                                    : null}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        } else {
                            return (
                                <tr key={i}>
                                    <th key={i}>
                                        {rank !== null
                                            ? `Prestataire Rang ${rank}`
                                            : `Prestataire Rang ${i + 1}`}
                                    </th>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            );
                        }
                    })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

ArticleVariations.propTypes = {
    prices: PropTypes.array,
    unit: PropTypes.string,
};

ArticleVariations.defaultProps = {
    prices: [],
    unit: "",
};

export default ArticleVariations;
