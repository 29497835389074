import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/fr";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import Account from "./../../Account";
import BlocInfos from "views/universal/BlocInfos/BlocInfos";
import IconPanier from "assets/img/icon-panier.svg";
import { centimesToCurrency } from "utils/Utils";
import { sessionOperations } from "state/ducks/session";
import { operations as supercustomerOperations } from "state/ducks/supercustomer";

const Carts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [customerCarts, setCustomerCarts] = useState(null);
  const customerId = parseInt(props.match.params.customerId);

  const session = useSelector((state) => state.session, shallowEqual);

  const supercustomer = useSelector(
    (state) => state.supercustomer,
    shallowEqual
  );

  useEffect(() => {
    if (
      session.isLoading === false &&
      session.init === true &&
      supercustomer.isLoading === false &&
      customerCarts === null &&
      customerId
    ) {
      dispatch(supercustomerOperations.getCustomerCarts(customerId)).then(
        (response) => {
          setCustomerCarts(response.data);
        }
      );
    }
  }, [dispatch, session, supercustomer, customerCarts, customerId]);

  const responseToProposal = (cart, resp) => {
    let cartType = cart.orderFreeSuppliers ? "free" : "formalized";
    return dispatch(
      sessionOperations.responseToProposalCart(cart.id, cartType, resp)
    ).then((response) => {
      if (cartType === "free") {
        history.push(`/compte/panier/libre/${cart.id}`);
      } else {
        history.push(`/compte/panier/${cart.id}`);
      }
    });
  };

  const renderNotSentPanier = (panier, cartType) => {
    return (
      <div key={panier.id} className="bloc-panier">
        <Row style={{ marginBottom: "30px" }}>
          <Col md={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="statut-panier">À valider</div>
              <div>
                <span>
                  Panier du :{" "}
                  <strong>
                    {moment(panier.date).locale("fr").format("L")}
                  </strong>
                </span>
              </div>
            </div>
          </Col>
          <Col md={6}>
            {panier.supplier ? (
              <h3 style={{ fontSize: "22px", textAlign: "right" }}>
                {panier.name} proposé par {panier.supplier.name}
              </h3>
            ) : (
              <h3 style={{ fontSize: "22px", textAlign: "right" }}>
                {panier.name}
              </h3>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={6} className="d-flex align-items-center">
            <ul className="list-infos-panier">
              <li>
                <b style={{ color: "#2C3948" }}>{panier.countArticles}</b>{" "}
                {panier.countArticles > 1 ? "articles" : "article"}
              </li>
              <li>
                Montant Total HT{" "}
                <b style={{ color: "#2C3948" }}>
                  {centimesToCurrency(panier.price)}
                </b>
              </li>
            </ul>
          </Col>
          <Col md={6}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              {panier.supplier && panier.status === 10 ? (
                <Row>
                  <Col md={6}>
                    <button
                      className="btn-green float-right"
                      onClick={() => responseToProposal(panier, "refuse")}
                    >
                      {" "}
                      Rejeter{" "}
                    </button>
                  </Col>
                  <Col md={6}>
                    <button
                      className="btn-green float-right"
                      onClick={() => responseToProposal(panier, "accept")}
                    >
                      {" "}
                      Voir le panier{" "}
                    </button>
                  </Col>
                </Row>
              ) : (
                <>
                  {cartType ? (
                    <Link
                      to={{ pathname: `/compte/panier/libre/${panier.id}` }}
                    >
                      <button className="btn-green float-right">
                        {" "}
                        Voir le panier{" "}
                      </button>
                    </Link>
                  ) : (
                    <Link to={{ pathname: `/compte/panier/${panier.id}` }}>
                      <button className="btn-green float-right">
                        {" "}
                        Voir le panier{" "}
                      </button>
                    </Link>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPaniers = () => {
    if (customerCarts) {
      if (
        customerCarts.pendingCarts.length < 1 &&
        customerCarts.pendingFreeCarts.length < 1 &&
        customerCarts.proposalCarts.length < 1 &&
        customerCarts.proposalFreeCarts.length < 1
      ) {
        return <BlocInfos title="Aucun panier en cours" icon={IconPanier} />;
      } else {
        return (
          <>
            <span>
              {customerCarts.proposalCarts &&
                customerCarts.proposalCarts.length > 0 &&
                customerCarts.proposalCarts.map((cart) =>
                  renderNotSentPanier(cart)
                )}
            </span>
            <span>
              {customerCarts.proposalFreeCarts &&
                customerCarts.proposalFreeCarts.length > 0 &&
                customerCarts.proposalFreeCarts.map((cart) =>
                  renderNotSentPanier(cart, "free")
                )}
            </span>
            <span>
              {customerCarts.pendingCarts &&
                customerCarts.pendingCarts.length > 0 &&
                customerCarts.pendingCarts.map((cart) =>
                  renderNotSentPanier(cart)
                )}
            </span>
            <span>
              {customerCarts.pendingFreeCarts &&
                customerCarts.pendingFreeCarts.length > 0 &&
                customerCarts.pendingFreeCarts.map((cart) =>
                  renderNotSentPanier(cart, "free")
                )}
            </span>
          </>
        );
      }
    } else {
      return <GlobalLoader message={"Chargement des paniers en cours ..."} />;
    }
  };

  return (
    <Account>
      <div className="bloc-panier bloc-panier-sent">
        <Row style={{ marginBottom: "30px" }}>
          <Col md={6} className="d-flex align-items-center">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="date-panier">
                <b>Compte de {customerCarts?.customer?.email}</b>
              </span>
            </div>
          </Col>
        </Row>
      </div>
      {renderPaniers()}
    </Account>
  );
};

Carts.propTypes = {};

Carts.defaultProps = {};

export default Carts;
