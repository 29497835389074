import React from "react";
import { Field, Form, ErrorMessage } from 'formik';
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError"

const Step11 = (props) => {

  const {
    formRef,
    initialValues,
  } = props

  return (
    <Form className="mb-4" id="step11" ref={formRef}>
      {initialValues.id && initialValues?.step11?.previewPDF &&
        <iframe src={`${initialValues.step11.previewPDF}#toolbar=0`} width="100%" height="500px" title="previsualisation-contrat"> </iframe>
      }

      <label>Fait à : </label>
      <FieldWithError name="madeIn" placeholder={"Nom de ma ville ..."} readonly={true}/>

      <div className="form-check mt-4">
         <Field id="acceptDeal" type="checkbox" name="acceptDeal" className="form-check-input"/>
         <label className="checkbox-label" htmlFor="acceptDeal">
          En cochant cette case, je déclare avoir lu et accepté l’accord cadre complété de mes réponses.
         </label>
        <ErrorMessage name="acceptDeal" component="div" className="error-red"/>
      </div>

    </Form>

  );

};

Step11.propTypes = {
};

Step11.defaultProps = {

};

export default Step11;
