import React from "react";
import { FastField, Field, ErrorMessage } from 'formik';

const FieldWithError = (props) => {

  const {
    name,
    placeholder,
    as,
    readonly,
    type,
    max,
    min,
    fastfield
  } = props

  return (
    <div className="mr-sm-1 mx-sm-1 form-group my-1">
      {fastfield ?
        <FastField as={as} name={name} className="form-control" placeholder={placeholder}  readOnly={readonly} type={type} max={max} min={min}/>
        :
        <Field as={as} name={name} className="form-control" placeholder={placeholder}  readOnly={readonly} type={type} max={max} min={min}/>
      }
      <ErrorMessage name={name} component="div" className="error-red"/>
    </div>
  );

};

FieldWithError.propTypes = {
};

FieldWithError.defaultProps = {

};

export default FieldWithError;
