import React from "react";
import { useDispatch } from "react-redux";
import Step7 from "./Step7"
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";
import {SUPPORTED_FORMATS_DOCUMENTS, SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, FILE_SIZE }from "constants/constants";

// Renseignements relatifs à la capacité technique et professionnelle du candidat individuel ou du membre du groupement
const Step7Container = (props) => {

  const {
    formRef,
    initialValues,
    contractors
  } = props

  const dispatch = useDispatch();

  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    averageAnnualWorkforce: Yup.array().of(
      Yup.object().shape({
        organization: Yup.string().nullable().required("Ce champ est requis"),
        y1Average:  Yup.string().nullable().required("Ce champ est requis"),
        y1PartStaff:  Yup.string().nullable().required("Ce champ est requis"),
        y2Average:  Yup.string().nullable().required("Ce champ est requis"),
        y2PartStaff:  Yup.string().nullable().required("Ce champ est requis"),
        y3Average:  Yup.string().nullable().required("Ce champ est requis"),
        y3PartStaff:  Yup.string().nullable().required("Ce champ est requis"),
      })),
    mainDeliveries: Yup.array().of(
      Yup.object().shape({
        organization: Yup.string().nullable().required("Ce champ est requis"),
        name:  Yup.string().max(1000, "1000 Caractères maximum").required("Ce champ est requis"),
        budget:  Yup.string().nullable().required("Ce champ est requis"),
        year:  Yup.string().nullable().required("Ce champ est requis"),
        proof: Yup.string().nullable(),
        proofUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) ).when('proof', {
            is: null,
            then: Yup.mixed().nullable(),
        }),
      })),
  })

  return (
    <>
    <Formik
       initialValues={initialValues.step7}
       validationSchema={validationSchema}
       onSubmit={values => {dispatch(formSupplierOperations.setStep7(values, initialValues.id))}}
     >
       {({ errors, touched, values, setFieldValue }) => (
         <>
          <Step7 formRef={formRef} values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} initialValues={initialValues} contractors={contractors}/>
         </>
       )}
     </Formik>
     </>
  );

};

Step7Container.propTypes = {
};

Step7Container.defaultProps = {

};

export default Step7Container;
