// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import Account from "./../Account";
// import Paper from "@material-ui/core/Paper";
// import { Container, Row, Col } from "reactstrap";
// import DataTable from "./components/Tables/DataTable";
// import { operations as supersupplierOperations } from "state/ducks/supersupplier";
// import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";

/**
 * @source https://github.com/olinations/crud-starter-frontend
 */
const Accounts = (props) => {
  // const dispatch = useDispatch();

  // const session = useSelector((state) => state.session, shallowEqual);

  // const supersupplier = useSelector(
  //   (state) => state.supersupplier,
  //   shallowEqual
  // );

  // const [accounts, setAccounts] = useState(null);
  // useEffect(() => {
  //   if (
  //     session.isLoading === false &&
  //     session.init === true &&
  //     supersupplier.isLoading === false &&
  //     supersupplier.init === false &&
  //     accounts === null
  //   ) {
  //     dispatch(
  //       supersupplierOperations.getSupplierAccounts(session.user.id)
  //     ).then((response) => {
  //       setAccounts(response.data);
  //     });
  //   }
  // }, [dispatch, session, supersupplier, accounts]);

  // return (
  //   <Account>
  //     {(session.isLoading || supersupplier.isLoading) && (
  //       <GlobalLoader message={"Chargement en cours ..."} />
  //     )}
  //     <div style={{ padding: "0px" }}>
  //       <Paper elevation={1} style={{ marginBottom: "15px" }}>
  //         <div style={{ padding: "30px" }}>
  //           <Container className="App">
  //             {supersupplier.supplierAccounts.length > 0 && (
  //               <Row>
  //                 <Col>
  //                   <DataTable
  //                     items={supersupplier.supplierAccounts}
  //                     admin={session.user}
  //                     buttonLabel="Editer"
  //                   />
  //                 </Col>
  //               </Row>
  //             )}
  //             {/* <Row>
  //               <Col sm="3"></Col>
  //               <Col sm="6">
  //                 <ModalForm
  //                   buttonLabel="Ajouter un compte"
  //                   admin={session.user}
  //                 />
  //               </Col>
  //               <Col sm="3"></Col>
  //             </Row> */}
  //           </Container>
  //         </div>
  //       </Paper>
  //     </div>
  //   </Account>
  // );
};

Accounts.propTypes = {};

Accounts.defaultProps = {};

export default Accounts;
