import { combineReducers } from "redux";
import * as actionTypes from "./types";
import { createReducer } from "state/utils";

/* State shape
{
  categoriesLot: object,
  lots: object,
  departments: object
}
*/
const init = {
  data: [],
  isLoading: false,
  init: false,
};

const lotsData = {
  data: { products: [], departmentCode: null, edition: null },
  filters: { category: "all", prestation: "", edition: null },
  isLoading: false,
  init: false,
  loaded: false,
};

const suppliersData = {
  data: {freeSuppliers:[], suppliers:[]},
  filters: {
    company: "",
    department: "all",
    rank: "all",
    category: "all",
    cartType: "free",
    edition: null,
    limit: 10000,
    offset: 0
  },
  freeFilters: {
    company: "",
    department: "all",
    rank: "all",
    category: "all",
    cartType: "free",
    edition: null,
    limit: 100,
    offset: 0
  },
  isLoading: false,
  init: false,
  loaded: false,
};

const categoriesData = {
  data: [],
  edition: null,
  isLoading: false,
  init: false,
  loaded: false,
};

const freeArticlesData = {
  data: [],
  filters: { department: "all", category: "all" },
  isLoading: false,
  init: false,
  loaded: false,
  pages: [],
  end: false,
};

const freeArticlesBySupplierData = {
  data: [],
  filters: { department: "all", category: "all" },
  isLoading: false,
  init: false,
  loaded: false,
  pages: [],
  end: false,
  suppliers: {
    isLoading: false,
    init: false,
    loaded: false,
    data: [],
  },
};

const lotsReducer = createReducer(lotsData)({
  [actionTypes.GET_LOTS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_LOTS_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result,
      edition: parseInt(action.meta.params.edition),
      isLoading: false,
      loaded: true,
    };
  },
  [actionTypes.GET_LOTS_FAILED]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.FILTERS_LOTS]: (state, action) => {
    const { category, prestation, edition } = action.payload;
    return {
      ...state,
      filters: {
        category: category,
        prestation: prestation,
        edition: edition,
      },
    };
  },
});

const categoriesLotReducer = createReducer(init)({
  [actionTypes.GET_CATEGORIES_LOT]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_CATEGORIES_LOT_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result,
      edition: parseInt(action.meta.params.edition),
      isLoading: false,
      loaded: true,
    };
  },
  [actionTypes.GET_CATEGORIES_LOT_FAILED]: (state, action) => {
    return { ...state, isLoading: true };
  },
});

const departmentsReducer = createReducer(init)({
  [actionTypes.GET_DEPARTMENTS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_DEPARTMENTS_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result,
      isLoading: false,
      loaded: true,
    };
  },
  [actionTypes.GET_DEPARTMENTS_FAILED]: (state, action) => {
    return { ...state, isLoading: true };
  },
});

const suppliersReducer = createReducer(suppliersData)({
  [actionTypes.GET_SUPPLIERS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_SUPPLIERS_COMPLETED]: (state, action) => {
    // Si offset est à 0, on remplace tous les résultats

    let cartType = action?.meta?.params?.type;

    if (cartType === "free") {
      let offset = state.freeFilters.offset;
      if (offset === 0) {
        return {
          ...state,
          data: {freeSuppliers:action.payload.result, suppliers:[...state.data.suppliers]},
          isLoading: false,
          loaded: true,
        };
      }
      return {
        ...state,
        data: {
          freeSuppliers:[...state.data.freeSuppliers, ...action.payload.result], 
          suppliers:[...state.data.suppliers]
        },
        isLoading: false,
        loaded: true,
      };
    }
    else {
      let offset = state.filters.offset;
      if (offset === 0) {
        return {
          ...state,
          data: {suppliers:action.payload.result, freeSuppliers:[...state.data.freeSuppliers]},
          isLoading: false,
          loaded: true,
        };
      }
      return {
        ...state,
        data: {
          suppliers:[...state.data.suppliers, ...action.payload.result], 
          freeSuppliers:[...state.data.freeSuppliers]
        },
        isLoading: false,
        loaded: true,
      };
    }
  },
  [actionTypes.GET_SUPPLIERS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [actionTypes.FILTERS_SUPPLIERS]: (state, action) => {
    const { company, department, rank, category, cartType, edition, limit, offset } =
      action.payload;
    return {
      ...state,
      filters: {
        company: company,
        department: department,
        rank: rank,
        category: category,
        cartType: cartType,
        edition: edition,
        limit: limit,
        offset: offset,
      },
    };
  },
  [actionTypes.FILTERS_FREE_SUPPLIERS]: (state, action) => {
    const { company, department, rank, category, cartType, edition, limit, offset } =
      action.payload;
    return {
      ...state,
      freeFilters: {
        company: company,
        department: department,
        rank: rank,
        category: category,
        cartType: cartType,
        edition: edition,
        limit: limit,
        offset: offset,
      },
    };
  },
  [actionTypes.RESET_FILTERS_SUPPLIERS]: (state, action) => {
    return {
      ...state,
      data: [],
      filters: {
        company: "",
        department: "all",
        rank: "all",
        category: "all",
        cartType: "free",
        edition: null,
        limit: 100,
        offset: null
      },
      isLoading: false,
    };
  },
  [actionTypes.RESET_FILTERS_FREE_SUPPLIERS]: (state, action) => {
    return {
      ...state,
      data: [],
      freeFilters: {
        company: "",
        department: "all",
        rank: "all",
        category: "all",
        cartType: "free",
        edition: null,
        limit: 100,
        offset: null
      },
      isLoading: false,
    };
  },
});

const customersReducer = createReducer(init)({
  [actionTypes.GET_CUSTOMERS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_CUSTOMERS_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result,
      isLoading: false,
      loaded: true,
    };
  },
  [actionTypes.GET_CUSTOMERS_FAILED]: (state, action) => {
    return { ...state, isLoading: true };
  },
});

const documentsData = {
  data: [],
  isLoading: false,
  loaded: false,
  init: false,
};

const documentsReducer = createReducer(documentsData)({
  [actionTypes.GET_DOCUMENTS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_DOCUMENTS_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result,
      isLoading: false,
      loaded: true,
      type: action.meta.params.type,
    };
  },
  [actionTypes.GET_DOCUMENTS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
});

const pagesData = {
  data: [],
  isLoading: false,
  loaded: false,
  init: false,
};
const pagesReducer = createReducer(pagesData)({
  [actionTypes.GET_PAGES]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_PAGES_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result,
      isLoading: false,
      loaded: true,
    };
  },
  [actionTypes.GET_PAGES_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
});

const categoriesReducer = createReducer(categoriesData)({
  [actionTypes.GET_CATEGORIES]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_CATEGORIES_COMPLETED]: (state, action) => {
    return {
      ...state,
      data: action.payload.result,
      isLoading: false,
      loaded: true,
    };
  },
  [actionTypes.GET_CATEGORIES_FAILED]: (state, action) => {
    return { ...state, isLoading: true };
  },
});

const freeArticlesReducer = createReducer(freeArticlesData)({
  [actionTypes.GET_FREE_ARTICLES]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [actionTypes.GET_FREE_ARTICLES_COMPLETED]: (state, action) => {
    if (state.pages.includes(action.payload.pageNumber)) {
      return { ...state, isLoading: false };
    } else {
      let end = false;
      // 100 items par page
      if (action.payload.result && action.payload.result.length < 100) {
        end = true;
      }
      return {
        ...state,
        isLoading: false,
        pages: [...state.pages, action.payload.pageNumber],
        data: [...state.data, ...action.payload.result],
        end: end,
      };
    }
  },
  [actionTypes.GET_FREE_ARTICLES_FAILED]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.FILTERS_FREE_ARTICLES]: (state, action) => {
    const { category, department } = action.payload;
    return {
      ...state,
      filters: {
        category: category,
        department: department,
      },
    };
  },
});

const freeArticlesBySupplierReducer = createReducer(freeArticlesBySupplierData)(
  {
    [actionTypes.FILTERS_FREE_ARTICLES_BY_SUPPLIER]: (state, action) => {
      const { category, department } = action.payload;
      return {
        ...state,
        filters: {
          category: category,
          department: department,
        },
      };
    },
    [actionTypes.GET_FREE_ARTICLES_BY_SUPPLIER]: (state, action) => {
      return { ...state, isLoading: true, init: true };
    },
    [actionTypes.GET_FREE_ARTICLES_BY_SUPPLIER_COMPLETED]: (state, action) => {
      if (state.pages.includes(action.payload.pageNumber)) {
        return { ...state, isLoading: false };
      } else {
        let end = false;
        // 100 items par page
        if (action.payload.result && action.payload.result.length < 100) {
          end = true;
        }
        return {
          ...state,
          isLoading: false,
          pages: [...state.pages, action.payload.pageNumber],
          bySupplier: [...state.data, ...action.payload.result],
          end: end,
        };
      }
    },
    [actionTypes.GET_FREE_ARTICLES_BY_SUPPLIER_FAILED]: (state, action) => {
      return { ...state, isLoading: true };
    },

    [actionTypes.GET_SUPPLIERS_FROM_FREE_ARTICLE_FAILED]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [actionTypes.GET_SUPPLIERS_FROM_FREE_ARTICLE]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        suppliers: {
          ...state.suppliers,
          isLoading: true,
          init: true,
        },
      };
    },
    [actionTypes.GET_SUPPLIERS_FROM_FREE_ARTICLE_COMPLETED]: (
      state,
      action
    ) => {
      return {
        ...state,
        isLoading: false,
        suppliers: {
          ...state.suppliers,
          isLoading: false,
          loaded: true,
          data: action.payload.result,
        },
      };
    },

    [actionTypes.GET_FREE_ARTICLES_SUPPLIER_FAILED]: (state, action) => {
      return { ...state, isLoading: true };
    },
    [actionTypes.GET_FREE_ARTICLES_SUPPLIER]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        // suppliers: {
        //   ...state.suppliers,
        //   isLoading: true,
        //   init: true,
        // },
      };
    },
    [actionTypes.GET_FREE_ARTICLES_SUPPLIER_COMPLETED]: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  }
);

export default combineReducers({
  categoriesLot: categoriesLotReducer,
  lots: lotsReducer,
  departments: departmentsReducer,
  suppliers: suppliersReducer,
  customers: customersReducer,
  documents: documentsReducer,
  pages: pagesReducer,
  categories: categoriesReducer,
  freeArticles: freeArticlesReducer,
  freeArticlesBySupplier: freeArticlesBySupplierReducer,
});
