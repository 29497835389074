import * as types from "./types";

export const getResponseError = (error) => ({
  type: types.GET_RESPONSE_ERROR,
  payload: {
    error,
  },
});

export const clearError = () => ({
  type: types.CLEAR_ERROR,
});

export const getResponseSuccess = (message) => ({
  type: types.GET_RESPONSE_SUCCESS,
  payload: {
    message,
  },
});

export const clearSuccess = () => ({
  type: types.CLEAR_SUCCESS,
});
