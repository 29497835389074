import React from "react";
import { useDispatch } from "react-redux";
import Step4 from "./Step4"
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";
import {SUPPORTED_FORMATS_DOCUMENTS, SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, FILE_SIZE }from "constants/constants";

// Coordonnées détaillées du candidat ou du groupement
const Step4Container = (props) => {

  const {
    formRef,
    initialValues,
    contractors
  } = props

  const dispatch = useDispatch();

  //eslint-disable-next-line
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    contactDetailsBuyers: Yup.array().of(
      Yup.object().shape({
        department: Yup.string().nullable().required('Ce champ est requis'),
        company: Yup.string().nullable().required('Ce champ est requis'),
        lastname: Yup.string().nullable().required('Ce champ est requis'),
        function: Yup.string().nullable().required('Ce champ est requis'),
        phone: Yup.string().nullable().matches(phoneRegExp, 'Le numéro de téléphone est invalide').required('Ce champ est requis'),
        email: Yup.string().nullable().email('Cet email est invalide.').required('Ce champ est requis'),
        emailDuplicate: Yup.string().nullable().email('Cet email est invalide.').required('Ce champ est requis')
      })),
    bankDetails: Yup.object().shape({
      organization: Yup.string().nullable().required('Ce champ est requis'),
      iban: Yup.string().nullable().required('Ce champ est requis'),
      bic: Yup.string().nullable().required('Ce champ est requis'),
      rib: Yup.string().nullable(),
      ribUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) ).when('rib', {
          is: null,
          then: Yup.mixed().nullable().required('Ce champ est requis'),
      }),
    }),
    contactDetailsCentralis: Yup.object().shape({
      organization: Yup.string().nullable().required('Ce champ est requis'),
      lastname: Yup.string().nullable().required('Ce champ est requis'),
      function: Yup.string().nullable().required('Ce champ est requis'),
      phone: Yup.string().nullable().matches(phoneRegExp, 'Le numéro de téléphone est invalide').required('Ce champ est requis'),
      email: Yup.string().nullable().email('Cet email est invalide.').required('Ce champ est requis')
    })
  })

  return (
    <>
    <Formik
       initialValues={initialValues?.submittedInputs?.step4 ? initialValues.submittedInputs.step4 : initialValues.step4}
       validationSchema={validationSchema}
       onSubmit={values => {
         // 05/23 - Stock les valeurs des inputs en cas de pb (unicité des emails)
         dispatch(
           formSupplierOperations.saveSubmittedInputs({ step4: values })
         );
         const submitReponse = dispatch(
           formSupplierOperations.setStep4(values, initialValues.id)
         );
         submitReponse.then((res) => {
           dispatch(formSupplierOperations.saveSubmittedInputs({}));
         });
       }}
     >
       {({ errors, touched, values, setFieldValue }) => (
         <>
          <Step4 formRef={formRef} values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} initialValues={initialValues} contractors={contractors}/>
         </>
       )}
     </Formik>
     </>
  );

};

Step4Container.propTypes = {
};

Step4Container.defaultProps = {

};

export default Step4Container;
