import * as types from './types'

export const setStepInprogress = (values) => ( {
    type: types.SET_STEP_IN_PROGRESS,
    payload: {
      values
    }
} );

export const setStepIsLoading = (values) => ( {
    type: types.SET_STEP_IS_LOADING,
    payload: {
      values
    }
} );

/**
 * Gère les erreurs qui sont "fatales" au process
 */
export const setCriticalError = (values) => ( {
    type: types.SET_CRITICAL_ERROR,
    payload: {
      values
    }
} );

export const resetCandidate = (values) => ( {
    type: types.RESET_CANDIDATE,
    payload: {
      values
    }
} );

export const getCandidate = (candidateId) => {
  return {
    type: types.GET_CANDIDATE,
    meta: {
      async: true,
      path: `candidates/${candidateId}`,
      method: "GET"
    }
  }
}

export const initCandidateByMail = (email, phase) => {
  return {
    type: types.INIT_CANDIDATE,
    meta: {
      async: true,
      path: `candidates`,
      method: "POST",
      body: {email:email, phase:phase, edition:25}
    }
  }
}

export const updateCandidate = (values, candidateId) => {
  if (candidateId) {
    return {
      type: types.UPDATE_CANDIDATE,
      meta: {
        async: true,
        path: `candidates/${candidateId}`,
        method: "PUT",
        body: values
      }
    }
  }
  return {
    type: types.UPDATE_CANDIDATE,
    meta: {
      async: true,
      path: `candidates`,
      method: "POST",
      body: values
    }
  }
}

export const getCandidates = (filters) => {
  return {
    type: types.GET_CANDIDATES,
    meta: {
      async: true,
      path: `candidates`,
      method: "GET",
      params:filters
    }
  }
}

export const generatePDFCandidate = (type, candidateId, lots) => {
  return {
    type: types.GENERATE_PDF,
    meta: {
      async: true,
      path: `candidates/${candidateId}/${type}pdf`,
      method: "GET"
    },
    payload: {
      type:type
    }
  }
}

export const getCandidatePrices = (params) => {
  return {
    type: types.GET_CANDIDATE_PRICES,
    meta: {
      async: true,
      path: `candidateprices`,
      method: "GET",
      params: params
    },
    payload: {
      params:params
    }
  }
}

export const updateCandidatePrices = (values) => {
  return {
    type: types.UPDATE_CANDIDATE_PRICES,
    meta: {
      async: true,
      path: `candidateprices`,
      method: "PUT",
      body: values
    }
  }
}

export const checkCandidatePrices = (values) => {
  return {
    type: types.CHECK_CANDIDATE_PRICES,
    meta: {
      async: true,
      path: `candidateprices/check`,
      method: "POST",
      body: values
    }
  }
}

export const setFilterCandidatePrices = (values) => ( {
    type: types.FILTER_CANDIDATE_PRICES,
    payload: values
} );

export const resetCandidatePrices = () => ( {
    type: types.RESET_CANDIDATE_PRICES
} );

export const saveSubmittedInputs = (values) => ( {
    type: types.SAVE_SUBMITTED_INPUTS,
    payload: values
} );
