import * as types from "./types";
import { createReducer } from "../../utils";
import { combineReducers } from "redux";

/* State shape
{
    error: [ object ]
    success: [ text ],
    lastError: {},
    lastSuccess: {}
}
*/

const errorReducer = createReducer(null)({
  [types.GET_RESPONSE_ERROR]: (state, action) => {
    const { response } = action.payload.error;
    return { ...state, response, lastError: action.payload.error};
  },
  [types.CLEAR_ERROR]: (state, action) => {
    return null;
  },
});

const successReducer = createReducer(null)({
  [types.GET_RESPONSE_SUCCESS]: (state, action) => {
    const { message } = action.payload;
    return { ...state, message: message, lastSuccess: action.payload};
  },
  [types.CLEAR_SUCCESS]: (state, action) => {
    return null;
  },
});

export default combineReducers({
  error: errorReducer,
  success: successReducer,
});
