import React from "react";
import { useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import ModalForm from "../Modals/ModalForm";
import { operations } from "state/ducks/supercustomer";

const DataTable = (props) => {
  const { admin, items, buttonLabel } = props;

  const dispatch = useDispatch();

  const deleteItem = (itemId) => {
    if (
      window.confirm(
        "Voulez-vous supprimer ce compte ? Il sera supprimer de la base de données Centralis, tous ses paniers en cours vous seront attribués ?"
      )
    ) {
      dispatch(operations.removeCustomerAsSuper(itemId)).then((response) => {
        // Recharge la page pour actualiser les paniers en cours que le super doit récupérer après une suppression
        window.location.reload();
      });
    }
  };

  const itemsRender = items.map((item) => {
    return (
      <tr key={item.id}>
        <th scope="row">
          {item.lastname} {item.firstname}
        </th>
        <td>{item.email}</td>
        <td>{item.active ? "Activé" : "Désactivé"}</td>
        <td>
          <ModalForm item={item} admin={admin} buttonLabel={buttonLabel} />
        </td>
        <td>
          <Link to={{ pathname: `/compte/comptes/${item.id}/paniers` }}>
            <Button
              className="btn-green float-right"
              style={{ fontSize: "75%" }}
            >
              Panier en cours
            </Button>
          </Link>
        </td>
        <td>
          <Button
            color="danger"
            onClick={() => deleteItem(item.id)}
            style={{ fontSize: "75%" }}
          >
            Supprimer
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Email</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>{itemsRender}</tbody>
    </Table>
  );
};

export default DataTable;
