import React, {useState} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {Link} from "react-router-dom";
import {Container, FormGroup, Row, Col, Input, Label} from "reactstrap";
import LoadingSvg from "assets/img/icon-preloader-connect.svg";
import {useFormik} from "formik";
import * as Yup from "yup";
import {sessionOperations} from "state/ducks/session";
import {modalsOperations} from "state/ducks/modal";
import makeAnimated from "react-select/animated";
import Select from "react-select";


const RegisterSupplier = (props) => {
    const {departments} = props;
    const categories = useSelector(
        (state) => state.store.categories,
        shallowEqual
    );

    const [categoryChoosen, setCategoryChoosen] = useState(null);
    let categoryOptions = [];
    if (categories.data) {
        for (var i = 0; i < categories.data.length; i++) {
            categoryOptions[i] = {
                value: `/api/categories/${categories.data[i].id}`,
                label: `${categories.data[i].name}`,
            };
        }
    }
    const categoryOnChange = (value) => {
        setCategoryChoosen(value);
    };
    const departmentsDefault = useSelector(
        (state) => state.store.departments,
        shallowEqual
    );

    const [departementsChoosen, setDepartementsChoosen] = useState([]);

    const animatedComponents = makeAnimated();

    let departmentOptions = [];
    if (departmentsDefault.data) {
        for (var i = 0; i < departmentsDefault.data.length; i++) {
            departmentOptions[i] = {
                value: `/api/departments/${departmentsDefault.data[i].id}`,
                label: `${departmentsDefault.data[i].name} - ${departmentsDefault.data[i].code}`,
            };
        }
    }
    const departmentsOnChange = (values) => {
        let dpts = [];
        for (var i = 0; i < values.length; i++) {
            dpts.push(values[i]);
        }
        setDepartementsChoosen(dpts);
    };
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            firstname: "",
            lastname: "",
            phone: "",
            registerCgu: false,
            size: "",
            address: {
                street: "",
                postal: "",
                city: "",
                department: "",
            },
            departementsChoosen: [],
            categoryChoosen: null,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Merci de renseigner votre mail")
                .required("Ce champ est requis"),
            name: Yup.string().required("Ce champ est requis"),
            firstname: Yup.string().required("Ce champ est requis"),
            lastname: Yup.string().required("Ce champ est requis"),
            phone: Yup.string().required("Ce champ est requis"),
            registerCgu: Yup.bool().oneOf([true], "Vous devez accepter nos CGU"),
            address: Yup.object({
                city: Yup.string().required("Ce champ est requis"),
                postal: Yup.string().required("Ce champ est requis"),
                department: Yup.string().required("Ce champ est requis"),
                street: Yup.string().required("Ce champ est requis"),
            }),
        }),
        onSubmit: (values, {setFieldError}) => {
            // Vérifier si les champs ont au moins une valeur
            if (!departementsChoosen.length) {
                setFieldError('departementsChoosen', 'Veuillez sélectionner au moins un département');
                return;
            }
            if (!categoryChoosen) {
                setFieldError('categoryChoosen', 'Veuillez sélectionner une catégorie');
                return;
            }
            let departmentsURI = [];
            for (var i = 0; i < departementsChoosen.length; i++) {
                departmentsURI[i] = departementsChoosen[i].value;
            }
            let categoryURI = null;
            if (categoryChoosen?.value) {
                categoryURI = categoryChoosen.value;
            }
            // Laisse la modle ouverte en cas d'erreur
            dispatch(sessionOperations.registerSupplier({
                ...values,
                departments: departmentsURI,
                category: categoryURI,
            })).then(
                (result) => {
                    dispatch(
                        modalsOperations.getModalStatus(
                            "message",
                            true,
                            null,
                            "Merci pour votre inscription. Vous allez recevoir par mail votre identifiant et votre mot de passe dès que nous aurons vérifié votre identité."
                        )
                    );
                },
                (error) => {
                }
            );
        },
    });

    return (
        <Container>
            <Row style={{paddingTop: "5px"}}>
                <Col md="12">
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col md="12">
                                <h4>Comment s'appelle votre organisme ?</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Input
                                        name="name"
                                        id="name"
                                        placeholder="Nom de l'organisme*"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="input-error">{formik.errors.name}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col md="12">
                                <h4>Quelle est l'adresse du siège ?</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        name="address.street"
                                        id="street"
                                        placeholder="N et Rue"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.address.street}
                                    />
                                    {formik.touched.address &&
                                    formik.touched.address.street &&
                                    formik.errors.address &&
                                    formik.errors.address.street ? (
                                        <div className="input-error">
                                            {formik.errors.address.street}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        type="select"
                                        name="address.department"
                                        id="department"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.department}
                                    >
                                        <option defaultValue>Choisir un département</option>
                                        {departments.data.map((department, index) => (
                                            <option value={department.code} key={index}>
                                                {department.code} - {department.name}
                                            </option>
                                        ))}
                                    </Input>
                                    {formik.touched.address &&
                                    formik.touched.address.department &&
                                    formik.errors.address &&
                                    formik.errors.address.department ? (
                                        <div className="input-error">
                                            {formik.errors.address.department}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        name="address.postal"
                                        id="postal"
                                        placeholder="Code postal*"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.address.postal}
                                    />
                                    {formik.touched.address &&
                                    formik.touched.address.postal &&
                                    formik.errors.address &&
                                    formik.errors.address.postal ? (
                                        <div className="input-error">
                                            {formik.errors.address.postal}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        name="address.city"
                                        id="city"
                                        placeholder="Ville*"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.address.city}
                                    />
                                    {formik.touched.address &&
                                    formik.touched.address.city &&
                                    formik.errors.address &&
                                    formik.errors.address.city ? (
                                        <div className="input-error">
                                            {formik.errors.address.city}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col md="12">
                                <h4>Qui sera l'administrateur du compte Prestataire ?</h4>
                                <p>
                                    <i>
                                        Une fois le compte créé, vous pourrez ajouter des utilisateurs mais seul
                                        l'administrateur pourra approuver un panier et le commander.
                                    </i>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        name="lastname"
                                        id="lastname"
                                        placeholder="Nom*"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lastname}
                                    />
                                    {formik.touched.lastname && formik.errors.lastname ? (
                                        <div className="input-error">{formik.errors.lastname}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        name="firstname"
                                        id="firstname"
                                        placeholder="Prénom*"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.firstname}
                                    />
                                    {formik.touched.firstname && formik.errors.firstname ? (
                                        <div className="input-error">{formik.errors.firstname}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        name="email"
                                        id="email"
                                        placeholder="Email*"
                                        type="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="input-error">{formik.errors.email}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        name="phone"
                                        id="phone"
                                        placeholder="Téléphone*"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div className="input-error">{formik.errors.phone}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                {departments.isLoading ? (
                                    <Label for="departements">
                                        Chargement des départements...
                                    </Label>
                                ) : (
                                    <>
                                    <FormGroup>
                                        <Label for="departementsDefault">
                                            Départements par défaut pour le catalogue libre*
                                        </Label>
                                        <Select
                                            id="departementsDefault"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            defaultValue={departementsChoosen}
                                            value={departementsChoosen}
                                            isMulti
                                            options={departmentOptions}
                                            onChange={departmentsOnChange}
                                        />
                                    </FormGroup>
                                        {formik.touched.departementsChoosen && formik.errors.departementsChoosen ? (
                                            <div className="input-error">{formik.errors.departementsChoosen}</div>
                                        ) : null}
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                {categories.isLoading ? (
                                    <Label for="categoryDefault">
                                        Chargement des catégories...
                                    </Label>
                                ) : (
                                    <>
                                    <FormGroup>
                                        <Label for="categoryDefault">
                                            Catégorie par défaut pour le catalogue libre*
                                        </Label>
                                        <Select
                                            id="categoryDefault"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            defaultValue={categoryChoosen}
                                            value={categoryChoosen}
                                            options={categoryOptions}
                                            onChange={categoryOnChange}
                                        />

                                    </FormGroup>

                                        {formik.touched.categoryChoosen && formik.errors.categoryChoosen ? (
                                            <div className="input-error">{formik.errors.categoryChoosen}</div>
                                        ) : null}
                                    </>
                                )}
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col md="1">
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            id="registerCgu"
                                            type="checkbox"
                                            name="registerCgu"
                                            checked={formik.values.registerCgu}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.registerCgu}
                                        />{" "}
                                        *
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col md="11">
                                {" "}
                                J'ai lu et j'accepte les{" "}
                                <u>
                                    <Link to="/conditions-generales"> Conditions générales</Link>
                                </u>
                                {formik.touched.registerCgu && formik.errors.registerCgu ? (
                                    <div className="input-error">{formik.errors.registerCgu}</div>
                                ) : null}
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col md="12">
                                <p
                                    style={{
                                        textAlign: "right",
                                        position: "absolute",
                                        bottom: "0",
                                        right: "40px",
                                        fontSize: ".8em",
                                    }}
                                >
                                    <em>*Champ requis</em>
                                </p>
                            </Col>
                            <Col md="12">
                                Si vous ne recevez pas votre identifiant et votre mot de passe dans les 48h, merci de
                                nous contacter
                            </Col>
                            <Col md="12">
                                <button
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                    id="btn-connect-modal"
                                    type="submit"
                                    className="btn-green"
                                >
                                    <div
                                        style={{
                                            lineHeight: "1",
                                            width: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        Inscription (gratuite et sans engagements)
                                    </div>
                                    <div
                                        style={
                                            1 === 0
                                                ? {marginLeft: "0", opacity: "1"}
                                                : {marginLeft: "0", opacity: "0"}
                                        }
                                        className="preloader-connect-user"
                                    >
                                        <img src={LoadingSvg} alt="Loader"/>
                                    </div>
                                </button>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        </Container>
    );
};

export default RegisterSupplier;
