import axios from "axios";
import param from "jquery-param";

export default (url, method, body, tokenAuth) => {
  function requestHeaders() {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (window.localStorage.getItem("token")) {
      const token = window.localStorage.getItem("token");
      headers.Authorization = `Bearer ${token}`;
    }

    if (tokenAuth) {
      headers.Authorization = `Bearer ${tokenAuth}`;
    }

    return headers;
  }

  // Les données en obj FormData sont utilisées pour l'upload de fichiers
  let data = JSON.stringify(body);
  if (body instanceof FormData) {
    data = body;
  }

  return axios({
    method: method,
    url: method === "GET" && body !== undefined ? `${url}&${param(body)}` : url,
    headers: requestHeaders(),
    data: method !== "GET" ? data : null
  });

  // Commenté, car un warning est levée mentionnant que ce code n'est jamais atteignable
  //return axios.get(url).then( res => parseStatus( res.status, res.json() ) );
};
// Commenté, car un warning est levée mentionnant que ce code n'est jamais atteignable
// function parseStatus( status, res ) {
//     return new Promise( ( resolve, reject ) => {
//         if ( status >= 200 && status < 300 ) {
//             res.then( response => resolve( response ) );
//         } else {
//             res.then( response => reject( { status, response } ) );
//         }
//     } );
// }
