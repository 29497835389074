import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import Account from "../../Account";
import OrderComponent from "./OrderComponent";
import BlocInfos from "views/universal/BlocInfos/BlocInfos";
import IconPanier from "assets/img/icon-panier.svg";
import "moment/locale/fr";
import { operations as supersupplierOperations } from "state/ducks/supersupplier";

const Orders = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const supplierId = parseInt(props.match.params.supplierId);

  const [orders, setOrders] = useState(null);

  const session = useSelector((state) => state.session, shallowEqual);

  useEffect(() => {
    if (
      session.isLoading === false &&
      session.init === true &&
      orders === null
    ) {
      dispatch(supersupplierOperations.getSupplierOrders(supplierId)).then(
        (response) => {
          setOrders(response.data);
        },
        (error) => {
          history.push("../");
        }
      );
    }
  }, [dispatch, session, orders, history, supplierId]);

  const render = () => {
    if (
      orders === null ||
      !orders.orderLotSuppliers ||
      !orders.pendingFreeCarts
    ) {
      return <BlocInfos title="Chargement..." icon={IconPanier} />;
    } else if (
      orders.orderLotSuppliers.length < 1 &&
      orders.pendingFreeCarts.length < 1
    ) {
      return <BlocInfos title="Aucune commande" icon={IconPanier} />;
    } else {
      const renderCarts = orders.orderLotSuppliers.map(
        (orderLotSupplier, key) => {
          return (
            <OrderComponent key={key} orderLotSupplier={orderLotSupplier} />
          );
        }
      );
      const renderFreeCarts = orders.pendingFreeCarts.map(
        (pendingFreeCart, key) => {
          return (
            <OrderComponent key={key} orderLotSupplier={pendingFreeCart} />
          );
        }
      );
      return [...renderCarts, ...renderFreeCarts];
    }
  };

  return <Account>{render()}</Account>;
};

Orders.propTypes = {};

Orders.defaultProps = {};

export default Orders;
