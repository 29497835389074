import React from "react";
import { Field, FieldArray, Form, ErrorMessage } from 'formik';
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError"
import { Row, Col } from 'reactstrap';
import SelectWithData from "views/pages/FormSupplier/Common/Inputs/SelectWithData"
import { INITIAL_FORM_SUPPLIER } from "constants/constants"
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError"
import swal from 'sweetalert';

const Step8 = (props) => {

  const {
    formRef,
    values,
    contractors
  } = props

  const removeContractorConfirm = (arrayHelpers, index) => {
    swal({
      title: "Etes vous de sûr de vouloir faire cette suppression ? ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
          arrayHelpers.remove(index)
        }
    });
  }

  return (
    <Form className="mb-4" id="step8" ref={formRef}>
      <ScrollToError />
      <h4>8.1 Sous-traitance :</h4>
      <p className="mb-0 mt-4">Le candidat unique ou le groupement :</p>
      <label className="mr-4">
         <Field type="radio" name="outSourcing" value="no" /> N’envisage pas de sous-traiter l’exécution de certaines prestations.
      </label>
      <label className="mt-1">
         <Field type="radio" name="outSourcing" value="yes" />  Envisage de sous-traiter l'exécution de certaines prestations et fournira le DC4 lors de l’exécution du marché à chaque acheteur commanditaire pour préciser les modalités.
      </label>
      <ErrorMessage name="outSourcing" component="div" className="error-red"/>
      <h4>8.2 Facultatif / Autre(s) opérateur(s) :</h4>
      <p>Rubrique à renseigner dans l’hypothèse où le candidat ou l’un des membres du groupement s’appuie sur la ou les capacités d’un autre opérateur économique, quelle que soit la nature juridique des liens qui l’unissent à cet opérateur, en application du II de l’article R. 2142-3 du code de la commande publique auquel l’article R. 2342-2 renvoie.</p>
      <FieldArray
        name="otherOperators"
        render={arrayHelpers => {
          const otherOperators = values.otherOperators;
          return (
            <div>
              {otherOperators && otherOperators.map((otherOperator, index) => (
                <>
                  <div className="card mb-4" key={index}>
                    <Row>
                      <Col xs="12" sm="12">
                        <label>Entreprise</label>
                        <SelectWithData name={`otherOperators.${index}.organization`} items={contractors} />
                      </Col>
                      <Col xs="12" sm="6">
                        <label>Nom commercial de l’opérateur sur les capacités duquel le candidat s’appuie</label>
                        <FieldWithError name={`otherOperators.${index}.name`} placeholder={""} />
                      </Col>
                      <Col xs="12" sm="6">
                        <label>SIRET ou N° d’identification européen ou international ou propre au pays d’origine du candidat issu d’un répertoire figurant dans la liste des ICD.</label>
                        <FieldWithError name={`otherOperators.${index}.siret`} placeholder={""}/>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <button
                        className="btn-red mb-2"
                        type="button"
                        onClick={() => removeContractorConfirm(arrayHelpers, index)}
                      >
                        Supprimer cette ligne
                      </button>
                    </div>
                  </div>
                </>
              ))}

              <>
                <button type="button" onClick={() => arrayHelpers.push(INITIAL_FORM_SUPPLIER.step8.otherOperators[0])} className="btn-grey">
                  Ajouter une ligne
                </button>
              </>

            </div>
          );
        }}
      />

    </Form>

  );

};

Step8.propTypes = {
};

Step8.defaultProps = {

};

export default Step8;
