import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import Banner from "views/universal/Banner/Banner";
import Filters from "./Filters/Filters";
import Lots from "./Lots/Lots";
import PrestaManquanteBloc from "./PrestaManquanteBloc";
import Rgpd from "views/universal/Rgpd/Rgpd";
import queryString from "query-string";
import { storeOperations } from "state/ducks/store";
import { sessionSelectors } from "state/ducks/session";

const Catalog = (props) => {
  const dispatch = useDispatch();

  const paramUrl = queryString.parse(props.location.search);

  const lots = useSelector((state) => state.store.lots, shallowEqual);

  const session = useSelector((state) => state.session, shallowEqual);

  // Recupère le selector du panier pour les articles
  const cartSelector = useSelector((state) => {
    return sessionSelectors.activeCartSelector(state);
  });

  let codeLot = paramUrl.lot;
  let lot;

  if (codeLot === undefined) {
    codeLot = "all";
  } else {
    lot = lots.data.products.find((value) => {
      return value.code === codeLot;
    });
  }

  useEffect(() => {
    if (lots.filters.category !== codeLot) {
      dispatch(storeOperations.filtersLots(codeLot, ""));
    }
  }, [dispatch, lots.filters.category, codeLot]);

  const descriptionBanner =
    "Dans ce catalogue, l’achat en ligne est immédiat et se fait en dispense de procédure pour toutes les catégories de métiers. « Les acheteurs qui recourent à une centrale d’achat pour la réalisation de travaux ou l’acquisition de services sont considérés comme ayant respecté leurs obligations de publicité et de mise en concurrence ». Article L2113-4 du Code de la commande publique.";

  return (
    <Layout>
      {lots.isLoading | lots.session ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <>
          {lot !== "all" && lot !== undefined ? (
            <Banner
              titleBanner={lot.name}
              image={encodeURI(lot.image.contentUrl)}
            />
          ) : (
            <Banner
              titleBanner="Catalogue de prestations issu des procédures formalisées"
              desc={descriptionBanner}
            />
          )}
          <Filters filters={lots.filters} lots={lots.data.products} />
          <Lots
            departmentCode={lots.data.departmentCode}
            lots={lots.data.products}
            filters={lots.filters}
            isAuthenticated={session.isAuthenticated}
            cartSelector={cartSelector}
            user={session?.user}
          />
        </>
      )}
      {session.isAuthenticated && (
        <>
          {session.user.roles.includes("ROLE_CUSTOMER") || (session.user.roles.includes("ROLE_SUPER_CUSTOMER")) ? (
            <PrestaManquanteBloc />
          ) : <>  </>
          }
        </>
      )}

      <Rgpd />
    </Layout>
  );
};

Catalog.propTypes = {};

Catalog.defaultProps = {};

export default Catalog;
