import React from "react";
import { Row, Col } from "reactstrap";

const PrestaManquanteBloc = (props) => {
  return (
    <section className="p-section">
      <Row className="bloc-about">
        <Col
          sm={12}
          className="align-self-center content-bloc content-bloc-white"
          style={{ color: "white" }}
        >
          <div>
            <p style={{ textAlign: "center" }}>
              <a
                className="hover-grey"
                href="mailto:contact@centralis.site?subject=Prestation manquante"
              >
                <button
                  variant="contained"
                  className="btn-green btn-dynamic-width"
                >
                   Mon besoin est différent des articles du catalogue. Je lance un marché subséquent 
                </button>
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};


export default PrestaManquanteBloc;
