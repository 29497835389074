import React from 'react';
import Select from 'react-select';

export default ({ onChange, options, value, className, multiple, disabled, placeholder }) => {

    const defaultValue = (options, value) => {
      let selectedValues = []
      value && value.forEach(val => {
        selectedValues = [...selectedValues, options.find(option => option.value === parseInt(val))]
      })
      return selectedValues;
    };

    return (
        <div className={className}>
            <Select
                value={defaultValue(options, value)}
                closeMenuOnSelect={false}
                isMulti
                placeholder={placeholder}
                onChange={value => {
                    onChange(value)
                }} options={options} />
        </div>

    )
}
