import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import Layout from "views/universal/Layout/Layout";
import Banner from "./Banner/Banner";
import Actions from "./Actions/Actions";
import AddressForm from "./AddressForm/AddressForm";
import Lots from "./Lots/Lots";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import BlocInfos from "views/universal/BlocInfos/BlocInfos";
import IconPanier from "assets/img/icon-panier.svg";
import { sessionOperations } from "state/ducks/session";

const Cart = (props) => {
  const dispatch = useDispatch();

  const lots = useSelector((state) => state.store.lots, shallowEqual);

  const session = useSelector((state) => state.session, shallowEqual);

  const cartId = parseInt(props.match.params.id);

  const [cart, setCart] = useState({});
  const [initialized, setInitialized] = useState(false);

  const activeCart = session.activeCart;
  const confirmedCarts = session.confirmedCarts;

  const isSupplier =
    session?.user?.roles && session.user.roles.includes("ROLE_SUPPLIER");

  const defaultDepartmentCode = lots.data.departmentCode;

  /**
   * Si le panier est consulté en tant que admin (super vs gestionnaire)
   */
  let seeAsSuper = false;

  useEffect(() => {
    if (Object.keys(cart).length === 0 && initialized === false) {
      if (activeCart !== null && activeCart.id === cartId) {
        setCart(activeCart);
        setInitialized(true);
      } else if (
        Object.keys(cart).length === 0 &&
        confirmedCarts &&
        confirmedCarts.length > 0
      ) {
        setCart(
          confirmedCarts.find(function (item) {
            return parseInt(item.id) === parseInt(cartId);
          })
        );
        setInitialized(true);
      } else {
        let isMounted = true;
        dispatch(sessionOperations.loadCart(cartId, "formalized")).then(
          (response) => {
            if (isMounted) {
              setCart(response.data);
              setInitialized(true);
            }
          },
          (error) => {
            setInitialized(true);
          }
        );
        return () => {
          isMounted = false;
        };
      }
    }
  }, [dispatch, cart, initialized, cartId, activeCart, confirmedCarts]);

  if (initialized === true) {
    if (isSupplier) {
      if (cart.status !== 5) {
        return <Redirect to="/compte/fournisseur/paniers" />;
      }
    }
    if (!isSupplier) {
      if (cart.status !== 1 && cart.status !== 10) {
        return <Redirect to="/compte/paniers" />;
      }
    }
    if (session.user.roles.includes("ROLE_CUSTOMER")) {
      if (session.user.roles.includes("ROLE_SUPER_CUSTOMER")) {
        if (
          session?.user?.accounts &&
          session.user.accounts.find(
            (account) => cart.customer.id === account.id
          ) !== undefined
        ) {
          seeAsSuper = true;
        }
      }
      if (seeAsSuper === false && cart.customer.id !== session.user.id) {
        return <Redirect to="/compte/paniers" />;
      }
    }
  }

  return (
    <Layout>
      {Object.keys(cart).length === 0 || lots.isLoading ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <div style={{ position: "relative" }}>

          <section className="p-section" style={{backgroundColor: '#8E8E8E'}}>
            <Container>
              {/*<Banner cart={cart} user={session.user} />*/}
              {/*<Actions cart={cart} seeAsSuper={seeAsSuper} />*/}
              <AddressForm cart={cart} seeAsSuper={seeAsSuper} user={session.user}/>
              <Row>
                <Col md="12">
                  <h4 style={{ marginBottom: "25px", color: "#ffffff"}}>Articles</h4>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  {cart.orderLots === null ? (
                    <BlocInfos
                      title="Vous n'avez aucun article présent dans votre panier"
                      icon={IconPanier}
                    />
                  ) : (
                    <Lots
                      lots={lots.data.products}
                      isAuthenticated={session.isAuthenticated}
                      cart={cart}
                      showDetail={
                        defaultDepartmentCode === cart.department.code
                      }
                      seeAsSuper={seeAsSuper}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
    </Layout>
  );
};

Cart.propTypes = {};

Cart.defaultProps = {};

export default Cart;
