import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Container, Row, Col, Button } from "reactstrap";
import Account from "./../Account";
import { modalsOperations } from "state/ducks/modal";
import Article from "./Article";

const Articles = (props) => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  const openModal = () => {
    dispatch(modalsOperations.getModalStatus("addFreeArticle", true, "big"));
  };
  return (
    <Account>
      <Container>
        <Row>
          <Col md={12}>
            <Button
              className="btn-green float-right"
              onClick={() => openModal()}
            >
              Ajouter un article libre
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {session.user.freeArticles &&
              session.user.freeArticles.map((freeArticle, key) => (
                <Article key={key} article={freeArticle} />
              ))}
          </Col>
        </Row>
      </Container>
    </Account>
  );
};

Articles.propTypes = {};

Articles.defaultProps = {};

export default Articles;
