import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { notificationsOperations } from "state/ducks/notifications";
import "./alerts.css";

/**
 * Erreur Axio
 * @source https://axios-http.com/docs/handling_errors
 */
const Error = (props) => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  const { message } = props;

  if (notifications.error || notifications.success) {
    setTimeout(function () {
      dispatch(notificationsOperations.clearError());
      dispatch(notificationsOperations.clearSuccess());
    }, 5000);
  }

  var theMessage = "Une erreur a eu lieu. Si elle persiste, merci de recharger la page.";
  if (notifications.error && notifications.error.response) {
    if (notifications.error.response.data && notifications.error.response.data.error && notifications.error.response.data.error.message) {
      theMessage = notifications.error.response.data.error.message
    }
    else if (notifications.error.response.data && notifications.error.response.data.error_description) {
      theMessage = notifications.error.response.data.error_description
    }
    else if (notifications.error.response.data && notifications.error.response.data.message) {
      theMessage = notifications.error.response.data.message
    }
    //10/22
    // Les contraintes sur les entités
    else if (notifications?.error?.response?.data?.violations && notifications.error.response.data.violations[0]?.message) {
      theMessage = notifications.error.response.data.violations[0].message
    }
    // 05/23 POur prendre les Exceptions qui ne sont pas formattées en mode dev sur le back
    else if (notifications?.error?.response?.data?.detail) {
      theMessage = notifications.error.response.data.detail
    }
    // Peut afficher des erreurs du type Not Found --> je préfère laisser le message d'erreur par défaut pour le moment
    // else if (notifications?.error?.response?.data?.detail) {
    //   theMessage = notifications.error.response.data.detail
    // }
    else if (message) {
      theMessage = message;
    }
  }

  if (theMessage === 'Invalid credentials.') {
    theMessage = "Identifiants invalides."
  }

  return (
    <div>
      {notifications.error && notifications.error.response && (
        <div className="senovea-global-alert-wrap" style={{display: 'block'}}>
          <div color="error" className="alert alert-success">
            <div>
            <strong>Erreur</strong></div>
            <div>{theMessage}</div>
            <div className="senovea-global-alert-close" onClick={() => dispatch(notificationsOperations.clearError())}>
              <div>x</div>
            </div>
          </div>
        </div>
      )}

      {notifications.success && notifications.success.message && (
        <div className="senovea-global-alert-wrap" style={{display: 'block'}}>
          <div color="error" className="alert alert-success">
            <div>
            <strong>Erreur</strong></div>
            <div>
              {notifications.success.message
                ? notifications.success.message
                : message}
            </div>
            <div className="senovea-global-alert-close" onClick={() => dispatch(notificationsOperations.clearSuccess())}>
              <div>x</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: "Nous rencontrons un problème, merci de recharger la page.",
};

export default Error;
