import {
  getSupplierAccounts,
  getSupplierOrders,
  addSupplierAccount,
  updateSupplierAccount,
  removeSupplierAccount,
} from "./actions";

const createSupplierAsSuper =
  (adminId, values) => async (dispatch, getState) => {
    let data = { ...values, admin: `api/suppliers/${adminId}` };
    return dispatch(addSupplierAccount(data));
  };

const updateSupplierAsSuper =
  (customerId, values) => async (dispatch, getState) => {
    return dispatch(updateSupplierAccount(customerId, values));
  };

const removeSupplierAsSuper = (customerId) => async (dispatch, getState) => {
  return dispatch(removeSupplierAccount(customerId));
};

export {
  getSupplierAccounts,
  createSupplierAsSuper,
  updateSupplierAsSuper,
  removeSupplierAsSuper,
  getSupplierOrders,
};
