import React from "react";
import { Row, Col, Table } from "reactstrap";
import PropTypes from "prop-types";
import { centimesToCurrency } from 'utils/Utils'

/**
 * Affiche les variations de prix d'un produit dans un tableau en fonction du rang et du palier
 */
const ArticleVariations = (props) => {

    const {
      prices,
      unit,
      quantityB,
      quantityC
    } = props

    return (
      <Row>
        <Col md="12">
          <Table>
            <thead>
              <tr>
                <th></th>
                {prices[0].map((palier, i) => {

                  let palierTxt;
                  if (i === 0 ) {
                    palierTxt = "1 à " + (quantityB - 1);
                  } else if (i === 1) {
                    palierTxt = quantityB + ' à ' + quantityC;
                  } else {
                    palierTxt = '> à ' + quantityC;
                  }

                  return (<th key={i}>{palierTxt}</th>)
                })}
              </tr>
            </thead>
            <tbody>
            {prices.map((palier, i) => {
              if (prices[i].length > 0 ) {
                return (
                  <tr key={i}>
                    <th key={i}>Prestataire Rang {i + 1}</th>
                    {prices[i].map((keyPrice, rang) => {

                        return (
                          <td key={rang}>
                          {
                            keyPrice >= 0 ? centimesToCurrency(keyPrice) + ' / ' + unit
                            :
                            null
                          }
                          </td>
                        );
                      }
                  )}
                </tr>
                )
              }
              else {
                return <tr key={i}><th key={i}>Prestataire Rang {i + 1}</th><td>-</td><td>-</td><td>-</td></tr>
              }
            })}
            </tbody>
          </Table>
        </Col>
      </Row>
    );

}

ArticleVariations.propTypes = {
  prices: PropTypes.array,
  unit: PropTypes.string
};

ArticleVariations.defaultProps = {
  prices: [],
  unit: ''
};

export default ArticleVariations;
