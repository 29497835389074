import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Account from "./../Account";
import Paper from "@material-ui/core/Paper";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  SUPPORTED_FORMATS_DOCUMENTS,
  SUPPORTED_FORMATS_DOCUMENTS_MESSAGE,
  FILE_SIZE,
} from "constants/constants";
import { sessionOperations } from "state/ducks/session";

const Downloads = (props) => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyInsuranceFileUpload: null,
      ribUpload: null,
      fiscalRegularityFileUpload: null,
    },
    validationSchema: Yup.object({
      fiscalRegularityFile: Yup.string().nullable(),
      fiscalRegularityFileUpload: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "Le fichier ne doit pas dépasser 8mo",
          (value) => value === null || value?.size <= FILE_SIZE
        )
        .test(
          "fileType",
          SUPPORTED_FORMATS_DOCUMENTS_MESSAGE,
          (value) =>
            value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)
        )
        .when("fiscalRegularityFile", {
          is: null,
          then: Yup.mixed().nullable(),
        }),
      rib: Yup.string().nullable(),
      ribUpload: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "Le fichier ne doit pas dépasser 8mo",
          (value) => value === null || value?.size <= FILE_SIZE
        )
        .test(
          "fileType",
          SUPPORTED_FORMATS_DOCUMENTS_MESSAGE,
          (value) =>
            value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)
        )
        .when("rib", {
          is: null,
          then: Yup.mixed().nullable(),
        }),
      companyInsuranceFile: Yup.string().nullable(),
      companyInsuranceFileUpload: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "Le fichier ne doit pas dépasser 8mo",
          (value) => value === null || value?.size <= FILE_SIZE
        )
        .test(
          "fileType",
          SUPPORTED_FORMATS_DOCUMENTS_MESSAGE,
          (value) =>
            value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)
        )
        .when("companyInsuranceFile", {
          is: null,
          then: Yup.mixed().nullable(),
        }),
    }),
    onSubmit: (values) => {
      dispatch(
        sessionOperations.updateSupplierFiles(session.user.id, values)
      ).then((response) => window.location.reload());
    },
  });

  return (
    <Account>
      <div style={{ padding: "0px" }}>
        <Paper elevation={1} style={{ marginBottom: "15px" }}>
          <div style={{ padding: "30px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md="12">
                  <h4>Mon contrat</h4>
                  {session.user?.finalPDFs &&
                    session.user.finalPDFs.map((finalPDF) => (
                      <>
                        {finalPDF?.contentUrl && (
                          <a
                            href={finalPDF.contentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fileLink"
                          >
                            Voir mon contrat signé
                          </a>
                        )}
                        <br />
                      </>
                    ))}
                </Col>
              </Row>
              {session.user.roles.includes("ROLE_SUPER_SUPPLIER") && (
                <>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md="12">
                      <FormGroup>
                        <Label for="ribUpload">
                          <h5>RIB</h5>
                        </Label>
                        <br />
                        {session.user?.rib?.contentUrl && (
                          <>
                            <a
                              href={session.user.rib.contentUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="fileLink"
                            >
                              Voir le fichier
                            </a>
                            <br />
                          </>
                        )}

                        {session.user.roles.includes("ROLE_SUPER_SUPPLIER") && (
                          <input
                            className="mr-sm-1 mx-sm-1 form-group my-1"
                            name={`ribUpload`}
                            type="file"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "ribUpload",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        )}

                        {formik?.touched?.companyInsuranceFileUpload &&
                        formik?.errors?.companyInsuranceFileUpload ? (
                          <div className="input-error">
                            {formik.errors.companyInsuranceFileUpload}
                          </div>
                        ) : null}
                      </FormGroup>

                      <FormGroup>
                        <Label for="fiscalRegularityFileUpload">
                          <h5>Attestation fiscale et sociale</h5>
                        </Label>
                        <br />
                        {session.user?.fiscalRegularityFile?.contentUrl && (
                          <>
                            <a
                              href={
                                session.user.fiscalRegularityFile.contentUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="fileLink"
                            >
                              Voir le fichier
                            </a>
                            <br />
                          </>
                        )}
                        {session.user.roles.includes("ROLE_SUPER_SUPPLIER") && (
                          <input
                            className="mr-sm-1 mx-sm-1 form-group my-1"
                            name={`fiscalRegularityFileUpload`}
                            type="file"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "fiscalRegularityFileUpload",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        )}

                        {formik?.touched?.fiscalRegularityFileUpload &&
                        formik?.errors?.fiscalRegularityFileUpload ? (
                          <div className="input-error">
                            {formik.errors.fiscalRegularityFileUpload}
                          </div>
                        ) : null}
                      </FormGroup>

                      <FormGroup>
                        <Label for="companyInsuranceFileUpload">
                          <h5>Le justificatif d'assurance</h5>
                        </Label>
                        <br />
                        {session.user?.companyInsuranceFile?.contentUrl && (
                          <>
                            <a
                              href={
                                session.user.companyInsuranceFile.contentUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="fileLink"
                            >
                              Voir le fichier
                            </a>
                            <br />
                          </>
                        )}
                        {session.user.roles.includes("ROLE_SUPER_SUPPLIER") && (
                          <input
                            className="mr-sm-1 mx-sm-1 form-group my-1"
                            name={`companyInsuranceFileUpload`}
                            type="file"
                            onChange={(event) => {
                              formik.setFieldValue(
                                "companyInsuranceFileUpload",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        )}

                        {formik?.touched?.companyInsuranceFileUpload &&
                        formik?.errors?.companyInsuranceFileUpload ? (
                          <div className="input-error">
                            {formik.errors.companyInsuranceFileUpload}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <button
                    style={{
                      marginTop: "30px",
                      marginLeft: "70%",
                      width: "30%",
                    }}
                    type="submit"
                    variant="contained"
                    className="btn-green"
                  >
                    Mettre à jour le profil
                  </button>
                </>
              )}
            </form>
          </div>
        </Paper>
      </div>
    </Account>
  );
};

Downloads.propTypes = {};

Downloads.defaultProps = {};

export default Downloads;
