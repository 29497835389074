import React from "react";
import { useDispatch } from "react-redux";
import Step12Phase2 from "./Step12Phase2"
import { formSupplierOperations } from "state/ducks/formSupplier";
import swal from 'sweetalert';

// Mémoire technique
const Step12Phase2Container = (props) => {

  const {
    initialValues,
  } = props

  const dispatch = useDispatch();

  const handleResetForm = () => {
    swal({
      title: "Etes-vous sûr de faire une nouvelle demande ?",
      text: "Vous perdrez la possibilité de télécharger votre candidature, pensez à bien l'enregistrer avant de valider.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        dispatch(formSupplierOperations.resetCandidate());
      }
    });
  }

  return (
    <Step12Phase2
    accepted={initialValues.accepted}
    pdf={initialValues.step12.finalPDF}
    handleResetForm={handleResetForm}/>
  );

};

Step12Phase2Container.propTypes = {
};

Step12Phase2Container.defaultProps = {

};

export default Step12Phase2Container;
