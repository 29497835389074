// import React, { useEffect } from "react";
// import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import Account from "./../Account";
// import Paper from "@material-ui/core/Paper";
// import { Container, Row, Col } from "reactstrap";
// import ModalForm from "./components/Modals/ModalForm";
// import DataTable from "./components/Tables/DataTable";
// import { operations as supercustomerOperations } from "state/ducks/supercustomer";
// import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";

/**
 * @source https://github.com/olinations/crud-starter-frontend
 */
const Accounts = (props) => {
  // const dispatch = useDispatch();

  // const session = useSelector((state) => state.session, shallowEqual);

  // const supercustomer = useSelector(
  //   (state) => state.supercustomer,
  //   shallowEqual
  // );

  // useEffect(() => {
  //   if (
  //     session.isLoading === false &&
  //     session.init === true &&
  //     supercustomer.isLoading === false &&
  //     supercustomer.init === false
  //   ) {
  //     dispatch(supercustomerOperations.getCustomerAccounts(session.user.id));
  //   }
  // }, [dispatch, session, supercustomer]);

  // return (
  //   <Account>
  //     {(session.isLoading || supercustomer.isLoading) && (
  //       <GlobalLoader message={"Chargement en cours ..."} />
  //     )}
  //     <div style={{ padding: "0px" }}>
  //       <Paper elevation={1} style={{ marginBottom: "15px" }}>
  //         <div style={{ padding: "30px" }}>
  //           <Container className="App">
  //             {supercustomer.customerAccounts.length > 0 && (
  //               <Row>
  //                 <Col>
  //                   <DataTable
  //                     items={supercustomer.customerAccounts}
  //                     admin={session.user}
  //                     buttonLabel="Editer"
  //                   />
  //                 </Col>
  //               </Row>
  //             )}
  //             <Row>
  //               <Col md={4}></Col>
  //               <Col md={4}>
  //                 <ModalForm
  //                   buttonLabel="Ajouter un compte"
  //                   admin={session.user}
  //                 />
  //               </Col>
  //               <Col md={4}></Col>
  //             </Row>
  //           </Container>
  //         </div>
  //       </Paper>
  //     </div>
  //   </Account>
  // );
};

Accounts.propTypes = {};

Accounts.defaultProps = {};

export default Accounts;
