import { createSelector } from "reselect";

/**
 * Récupere le listing des régions
 */
const departmentsState = state => state.store.departments.data;
const regionsSelector = createSelector(
  departmentsState,
  departmentsState => {

    let regionsDuplicate = [];
    let regions = [];
    let regionsSelect = []

    departmentsState.forEach((department, index) => {
      if (undefined === regionsDuplicate.find(el => department.region.name === el.name)) {
        regionsDuplicate = [...regionsDuplicate, {id: department.region.id, name: department.region.name, code: department.region.code}]
      }
    });

    regions = [...new Set(regionsDuplicate)];
    regions.sort((a, b) => a.name.localeCompare(b.name))

    // On transforme pour exploitation dans un select box
    regions.forEach((region, index) => {
      regionsSelect = [...regionsSelect, {id: region.id, name: region.name, code: region.code}]
    });
    return regionsSelect;
  }
);

/**
 * Groupe les articles libres par entreprise
 */
const freeArticlesState = state => state.store.freeArticles;
const freeArticlesSelector = createSelector(
  freeArticlesState,
  freeArticlesState => {
    let freeArticlesGroupBySuppliers = [];
    for (var i = 0; i < freeArticlesState.data.length; i++) {
      if (!freeArticlesGroupBySuppliers[freeArticlesState.data[i].supplier.id]) {
        freeArticlesGroupBySuppliers[freeArticlesState.data[i].supplier.id] = freeArticlesState.data[i].supplier;
        freeArticlesGroupBySuppliers[freeArticlesState.data[i].supplier.id].freeArticles = [];
      }
      freeArticlesGroupBySuppliers[freeArticlesState.data[i].supplier.id].freeArticles.push(freeArticlesState.data[i]);
    }

    return freeArticlesGroupBySuppliers;
  }
);

export {
  freeArticlesSelector,
  regionsSelector
};
