import { createSelector } from "reselect";

/**
 * Formate le panier avec son id, ses lots et ses articles
 */
const activeCartState = (state) => state.session.activeCart;
const activeCartSelector = createSelector(
  activeCartState,
  (activeCartState) => {
    let cart = {
      id: null,
      lots: [],
      articles: [],
      fromSupplier: null,
      status: null,
        department: null,
    };

    if (activeCartState && activeCartState.id) {
      cart.id = activeCartState.id;
      cart.fromSupplier = activeCartState.supplier ? true : false;
      cart.status = activeCartState.status;
      cart.department = activeCartState.department.code;
    }

    if (
      activeCartState &&
      activeCartState.orderLots &&
      activeCartState.orderLots.length > 0
    ) {
      let codeDepartment;
      if (activeCartState.department && activeCartState.department.code) {
        codeDepartment = activeCartState.department.code;
      }
      activeCartState.orderLots.forEach((orderLot) => {
        let codeLot, codeArticle, quantity, orderArticleId, orderLotId;

        // Lot info
        codeLot = orderLot.lotCode;
        orderLotId = orderLot.id;
        // Si les articles sont vides, n'ajoute pas le lot
        if (orderLot.orderArticles && orderLot.orderArticles.length > 0) {
          cart.lots.push({
            codeLot: codeLot,
            codeDepartment: codeDepartment,
            orderLotId: orderLotId,
          });
        }
        // Article info
        if (
          orderLot.orderArticles &&
          orderLot.orderArticles.length > 0 &&
          activeCartState.orderLots.length > 0
        ) {
          orderLot.orderArticles.forEach((orderArticle) => {
            codeArticle = orderArticle.articleCode;
            quantity = orderArticle.quantity;
            orderArticleId = orderArticle.id;
            cart.articles.push({
              codeLot: codeLot,
              codeArticle: codeArticle,
              codeDepartment: codeDepartment,
              quantity: quantity,
              orderArticleId: orderArticleId,
              orderLotId: orderLotId,
            });
          });
        }
      });
    }

    return cart;
  }
);

const activeFreeCartState = (state) => state.session.activeFreeCart;
const activeFreeCartSelector = createSelector(
  activeFreeCartState,
  (activeFreeCartState) => {
    let cart = {
      id: null,
      orderFreeArticles: [],
    };

    if (activeFreeCartState && activeFreeCartState.id) {
      cart = activeFreeCartState;
    }

    return cart;
  }
);

/**
 * Retourne le panier libre en fonction d'un id
 */
const getFreeCartByIdSelector = createSelector(
  [(state) => state.session, (state, cartId) => cartId],
  (sessionState, cartId) => {
    let cart = null;
    if (
      sessionState?.activeFreeCart &&
      sessionState.activeFreeCart.id === cartId
    ) {
      cart = sessionState.activeFreeCart;
    } else if (
      sessionState?.confirmedFreeCarts &&
      sessionState.confirmedFreeCarts.length > 0
    ) {
      cart = sessionState.confirmedFreeCarts.find(function (item) {
        return parseInt(item.id) === parseInt(cartId);
      });
    } else if (
      sessionState?.pendingFreeCarts &&
      sessionState.pendingFreeCarts.length > 0
    ) {
      cart = sessionState.pendingFreeCarts.find(function (item) {
        return parseInt(item.id) === parseInt(cartId);
      });
    }

    return cart;
  }
);

export { activeCartSelector, activeFreeCartSelector, getFreeCartByIdSelector };
