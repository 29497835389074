import React, { useState } from "react";

const Rgpd = (props) => {

  const [cookieMentions, setCookieMentions] = useState(true);
  if (cookieMentions === false) {
    localStorage.setItem("cookiesNotices", JSON.stringify(false))
  }
  return (
    <div id="cookie-notice" className={localStorage.getItem("cookiesNotices")  ? 'displayBlocOpacity' : null}>
        <p>Ce site utilise des cookies. En poursuivant la navigation, vous acceptez l'utilisation de cookies.</p>
        <button onClick={() => setCookieMentions(false)} className="btn-green">Fermer et continuer</button>
    </div>
  );

};

Rgpd.propTypes = {};

Rgpd.defaultProps = {};

export default Rgpd;
