import React from 'react'
import { useDispatch } from "react-redux";
import { FormGroup, Row, Col, Input } from 'reactstrap';
import LoadingSvg from 'assets/img/icon-preloader-connect.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { modalsOperations } from 'state/ducks/modal'
import { sessionOperations } from "state/ducks/session";

const Resend = (props) =>{

  const dispatch = useDispatch();

  const formik = useFormik({
   initialValues: {
     email: ''
   },
   validationSchema: Yup.object({
     email: Yup.string().email('Merci de renseigner votre mail').required('Ce champ est requis'),
   }),
   onSubmit: values => {
     // Laisse la modle ouverte en cas d'erreur
     dispatch(sessionOperations.resetPassword(values)).then(
       (result) => {
         dispatch(modalsOperations.getModalStatus('message', true, null, "Vous allez recevoir un mail avec vos nouveaux identifiants"))
       },
       (error) => {
         // dispatch(modalsOperations.getModalStatus('message', true, null, "Cet email n'est associé à aucun compte actif."))
       }
     )
   },
  });

    return(
        <Row>
            <Col md="12">
                <form onSubmit={formik.handleSubmit}>

                    <FormGroup>
                        <Input name="email" id="email" placeholder="Votre email" type="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="input-error">{formik.errors.email}</div>
                        ) : null}
                    </FormGroup>

                    <div>
                    <button style={{display:"flex",alignItems:"center",justifyContent:"space-between"}} id="btn-connect-modal" type="submit" className="btn-green">
                        <div style={{lineHeight:"1"}}>Me renvoyer mes identifiants</div>
                        <div style={ 1 === 2 ? {marginLeft:"0",opacity:"1"} : {marginLeft:"0",opacity:"0"} } className="preloader-connect-user"><img src={LoadingSvg} alt="loader"/></div>
                    </button>
                    </div>

                </form>
            </Col>
            <Col md="12">
                <p style={{textAlign:"center",marginBottom:"0px",marginTop:"1rem",color: "#DB7124", cursor:"pointer"}}
                onClick={ ()=> dispatch(modalsOperations.getModalStatus('login', true, null))}>Retourner à la connexion</p>
            </Col>
        </Row>
    )
}


export default Resend
