import React from "react";
import { Field, ErrorMessage } from 'formik';

const SelectWithData = (props) => {

  const {
    items,
    name,
    multiple,
    onChange,
    disabled
  } = props

  return (
    <div className="mr-sm-1 mx-sm-1 form-group my-1">
      {onChange ?
        <Field as="select" name={name} className="form-control" multiple={multiple} onChange={onChange && onChange} disabled={disabled}>
          {!disabled && <option disabled selected value="">Choisir une ou des option(s)</option>}
          {
            items && items.length > 0 && items.map(( item, index ) => {
              return(
                <option key={index} value={item.id} selected={item.selected && item.selected}>{item.name}</option>
              )
            })
          }
        </Field>
        :
        <Field as="select" name={name} className="form-control" multiple={multiple} disabled={disabled}>
          {!disabled && <option disabled selected value="">Choisir une ou des option(s)</option> }
          {
            items && items.length > 0 && items.map(( item, index ) => {
              return(
                <option key={index} value={item.id}>{item.name}</option>
              )
            })
          }
        </Field>
      }

      <ErrorMessage name={name} component="div" className="error-red"/>
    </div>
  );

};

SelectWithData.propTypes = {
};

SelectWithData.defaultProps = {

};

export default SelectWithData;
