import React, {useState, useEffect} from "react";
import {Container, Row, Col, FormGroup, Input} from "reactstrap";
import "./Filters.css";
import {useDispatch} from "react-redux";
import {storeOperations} from "state/ducks/store";

const Filters = (props) => {
    const dispatch = useDispatch();

    const {categoriesLot, departments, filters, cartType, categories} = props;
    const [searchCompany, setSearchCompany] = useState("");
    const [selectedDepartment, setSelectedDepartment] = useState("75");


    useEffect(() => {
        setSearchCompany(filters.company)
    }, [cartType, filters.company]);

    useEffect(() => {
        if (cartType === "free") {
            dispatch(
                storeOperations.filtersFreeSuppliers(
                    searchCompany,
                    selectedDepartment,
                    filters.rank,
                    filters.category,
                    cartType,
                    filters.edition,
                    filters.limit,
                    0
                )
            );
        } else {
            dispatch(
                storeOperations.filtersSuppliers(
                    searchCompany,
                    selectedDepartment,
                    filters.rank,
                    filters.category,
                    cartType,
                    filters.edition,
                    filters.limit,
                    0
                )
            );
        }

        dispatch(
            storeOperations.getSuppliers(
                searchCompany,
                selectedDepartment,
                filters.rank,
                filters.category,
                cartType,
                filters.edition,
                filters.limit,
                0
            )
        )
    }, [searchCompany, filters.department, filters.rank, filters.category, cartType, filters.edition, filters.limit]); // Add dependencies here


    return (
        <nav id="Filters">
            <Container>
                <Row>
                <Col lg={cartType === "formalized" ? "3" : "4"}>
                        <FormGroup className="mb-0">
                            <Input
                                type="select"
                                name="selectLot"
                                id="selectLot"
                                value={filters.category}
                                onChange={(e) => {
                                    if (cartType === "free") {
                                        dispatch(
                                            storeOperations.filtersFreeSuppliers(
                                                filters.company,
                                                filters.department,
                                                filters.rank,
                                                e.target.value
                                            )
                                        );
                                    } else {
                                        dispatch(
                                            storeOperations.filtersSuppliers(
                                                filters.company,
                                                filters.department,
                                                filters.rank,
                                                e.target.value
                                            )
                                        );
                                    }
                                }}
                            >
                                <option value="all">Catégorie</option>
                                {cartType === "formalized" &&
                                    categoriesLot.data.map((category) => {
                                        return (
                                            <option
                                                value={category.code}
                                                key={"filter-category-" + category.code}
                                            >
                                                {category.name}
                                            </option>
                                        );
                                    })}
                                {cartType === "free" &&
                                    categories.data.map((category) => {
                                        return (
                                            <option
                                                value={category.id}
                                                key={"filter-category-" + category.id}
                                            >
                                                {category.name}
                                            </option>
                                        );
                                    })}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <FormGroup className="mb-0">
                            <Input
                                type="select"
                                name="selectDepartment"
                                id="selectDepartment"
                                value={selectedDepartment}
                                onChange={(e) => {
                                    setSelectedDepartment(e.target.value);
                                    if (cartType === "free") {
                                        dispatch(
                                            storeOperations.filtersFreeSuppliers(
                                                filters.company,
                                                e.target.value,
                                                filters.rank,
                                                filters.category,
                                            )
                                        );
                                    } else {
                                        dispatch(
                                            storeOperations.filtersSuppliers(
                                                filters.company,
                                                e.target.value,
                                                filters.rank,
                                                filters.category,
                                            )
                                        );
                                    }
                                }}
                            >

                                {departments.data.map((department) => {
                                    return (
                                        <option
                                            value={department.code}
                                            key={"filter-department-" + department.code}
                                        >
                                            {department.code} - {department.name}
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                    </Col>
                    {cartType === "formalized" && (
                        <Col lg="3">
                            <FormGroup className="mb-0">
                                <Input
                                    type="select"
                                    name="selectRank"
                                    id="selectRank"
                                    value={filters.rank}
                                    onChange={(e) => {
                                        if (cartType === "free") {
                                            dispatch(
                                                storeOperations.filtersFreeSuppliers(
                                                    filters.company,
                                                    filters.department,
                                                    e.target.value,
                                                    filters.category
                                                )
                                            );
                                        } else {
                                            dispatch(
                                                storeOperations.filtersSuppliers(
                                                    filters.company,
                                                    filters.department,
                                                    e.target.value,
                                                    filters.category
                                                )
                                            );
                                        }
                                    }}
                                >
                                    <option value="all">Rang</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    )}
                    <Col lg="3">
                        <FormGroup className="mb-0">
                            <Input
                                type="text"
                                name="text"
                                id="searchCompany"
                                placeholder="Nom de l'entreprise"
                                value={searchCompany}
                                onChange={(e) => setSearchCompany(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    
                    
                    
                </Row>
            </Container>
        </nav>
    );
};

Filters.propTypes = {};

Filters.defaultProps = {};

export default Filters;
