import React, { useState } from "react";
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import "./Filters.css";
import { useDispatch } from "react-redux";
import { storeOperations } from "state/ducks/store";
import { useHistory } from "react-router-dom";

const Filters = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const { categories, departments, filters, departmentCart } = props;

  const [category, setCategory] = useState(
    filters.category === "all" && categories[0] !== undefined
      ? categories[0].id
      : filters.category
  );
  const [department, setDepartment] = useState(filters.department);
  const [searchPrestation, setSearchPrestation] = useState(filters.prestation);

  return (
    <nav id="Filters">
      <Container>
        <Row>
          <Col lg="4">
            <FormGroup className="mb-0">
              <Input
                type="select"
                name="selectCategory"
                id="selectCategory"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {categories.map((category) => {
                  return (
                    <option
                      value={category.id}
                      key={"filter-category-" + category.id}
                    >
                      {category.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
          {!departmentCart && (
            <Col lg="3">
              <FormGroup className="mb-0">
                <Input
                  type="select"
                  name="selectLot"
                  id="selectLot"
                  value={department}
                  onChange={(e) => {
                    setDepartment(e.target.value);
                  }}
                >
                  {departments.map((department) => {
                    return (
                      <option
                        value={department.id}
                        key={"filter-department-" + department.id}
                      >
                        {department.name} - ({department.code})
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          )}
          <Col lg={!departmentCart ? "3" : "6"}>
            <FormGroup className="mb-0">
              <Input
                type="text"
                name="text"
                id="prestation"
                placeholder="Saisir une prestation, ex : Diagnostic"
                value={searchPrestation}
                onChange={(e) => setSearchPrestation(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <button
              onClick={(e) => {
                history.push(
                  `/catalogue/libre?category=${category}&department=${department}`
                );
                dispatch(
                  storeOperations.getSuppliersFromFreeArticle(
                    category,
                    department,
                    searchPrestation
                  )
                );
              }}
              className="btn-green"
            >
              Chercher
            </button>
          </Col>
        </Row>
      </Container>
    </nav>
  );
};

Filters.propTypes = {};

Filters.defaultProps = {};

export default Filters;
