import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ModalModel from "views/universal/ModalModel/ModalModel";
import { storeOperations } from "state/ducks/store";
import { sessionOperations } from "state/ducks/session";
import Error from "views/universal/Errors/Api/Error";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";

const Layout = (props) => {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.modal, shallowEqual);

  const session = useSelector((state) => state.session, shallowEqual);

  const departments = useSelector(
    (state) => state.store.departments,
    shallowEqual
  );

  const categoriesLot = useSelector(
    (state) => state.store.categoriesLot,
    shallowEqual
  );

  const lots = useSelector((state) => state.store.lots, shallowEqual);

  const categories = useSelector(
    (state) => state.store.categories,
    shallowEqual
  );

  useEffect(() => {
    if (!session.init) {
      dispatch(sessionOperations.initializeSession());
    } else {
      if (!departments.init) {
        dispatch(storeOperations.getDepartments());
      }
      if (!categoriesLot.init || !lots.init) {
        dispatch(storeOperations.getCategoriesLot());
      }
      if (!lots.init) {
        if (
          session?.user?.roles &&
          session.user.roles.includes("ROLE_SUPPLIER") &&
          session?.activeCart?.department?.code
        ) {
          dispatch(storeOperations.getLots(session.activeCart.department.code));
        } else if (
          session.user &&
          session.user.address &&
          session.user.address.department
        ) {
          dispatch(storeOperations.getLots(session.user.address.department));
        } else {
          dispatch(storeOperations.getLots());
        }
      }
      if (!categories.init) {
        dispatch(storeOperations.getCategories());
      }
    }
  }, [dispatch, session, departments, categoriesLot, lots, categories]);

  return (
    <div className="root-inside">
      <Header
        isAuthenticated={session.isAuthenticated}
        user={session.user}
        activeCart={session.activeCart}
        activeFreeCart={session.activeFreeCart}
        pendingCarts={session.pendingCarts}
        pendingFreeCarts={session.pendingFreeCarts}
      />

      <main id="main-app">
      {props.children}
      </main>

      {session.isLoading && (
        <GlobalLoader message={"Chargement en cours ..."} />
      )}

      {modal.name !== null && (
        <ModalModel
          modal={modal}
          session={session}
          departments={departments}
          categories={categories}
        />
      )}
      <Error />
      <Footer />
    </div>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
