import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Collapse, Button, Input } from "reactstrap";
import { modalsOperations } from "state/ducks/modal";
import { sessionOperations } from "state/ducks/session";
import ArticleVariations from "./ArticleVariations";
import { centimesToCurrency } from "utils/Utils";

const Article = (props) => {
  const dispatch = useDispatch();

  const {
    article,
    isAuthenticated,
    cart,
    setDetailToggleArticleId,
    detailToggleArticleId,
    user,
    supplierId,
  } = props;

  let orderFreeSupplier = null;
  if (cart?.orderFreeSuppliers) {
    orderFreeSupplier = cart.orderFreeSuppliers.find((ofSupplier) => {
      return (
        supplierId === ofSupplier.supplier.id
        // article.supplier.id === ofSupplier.supplier.id ||
        // article.supplier === "/api/suppliers/" + ofSupplier.supplier.id
      );
    });
  }

  let articleInCart = null;
  if (orderFreeSupplier) {
    articleInCart = orderFreeSupplier.orderFreeArticles.find(function (item) {
      return item.freeArticle.id === article.id;
    });
  }

  // Retourne le prix en fonction de la quantité
  const calculatePrice = (qty, article) => {
    let quantityB = article.quantityB;
    let quantityC = article.quantityC;

    let quantity = parseInt(qty);

    // En fonction des paliers dépassés, on choisit le prix à appliquer
    if (article.priceA && quantity > 0) {
      if (quantityB === 1 || quantityB === null) {
        return quantity * parseInt(article.priceA);
      } else if (quantityC === 1 || quantityB === null) {
        if (quantity < quantityB) {
          return quantity * parseInt(article.priceA);
        } else {
          return quantity * parseInt(article.priceB);
        }
      } else {
        if (quantity < quantityB) {
          return quantity * parseInt(article.priceA);
        } else if (quantity >= quantityB && quantity <= quantityC) {
          return quantity * parseInt(article.priceB);
        } else {
          return quantity * parseInt(article.priceC);
        }
      }
    }
    return 0;
  };

  // Ajoute un article dans le panier
  const addArticleInCart = (orderFreeSupplier, articleInCart, cartId) => {
    if (parseInt(quantity) > 0) {
      let data = {
        articleId: article.id,
        quantity: parseInt(quantity),
        price: parseInt(totalPrice) / parseInt(quantity),
      };
      data.cartId = cartId;

      if (orderFreeSupplier) {
        data.orderFreeSupplierId = orderFreeSupplier.id;
      } else {
        data.supplierId = supplierId;
      }

      dispatch(sessionOperations.addArticle(data, cartId, "free"));
    }
  };

  const renderSwitchMode = (orderFreeSupplier, articleInCart) => {
    let mode = articleInCart ? "panier" : "catalog";

    switch (mode) {
      case "catalog": {
        return (
          <Button
            onClick={() =>
              cart.id !== null
                ? addArticleInCart(orderFreeSupplier, articleInCart, cart.id)
                : user.roles.includes("ROLE_SUPPLIER")
                ? dispatch(
                    modalsOperations.getModalStatus(
                      "panier_libre_create_as_supplier",
                      true,
                      null
                    )
                  )
                : dispatch(
                    modalsOperations.getModalStatus(
                      "panier_libre_create",
                      true,
                      null
                    )
                  )
            }
            style={{ marginRight: "10px" }}
            className="btn-white"
            disabled={quantity > 0 || cart.id === null ? false : true}
          >
            Ajouter au panier
          </Button>
        );
      }
      case "panier": {
        return (
          <Button
            onClick={(e) =>
              dispatch(
                sessionOperations.removeArticle(articleInCart.id, "free")
              )
            }
            style={{ marginRight: "10px" }}
            className="btn-grey"
          >
            Retirer du panier
          </Button>
        );
      }
      default:
        break;
    }
  };

  // Prix
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (articleInCart) {
      setQuantity(articleInCart.quantity);
      setTotalPrice(calculatePrice(articleInCart.quantity, article));
    }
  }, [articleInCart, article]);

  // Toggle
  const [detailToggle, setDetailToggle] = useState(false);
  const updateToggle = (toggle, detailToggleArticleId) => {
    if (toggle) {
      setDetailToggleArticleId(detailToggleArticleId);
    } else {
      setDetailToggleArticleId(null);
    }
  };
  useEffect(() => {
    setDetailToggle(detailToggleArticleId === article.id);
  }, [detailToggleArticleId, article]);

  let priceMinus = article.priceA;
  if (article.priceC && article.priceC < priceMinus) {
    priceMinus = article.priceC;
  }
  if (article.priceB && article.priceB < priceMinus) {
    priceMinus = article.priceB;
  }

  return (
    <div className="article-bloc">
      <Row>
        <Col md="1">
          <p>
            <b>{article.id}</b>
          </p>
        </Col>
        <Col md="4">
          <p>
            <b>{article.name}</b>
          </p>
        </Col>
        <Col md="2">
          <p>
            à partir de{" "}
            <b>
              {centimesToCurrency(priceMinus)} / {article.unit}
            </b>
          </p>
        </Col>
        <Col md="1">
          {article.priceA && (
            <Input
              onChange={(e) => {
                setQuantity(e.target.value);
                setTotalPrice(calculatePrice(e.target.value, article));
              }}
              type="number"
              min="0"
              max="1000000"
              value={quantity}
              disabled={
                articleInCart && articleInCart.quantity > 0 ? true : false
              }
            />
          )}
        </Col>
        <Col md="1">
          <div>
            {article.priceA && (
              <span>
                Total HT : <br />
                <b>{centimesToCurrency(totalPrice)}</b>
              </span>
            )}
          </div>
        </Col>
        <Col md="3">
          {!isAuthenticated ? (
            <div className="text-right" style={{ marginRight: "15px" }}>
              <Button
                className="btn-white"
                onClick={() =>
                  dispatch(modalsOperations.getModalStatus("login", true, null))
                }
              >
                Détails (Réservés aux acheteurs libres)
              </Button>
            </div>
          ) : (
            <Row className="text-center">
              <Col md="6">
                {article.priceA &&
                  renderSwitchMode(orderFreeSupplier, articleInCart)}
              </Col>
              <Col md="6">
                <Button
                  className="btn-white"
                  onClick={() => updateToggle(!detailToggle, article.id)}
                >
                  Détails
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Collapse isOpen={detailToggle}>
            <div style={{ padding: "20px 10px" }}>
              <p style={{ marginBottom: "30px" }}>{article.description}</p>
              {article.priceA ? (
                <div>
                  <div>
                    <p>Prix unitaire des entreprises:</p>
                  </div>
                  <ArticleVariations
                    quantityB={article.quantityB}
                    quantityC={article.quantityC}
                    priceA={article.priceA}
                    priceB={article.priceB}
                    priceC={article.priceC}
                    unit={article.unit}
                  />
                </div>
              ) : (
                <div>
                  <div>
                    <p>Aucun prestataire</p>
                  </div>
                </div>
              )}
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

Article.propTypes = {};

Article.defaultProps = {};

export default Article;
