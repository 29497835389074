import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {

    const session = useSelector(
      (state) => state.session,
      shallowEqual
    )
    const user = session.user;

    const userRoles = user && user.roles ? user.roles : [];

    let roleOk = false;
    if (rest.roles && rest.roles.length > 0) {
      userRoles.forEach(role => {
        if (rest.roles.includes(role)) {
          return roleOk = true;
        }
      })
    }
    else {
      roleOk = true;
    }

    return (
        <Route {...rest} render={props => (
          session.isAuthenticated && roleOk ?
          <Component {...props} />
          : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;
