import React from "react";
import { Row, Col } from "reactstrap";
import { useSelector, shallowEqual } from "react-redux";
import Account from "./../Account";
import BlocInfos from "views/universal/BlocInfos/BlocInfos";
import IconPanier from "assets/img/icon-panier.svg";
import moment from "moment/moment";
import "moment/locale/fr";
import arrowPanier from "assets/img/arrow-product.svg";
import { centimesToCurrency } from "utils/Utils";

const FreeOrders = (props) => {
  const session = useSelector((state) => state.session, shallowEqual);

  const renderBlocDetails = (value) => {
    const blocPanier = document.querySelector(`#c${value}`);
    blocPanier.style.display =
      blocPanier.style.display === "block" ? "none" : "block";
  };

  const renderDetails = (orderFreeSupplier) => {
    return (
      <div id={"c" + orderFreeSupplier.id} className="bloc-panier-table">
        <div className="bloc-panier-header">
          <div>N°Bon De Commande</div>
          <div>Statut</div>
          <div>Département</div>
          <div>Client</div>
          <div>Total HT</div>
          {(orderFreeSupplier.status === 1 ||
            orderFreeSupplier.status === 2) && <div>Lien</div>}
        </div>
        <div className="bloc-panier-content">
          <div>
            <div className="bloc-panier-content-lot">
              <div className="bloc-panier-content-lot-header">
                <div>
                  <p>{orderFreeSupplier.status > 0 && orderFreeSupplier.id}</p>
                </div>

                <div>
                  <p>
                    {orderFreeSupplier.status > 0 &&
                      orderFreeSupplier.statusTextFR}
                  </p>
                </div>

                <div>{orderFreeSupplier.postal}</div>
                <div className="bloc-panier-content-lot-header-lot">
                  {orderFreeSupplier.customerName}
                </div>

                <div>
                  <p>
                    {orderFreeSupplier.status > 0 &&
                      centimesToCurrency(orderFreeSupplier.price)}
                  </p>
                </div>
                {(orderFreeSupplier.status === 1 ||
                  orderFreeSupplier.status === 2) && (
                  <div>
                    <a
                      href={
                        orderFreeSupplier.publicUrl &&
                        orderFreeSupplier.publicUrl
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Résumé & bons de commande
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = (orderFreeSupplier) => {
    return (
      <div key={orderFreeSupplier.id}>
        <div className="bloc-panier bloc-panier-sent">
          <Row style={{ marginBottom: "30px" }}>
            <Col md={6} className="d-flex align-items-center">
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="date-panier">
                  Panier du :{" "}
                  <b>
                    {moment(orderFreeSupplier.date).locale("fr").format("L")}
                  </b>
                </span>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <h3 className="title-panier">{orderFreeSupplier.customerName}</h3>
            </Col>
          </Row>
          <Row>
            <Col md={8} className="d-flex align-items-center">
              <ul className="list-infos-panier">
                <li>
                  Montant Total HT{" "}
                  <b>{centimesToCurrency(orderFreeSupplier.price)}</b>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <button
                onClick={() => renderBlocDetails(orderFreeSupplier.id)}
                className="arrow"
              >
                <img src={arrowPanier} alt="flèche produit" />
              </button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            {orderFreeSupplier !== null
              ? renderDetails(orderFreeSupplier)
              : null}
          </Col>
        </Row>
      </div>
    );
  };

  const render = () => {
    if (!session.orderFreeSuppliers || session.orderFreeSuppliers.length < 1) {
      return (
        <BlocInfos title="Vous n'avez aucune commande" icon={IconPanier} />
      );
    } else {
      return session.orderFreeSuppliers.map((orderFreeSupplier, key) => {
        return <span key={key}>{renderOrder(orderFreeSupplier)}</span>;
      });
    }
  };

  return <Account>{render()}</Account>;
};

FreeOrders.propTypes = {};

FreeOrders.defaultProps = {};

export default FreeOrders;
