import { fetch } from "../utils";
import { notificationsOperations } from "state/ducks/notifications";
import { store } from "state/store";

const baseUrl = process.env.REACT_APP_API_URL;

const apiService = () => next => action => {
  const result = next(action);
  if (!action.meta || !action.meta.async) {
    return result;
  }
  let { path, method, body, params } = action.meta;

  if (!path) {
    throw new Error(`'path' not specified for async action ${action.type}`);
  }

  let url = `${baseUrl}/${path}?`;

  if (method === "GET") {
    body = params;
  }

  let tokenAuth = store.getState().session.token;

  return fetch(url, method, body, tokenAuth).then(
    res => handleResponse(res, action, next),
    err => handleErrors(err, action, next)
  );
};

export default apiService;

function handleErrors(err, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.error = err;

  next({
    type: `${action.type}_FAILED`,
    payload: payload,
    meta: action.meta
  });

  // Supprime le token si erreur de token + token en session
  if (window.localStorage.getItem("token") !== null && err.response && err.response.data && err.response.data.code && err.response.data.message) {
    if (err.response.data.code === 401 && (err.response.data.message.includes('JWT') || err.response.data.message.includes('jwt'))) {
      window.localStorage.removeItem("token");
      window.location.reload(true);
    }
  }

  store.dispatch(notificationsOperations.getResponseError(err));
  return Promise.reject(err);
}

function handleResponse(res, action, next) {

  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.result = res.data;

  if (res.data.total) {
    payload.total = res.data.total;
  }

  next({
    type: `${action.type}_COMPLETED`,
    payload: payload,
    meta: action.meta
  });
  return res;
}
