import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    session: object
}
*/

const initialState = {
  init: false,
  loaded: false,
  isAuthenticated: false,
  redirectAfterLogin: null,
  token: window.localStorage.getItem("token"),
  isLoading: false,
  user: null,
  activeCart: null,
  activeFreeCart: null,
  lastLoadedCart: null,
  confirmedCarts: {
    init: false,
    pages: [],
    data: [],
    end: false,
  },
  confirmedFreeCarts: {
    init: false,
    pages: [],
    data: [],
    end: false,
  },
  pendingCarts: [],
  pendingFreeCarts: [],
  proposalCarts: [],
  proposalFreeCarts: [],
  orderLotSuppliers: [],
  orderFreeSuppliers: [],
};

const sessionReducer = createReducer(initialState)({
  [types.LOGIN]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.LOGIN_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [types.LOGIN_COMPLETED]: (state, action) => {
    // LOGIN WITH JWT
    window.localStorage.setItem("token", action.payload.result.token);
    return {
      ...state,
      redirectAfterLogin: action.payload.redirectUrl,
      isLoading: true,
      token: action.payload.result.token,
    };
  },

  [types.LOGOUT]: (state, action) => {
    window.localStorage.removeItem("token");
    window.location.reload(true);
    return { ...state, user: null, isAuthenticated: false };
  },

  [types.REGISTER_CUSTOMER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REGISTER_CUSTOMER_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.REGISTER_CUSTOMER_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [types.INITIALIZE_SESSION]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [types.INITIALIZE_SESSION_COMPLETED]: (state, action) => {
    if (action.payload) {
      let user = action.payload.result.user;

      if (user?.lotGeos) {
        user.freeDepartmentCodes = [];
        user.freeArticles.forEach((freeArticle) => {
          if (freeArticle?.departments) {
            freeArticle.departments.forEach((department) => {
              if (!user.freeDepartmentCodes.includes(department.code)) {
                user.freeDepartmentCodes.push(department.code);
              }
            });
          }
        });
      }
      return {
        ...state,
        user: user,
        activeCart: action.payload.result.activeCart,
        activeFreeCart: action.payload.result.activeFreeCart,
        pendingCarts: action.payload.result.pendingCarts,
        pendingFreeCarts: action.payload.result.pendingFreeCarts,
        proposalCarts: action.payload.result.proposalCarts,
        proposalFreeCarts: action.payload.result.proposalFreeCarts,
        orderLotSuppliers: action.payload.result.orderLotSuppliers,
        orderFreeSuppliers: action.payload.result.orderFreeSuppliers,
        isAuthenticated: true,
        isLoading: false,
        loaded: true,
        init: true,
      };
    } else {
      return { ...state, isLoading: false, loaded: true, init: true };
    }
  },

  [types.ADD_ARTICLE]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.ADD_ARTICLE_COMPLETED]: (state, action) => {
    let returnState = {
      ...state,
      isLoading: false,
    };
    let cart = action.payload.result;
    if (
      action.payload &&
      action.payload.cartType &&
      action.payload.cartType === "free"
    ) {
      returnState = { ...returnState, activeFreeCart: cart };
    } else {
      returnState = { ...returnState, activeCart: cart };
    }

    if (
      state?.lastLoadedCart?.id &&
      cart?.id &&
      state.lastLoadedCart.id === cart.id
    ) {
      returnState = { ...returnState, lastLoadedCart: cart };
    }

    return returnState;
  },

  [types.REMOVE_ARTICLE]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REMOVE_ARTICLE_COMPLETED]: (state, action) => {
    const { orderArticleId } = action.payload;

    if (action?.payload?.cartType && action.payload.cartType === "free") {
      let orderFreeSuppliers = state.activeFreeCart.orderFreeSuppliers.map(
        (orderFreeSupplier, index) => {
          orderFreeSupplier.orderFreeArticles =
            orderFreeSupplier.orderFreeArticles.filter(
              (orderFreeArticle, i) => {
                if (
                  parseInt(orderArticleId) !== parseInt(orderFreeArticle.id)
                ) {
                  return true;
                }
                return false;
              }
            );
          return orderFreeSupplier;
        }
      );
      orderFreeSuppliers = orderFreeSuppliers.filter(
        (orderFreeSupplier, index) => {
          if (orderFreeSupplier.orderFreeArticles) {
            return orderFreeSupplier.orderFreeArticles.length > 0;
          } else {
            return false;
          }
        }
      );
      let freeCart = {
        ...state.activeFreeCart,
        orderFreeSuppliers: orderFreeSuppliers,
      };
      let totalAndCountArticles = getFreeCartPriceAndCountArticles(freeCart);
      let returnState = {
        ...state,
        isLoading: false,
        activeFreeCart: {
          ...freeCart,
          price: totalAndCountArticles.price,
          countArticles: totalAndCountArticles.countArticles,
        },
      };
      if (
        state?.lastLoadedCart?.id &&
        state.lastLoadedCart.id === state.activeFreeCart.id
      ) {
        returnState = {
          ...returnState,
          lastLoadedCart: {
            ...freeCart,
            price: totalAndCountArticles.price,
            countArticles: totalAndCountArticles.countArticles,
          },
        };
      }
      return returnState;
    }

    let orderLots = state.activeCart.orderLots.map((orderLot, index) => {
      orderLot.orderArticles = orderLot.orderArticles.filter(
        (orderArticle, i) => {
          if (parseInt(orderArticleId) !== parseInt(orderArticle.id)) {
            return true;
          }
          return false;
        }
      );
      return orderLot;
    });
    orderLots = orderLots.filter((orderLot, index) => {
      if (orderLot.orderArticles) {
        return orderLot.orderArticles.length > 0;
      } else {
        return false;
      }
    });
    return {
      ...state,
      isLoading: false,
      activeCart: {
        ...state.activeCart,
        orderLots: orderLots,
      },
    };
  },

  [types.REMOVE_LOT]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REMOVE_LOT_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.REMOVE_LOT_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return state;
  },

  [types.REMOVE_FREE_SUPPLIER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REMOVE_FREE_SUPPLIER_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.REMOVE_FREE_SUPPLIER_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return state;
  },

  [types.CHANGE_ACTIVE_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.CHANGE_ACTIVE_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.CHANGE_ACTIVE_CART_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return { ...state, isLoading: true };
  },

  [types.CONFIRM_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.CONFIRM_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.CONFIRM_CART_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return {
      ...state,
      isLoading: true,
    };
  },

  [types.CREATE_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.CREATE_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.CREATE_CART_COMPLETED]: (state, action) => {
    if (action.payload.cartType === "free") {
      return (window.location.href = "/catalogue/libre");
    } else if (action.payload.cartType === "formalized") {
      return (window.location.href = "/catalogue");
    }
    return window.location.reload(true);
  },

  [types.UPDATE_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.UPDATE_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.UPDATE_CART_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return {
      ...state,
      isLoading: true,
    };
  },

  [types.REMOVE_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REMOVE_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.REMOVE_CART_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return { ...state, isLoading: false };
  },

  [types.TAKE_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.TAKE_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.TAKE_CART_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return {
      ...state,
      isLoading: false,
    };
  },

  [types.GIVE_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.GIVE_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.GIVE_CART_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  },

  [types.PRINT_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.PRINT_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.PRINT_CART_COMPLETED]: (state, action) => {
    window.open(action.payload.result, "_blank");
    return {
      ...state,
      isLoading: false,
    };
  },

  [types.UPDATE_CUSTOMER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.UPDATE_CUSTOMER_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.UPDATE_CUSTOMER_COMPLETED]: (state, action) => {
    window.location.reload(true);
    return {
      ...state,
      isLoading: true,
    };
  },

  [types.RESET_PASSWORD]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.RESET_PASSWORD_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.RESET_PASSWORD_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [types.GET_CONFIRMED_CARTS]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [types.GET_CONFIRMED_CARTS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.GET_CONFIRMED_CARTS_COMPLETED]: (state, action) => {
    if (action.payload.cartType === "free") {
      // Si la page a deja été chargée, ne rien faire
      if (state.confirmedFreeCarts.pages.includes(action.payload.pageNumber)) {
        return { ...state, isLoading: false };
      } else {
        let end = false;
        // 30 items par page
        if (action.payload.result && action.payload.result.length < 30) {
          end = true;
        }
        return {
          ...state,
          isLoading: false,
          confirmedFreeCarts: {
            pages: [
              ...state.confirmedFreeCarts.pages,
              action.payload.pageNumber,
            ],
            data: [...state.confirmedFreeCarts.data, ...action.payload.result],
            end: end,
          },
        };
      }
    } else {
      // Si la page a deja été chargée, ne rien faire
      if (state.confirmedCarts.pages.includes(action.payload.pageNumber)) {
        return { ...state, isLoading: false };
      } else {
        let end = false;
        // 30 items par page
        if (action.payload.result && action.payload.result.length < 30) {
          end = true;
        }
        return {
          ...state,
          isLoading: false,
          confirmedCarts: {
            pages: [...state.confirmedCarts.pages, action.payload.pageNumber],
            data: [...state.confirmedCarts.data, ...action.payload.result],
            end: end,
          },
        };
      }
    }
  },

  [types.LOAD_CART]: (state, action) => {
    return { ...state, isLoading: true, init: true };
  },
  [types.LOAD_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.LOAD_CART_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      lastLoadedCart: action.payload.result,
    };
  },

  [types.ADD_FREE_ARTICLE]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.ADD_FREE_ARTICLE_COMPLETED]: (state, action) => {
    const freeArticle = action.payload.result;
    const freeArticles = [...state.user.freeArticles, freeArticle];
    return {
      ...state,
      isLoading: false,
      user: {
        ...state.user,
        freeArticles: freeArticles,
        freeDepartmentCodes: getFreeDepartmentCodes(freeArticles),
      },
    };
  },

  [types.UPDATE_FREE_ARTICLE]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.UPDATE_FREE_ARTICLE_COMPLETED]: (state, action) => {
    const freeArticle = action.payload.result;
    const index = state.user.freeArticles.findIndex(
      (fa) => fa.id === freeArticle.id
    );
    const freeArticles = [
      ...state.user.freeArticles.slice(0, index),
      freeArticle,
      ...state.user.freeArticles.slice(index + 1),
    ];
    return {
      ...state,
      isLoading: false,
      user: {
        ...state.user,
        freeArticles: freeArticles,
        freeDepartmentCodes: getFreeDepartmentCodes(freeArticles),
      },
    };
  },

  [types.REMOVE_FREE_ARTICLE]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.REMOVE_FREE_ARTICLE_COMPLETED]: (state, action) => {
    const freeArticleId = action.payload.freeArticleId;
    const freeArticles = state.user.freeArticles.filter(
      (element) => element.id !== freeArticleId
    );
    return {
      ...state,
      isLoading: false,
      user: {
        ...state.user,
        freeArticles: freeArticles,
        freeDepartmentCodes: getFreeDepartmentCodes(freeArticles),
      },
    };
  },

  [types.SEND_CART_TO_CUSTOMER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.SEND_CART_TO_CUSTOMER_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.SEND_CART_TO_CUSTOMER_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  },

  [types.RESPONSE_PROPOSAL_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.RESPONSE_PROPOSAL_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.RESPONSE_PROPOSAL_CART_COMPLETED]: (state, action) => {
    // pas de reload ici car redirection sur le panier une fois acceptée
    return {
      ...state,
      isLoading: false,
    };
  },

  [types.UPDATE_SUPPLIER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.UPDATE_SUPPLIER_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [types.UPDATE_SUPPLIER_COMPLETED]: (state, action) => {
    // Reload pour les départements pour les fournisseurs. Permet d'avoir les départements disponibles ensuite pour le catalogue libre
    window.location.reload(true);
    return {
      ...state,
      isLoading: true,
    };
  },
});

/**
 * Permet de calculer le prix du panier et le nombre d'articles
 */
const getFreeCartPriceAndCountArticles = (cart) => {
  let price = 0;
  let countArticles = 0;
  if (cart?.orderFreeSuppliers.length > 0) {
    cart.orderFreeSuppliers.forEach((orderFreeSupplier) => {
      orderFreeSupplier.orderFreeArticles.forEach((orderFreeArticle) => {
        price += orderFreeArticle.price * orderFreeArticle.quantity;
        countArticles += orderFreeArticle.quantity;
      });
    });
  }

  return { price: price, countArticles: countArticles };
};

/**
 * Retourne le tableau des de départments en fonction des articles libres
 * @param {array} freeArticles
 */
const getFreeDepartmentCodes = (freeArticles) => {
  let freeDepartmentCodes = [];
  freeArticles.forEach((fa) => {
    if (fa?.departments) {
      fa.departments.forEach((department) => {
        if (!freeDepartmentCodes.includes(department.code)) {
          freeDepartmentCodes.push(department.code);
        }
      });
    }
  });
  return freeDepartmentCodes;
};

export default sessionReducer;
