import React from "react";
import iconDl from 'assets/img/generate-pdf-svgrepo-com.svg';
import iconSign from 'assets/img/pen-svgrepo-com.svg';

const Step12 = (props) => {

  const {
    closeAndGenerate,
    handleCloseAndGenerateForm,
    handleResetForm,
    pdf
  } = props

  return (
    <div className="card p-5">

      {closeAndGenerate ?
        <div className="text-center">
          <p className="mb-5 text-center">
            Merci d’avoir valider votre candidature et votre offre.
          </p>
          <p className="mb-5 text-center">
            Vous devez impérativement enregistrer le fichier PDF ci-dessous et le déposer sur le profil acheteur avant la date d’échéance, conformément au règlement de la consultation.
          </p>
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            <button className="btn-green min">
              <img src={iconDl} alt="icon search filter" className="svg-size"/><br/>
              Télécharger ou imprimer<br/> une copie PDF du marché et de ses annexes
            </button>
          </a><br/>
          <button className="btn-white min mt-5" onClick={handleResetForm}>
            Soumettre une nouvelle demande<br/>(pensez à bien sauvegarder votre copie PDF ci-dessus avant de cliquer-ici)
          </button>
        </div>
      :
        <>
          <p className="mb-5 text-center">
            Vous êtes invités à valider votre candidature et votre offre. Un fichier PDF sera généré qui sera composé de votre dossier : contrat et ses annexes. Vous devez impérativement enregistrer ce fichier PDF et le déposer sur le profil acheteur avant la date d’échéance, conformément au règlement de la consultation.
          </p>
          <button className="btn-white full" onClick={handleCloseAndGenerateForm}>
            <img src={iconSign} alt="icon search filter" className="svg-size"/><br/>
            Signer éléctroniquement<br/> et clôturer le formulaire, générer le fichier final PDF<br/>
          </button><br/>
        </>
      }

    </div>

  );

};

Step12.propTypes = {
};

Step12.defaultProps = {

};

export default Step12;
