import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Collapse, Button } from "reactstrap";
import { modalsOperations } from "state/ducks/modal";

const ArticleWithoutSupplier = (props) => {
  const dispatch = useDispatch();

  const {
    departmentCode,
    lot,
    article,
    isAuthenticated,
    setDetailToggleArticleId,
    detailToggleArticleId,
    userIsFormalized,
  } = props;

  // Toggle
  const [detailToggle, setDetailToggle] = useState(false);
  const updateToggle = (toggle, detailToggleArticleId) => {
    if (toggle) {
      setDetailToggleArticleId(detailToggleArticleId);
    } else {
      setDetailToggleArticleId(null);
    }
  };
  useEffect(() => {
    setDetailToggle(detailToggleArticleId === article.id);
  }, [detailToggleArticleId, article]);

  return (
    <div className="article-bloc">
      <Row>
        <div style={{ width: "9%", paddingLeft: "10px" }}>
          <p>
            <b>
              {departmentCode} {lot.code} {article.code} 1
            </b>
          </p>
        </div>
        <Col md="4">
          <p>
            <b>{article.name}</b>
          </p>
        </Col>
        <Col md="2"></Col>
        <div style={{ width: "10%", paddingLeft: "10px" }}></div>
        <Col md="1">
          <div></div>
        </Col>
        {!isAuthenticated || !userIsFormalized ? (
          <div className="text-right" style={{ marginRight: "15px" }}>
            <Button
              className="btn-white"
              onClick={() => {
                if (userIsFormalized) {
                  dispatch(
                    modalsOperations.getModalStatus("login", true, null)
                  );
                }
                return;
              }}
            >
              Détails (Réservés aux acheteurs formalisés)
            </Button>
          </div>
        ) : (
          <div className="text-right" style={{ marginRight: "15px" }}>
            <Button
              className="btn-white"
              onClick={() => updateToggle(!detailToggle, article.id)}
            >
              Détails
            </Button>
          </div>
        )}
      </Row>
      <Row>
        <Col md="12">
          <Collapse isOpen={detailToggle}>
            <div style={{ padding: "20px 10px" }}>
              <h4 style={{ fontSize: "20px", marginBottom: "20px" }}>
                <b style={{ color: "#DB7124" }}>{lot.code}</b> : {lot.name}
              </h4>
              <p style={{ marginBottom: "30px" }}>
                Aucun prestataire pour ce département pour le moment
              </p>
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

ArticleWithoutSupplier.propTypes = {};

ArticleWithoutSupplier.defaultProps = {};

export default ArticleWithoutSupplier;
