import React from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col, FormGroup, Input, Label } from "reactstrap";
import "./CreateCart.css";
import { modalsOperations } from "state/ducks/modal";
import { sessionOperations } from "state/ducks/session";

const CreateCart = (props) => {
  const { activeCart, pendingCarts, activeFreeCart, pendingFreeCarts, user } =
    props;

  const dispatch = useDispatch();

  return (
    <div id="nav-create-panier">
      <Container>
        {user && user.type === "formalized" && (
          <Row>
            <Col lg="12" className="text-center p-0 mb-2">
              {user && user.roles && user.roles.includes("ROLE_SUPPLIER") ? (
                <Row>
                  <Col lg="12" className="text-center p-0 mb-2">
                    <button
                      onClick={() =>
                        dispatch(
                          modalsOperations.getModalStatus(
                            "panier_create_as_supplier",
                            true,
                            null
                          )
                        )
                      }
                      className="btn-white btn btn-secondary"
                      style={{ width: "100%" }}
                    >
                      CRÉER UN PANIER FORMALISE POUR UN CLIENT FORMALISE
                    </button>
                  </Col>
                  <Col lg="12" className="text-center p-0 mb-2">
                    <button
                      onClick={() =>
                        dispatch(
                          modalsOperations.getModalStatus(
                            "panier_libre_create_as_supplier",
                            true,
                            null
                          )
                        )
                      }
                      className="btn-white btn btn-secondary"
                      style={{ width: "100%" }}
                    >
                      CRÉER UN PANIER LIBRE POUR UN CLIENT LIBRE
                    </button>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {user && user.roles && user.roles.includes("ROLE_CUSTOMER") && (
                <Row>
                  <Col lg="12" className="text-center p-0 mb-2">
                    <button
                      onClick={() =>
                        dispatch(
                          modalsOperations.getModalStatus(
                            "panier_create",
                            true,
                            null
                          )
                        )
                      }
                      className="btn-white btn btn-secondary"
                      style={{ width: "100%" }}
                    >
                      CRÉER UN NOUVEAU PANIER FORMALISE
                    </button>
                  </Col>
                  <Col lg="12" className="text-center p-0 mb-2">
                    <button
                      onClick={() =>
                        dispatch(
                          modalsOperations.getModalStatus(
                            "panier_libre_create",
                            true,
                            null
                          )
                        )
                      }
                      className="btn-white btn btn-secondary"
                      style={{ width: "100%" }}
                    >
                      CRÉER UN NOUVEAU PANIER LIBRE
                    </button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )}
        {user && user.type === "free" && (
          <Row>
            <Col lg="12" className="text-center p-0 mb-2">
              {user && user.roles && user.roles.includes("ROLE_SUPPLIER") ? (
                <button
                  onClick={() =>
                    dispatch(
                      modalsOperations.getModalStatus(
                        "panier_libre_create_as_supplier",
                        true,
                        null
                      )
                    )
                  }
                  className="btn-white btn btn-secondary"
                  style={{ width: "100%" }}
                >
                  CRÉER UN PANIER LIBRE POUR UN CLIENT LIBRE
                </button>
              ) : (
                <button
                  onClick={() =>
                    dispatch(
                      modalsOperations.getModalStatus(
                        "panier_libre_create",
                        true,
                        null
                      )
                    )
                  }
                  className="btn-white btn btn-secondary"
                  style={{ width: "100%" }}
                >
                  CRÉER UN NOUVEAU PANIER LIBRE
                </button>
              )}
            </Col>
          </Row>
        )}
        {(activeCart !== null || (pendingCarts && pendingCarts.length > 0)) && (
          <Row>
            <Col lg="12" className="p-0 mb-2">
              <Label style={{ display: "block", marginTop: "20px" }}>
                Choisir un panier formalisé en cours
              </Label>
              <FormGroup style={{ margin: "0px" }}>
                <Input
                  type="select"
                  name="select_panier"
                  id="select_panier"
                  onChange={(e) =>
                    dispatch(
                      sessionOperations.changeActiveCart(
                        user.id,
                        user.roles,
                        e.target.value
                      )
                    )
                  }
                >
                  {activeCart !== null ? (
                    <option value={activeCart.id} defaultValue={activeCart.id}>
                      {activeCart.name}
                    </option>
                  ) : (
                    <option>Merci de choisir un panier</option>
                  )}
                  {pendingCarts &&
                    pendingCarts.length > 0 &&
                    pendingCarts.map(
                      (cart, key) =>
                        (activeCart === null || activeCart.id !== cart.id) && (
                          <option
                            value={cart.id}
                            key={key}
                            defaultValue={cart.id}
                          >
                            {cart.name}
                          </option>
                        )
                    )}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        )}
        {(activeFreeCart !== null ||
          (pendingFreeCarts && pendingFreeCarts.length > 0)) && (
          <Row>
            <Col lg="12" className="p-0 mb-2">
              <Label style={{ display: "block", marginTop: "20px" }}>
                Choisir un panier libre en cours
              </Label>
              <FormGroup style={{ margin: "0px" }}>
                <Input
                  type="select"
                  name="select_panier"
                  id="select_panier"
                  onChange={(e) =>
                    dispatch(
                      sessionOperations.changeActiveCart(
                        user.id,
                        user.roles,
                        e.target.value,
                        "free"
                      )
                    )
                  }
                >
                  {activeFreeCart !== null ? (
                    <option
                      value={activeFreeCart.id}
                      defaultValue={activeFreeCart.id}
                    >
                      {activeFreeCart.name}
                    </option>
                  ) : (
                    <option>Merci de choisir un panier</option>
                  )}
                  {pendingFreeCarts &&
                    pendingFreeCarts.length > 0 &&
                    pendingFreeCarts.map(
                      (cart, key) =>
                        (activeFreeCart === null ||
                          activeFreeCart.id !== cart.id) && (
                          <option
                            value={cart.id}
                            key={key}
                            defaultValue={cart.id}
                          >
                            {cart.name}
                          </option>
                        )
                    )}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

// export
export default CreateCart;
