import { createSelector } from "reselect";

const formSupplierState = state => state.formSupplier;

// Permet de récuperer la liste des entreprises postulants
const contractorsSelector = createSelector(
  formSupplierState,
  formSupplierState => {

    let contractors = []

    formSupplierState.step3.contractors.forEach((contractor, index) => {
      contractors = [...contractors, {id: contractor.id, name: contractor.organization}]
    });

    return contractors;

  }
);

const candidateSelector = createSelector(
  formSupplierState,
  formSupplierState => {

    let candidate = {
      ...formSupplierState
    };

    return candidate;
  }
);

export {
  contractorsSelector,
  candidateSelector
};
