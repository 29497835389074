import React, {useEffect} from "react";
import { Form, ErrorMessage } from 'formik';
import SelectWithData from "views/pages/FormSupplier/Common/Inputs/SelectWithData"
import ReactSelect from "views/pages/FormSupplier/Common/Inputs/ReactSelect"
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError"

const Step2 = (props) => {

  const {
    formRef,
    categoriesLot,
    departments,
    regions,
    values,
    setFieldValue,
    phaseCandidate
  } = props

  let categoriesLotSelected ={}
  let codeCategoriesLotSelected = "";
  let lotIsRegion = false;
  // Permet pour le select d'avoir le code avec le nom
  let formattedLots = [];
  // Permet d'afficher ou non la sélection des régions en fonction du lot 1xx
  if(categoriesLot.data.length > 0 && values.technicalCategory){
    categoriesLotSelected = categoriesLot.data.find(value => parseInt(value.id) === parseInt(values.technicalCategory));
    codeCategoriesLotSelected = categoriesLotSelected.code;
    lotIsRegion = codeCategoriesLotSelected.startsWith('1');
  }

  if (categoriesLot.data.length > 0) {
    categoriesLot.data.forEach((item, i) => {
      formattedLots.push({id:item.id, name: `${item.code} - ${item.name}`})
    });
  }

  // Permet de sélectionné une categorie technique et de réinitialiser le reste du form si changement
  const handleTechnicalCategory = (event) => {
    setFieldValue("technicalCategory", event.currentTarget.value);
    setFieldValue("regions", []);
    setFieldValue("departments", []);
    setFieldValue("fruitlessnessRegions", []);
    setFieldValue("fruitlessnessLots", []);
  }

  // Indique si une région est sélectionnée ou non en fonction des départements, permet de maintenir le select au refresh aussi
  let departmentsRegions = []
  let fruitlessnessRegions = []
  // Besoin de faire ça pour éviter de modifier l'objet initial
  let regionsListWithSelected = regions.map(a => Object.assign({}, a));

  let fruitlessnessRegionsListWithSelected = regions.map(a => Object.assign({}, a));
  // On définit la synthese des regions utilisé dans les départements 1.1 et 1.2 choisis
  useEffect(() => {
    if(lotIsRegion){
      if(values.departments.length > 0){
        values.departments.forEach((departmentsSelected, index) => {
          if(departments.data.length > 0){
            departments.data.forEach((department, index) => {
              if(parseInt(department.id) === parseInt(departmentsSelected)){
                departmentsRegions = [...departmentsRegions, department.region]
              }
            })
          }
        })
      }
      if(values.fruitlessnessLots.length > 0){
        values.fruitlessnessLots.forEach((fruitlessnessLotSelected, index) => {
          if(departments.data.length > 0){
            departments.data.forEach((department, index) => {
              if(parseInt(department.id) === parseInt(fruitlessnessLotSelected)){
                fruitlessnessRegions = [...fruitlessnessRegions, department.region]
              }
            })
          }
        })
      }
      //eslint-disable-next-line
      departmentsRegions = [...new Set(departmentsRegions)];
      //eslint-disable-next-line
      fruitlessnessRegions = [...new Set(fruitlessnessRegions)];

      // Si la synthese des départements et infructeux colle avec la liste des regions alors on ajoute un selected à la select box
      if(departmentsRegions.length > 0){
        let regionsInitSelected = [];
        departmentsRegions.forEach((departmentRegion, index) => {
          regionsListWithSelected.forEach((region, index2) => {
            if(region.name === departmentRegion){
              regionsInitSelected = [...regionsInitSelected, region.id]
            }
          })
        })
        setFieldValue('regions', regionsInitSelected);
      }
      if(fruitlessnessRegions.length > 0){
        let fruitlessnessRegionsInitSelected = [];
        fruitlessnessRegions.forEach((fruitlessnessRegion, index) => {
          fruitlessnessRegionsListWithSelected.forEach((region, index2) => {
            if(region.name === fruitlessnessRegion){
              fruitlessnessRegionsInitSelected = [...fruitlessnessRegionsInitSelected, region.id]
            }
          })
        })
        setFieldValue('fruitlessnessRegions', fruitlessnessRegionsInitSelected);
      }
    }
  }, [categoriesLot, departments, regions])

  // Filtre les departements restants  pour lots infructuosité
  let departmentsRemaining = departments.data;
  if(values.departments.length > 0){
    values.departments.forEach((departmentSelected, index) => {
      if(departmentsRemaining.length > 0){
        departmentsRemaining.forEach((department, index2) => {
          if(parseInt(department.id) === parseInt(departmentSelected)){
            departmentsRemaining = [...departmentsRemaining.slice(0, index2), ...departmentsRemaining.slice(index2 + 1)];
          }
        })
      }
    })
  }

  // Filtre les régions restantes pour lots infructuosité
  let regionsRemaining = fruitlessnessRegionsListWithSelected;
  if(values.regions && values.regions.length > 0){
    values.regions.forEach((regionSelected, index) => {
      if(regionsRemaining.length > 0){
        regionsRemaining.forEach((region, index2) => {
          if(parseInt(region.id) === parseInt(regionSelected)){
            regionsRemaining = [...regionsRemaining.slice(0, index2), ...regionsRemaining.slice(index2 + 1)];
          }
        })
      }
    })
  }

  // Reformatage pour rendre compatible avec React Select
  let departmentsReactSelectFormat = [];
  let departmentsRemainingReactSelectFormat = [];
  let regionsRemainingReactSelectFormat = [];
  let regionsReactSelectFormat = [];

  regionsListWithSelected.forEach((region, index) => {
    regionsReactSelectFormat = [...regionsReactSelectFormat, { value: region.id, label: `${region.code} - ${region.name}` }]
  })

  departments.data.forEach((department, index) => {
    departmentsReactSelectFormat = [...departmentsReactSelectFormat, { value: department.id, label: `${department.code} - ${department.name}` }]
  })

  departmentsRemaining.forEach((department, index) => {
    departmentsRemainingReactSelectFormat = [...departmentsRemainingReactSelectFormat, { value: department.id, label: `${department.code} - ${department.name}` }]
  })

  regionsRemaining.forEach((region, index) => {
    regionsRemainingReactSelectFormat = [...regionsRemainingReactSelectFormat, { value: region.id, label: `${region.code} - ${region.name}` }]
  })

  return (
    <Form className="mb-4" id="step2" ref={formRef}>
      <ScrollToError />
      <h4>2.1 La candidature est présentée :</h4>
      <label>Pour la catégorie technique suivante (1 choix possible uniquement)*</label>

      <SelectWithData name="technicalCategory" items={formattedLots} onChange={(event) => handleTechnicalCategory(event)}/>

      { values.technicalCategory &&
        <>
          <label>Pour le ou les lots géographiques suivants : *</label><br/>
          {lotIsRegion ?
            <div className="mb-3">
              <ReactSelect
                className='input'
                placeholder='Choisissez vos régions'
                onChange={ event => {
                    let valuesSelected = [];
                    event && event.map(value => {
                      return valuesSelected = [...valuesSelected, value.value]
                    })
                    setFieldValue('regions', valuesSelected);
                  }
                }
                value={values.regions}
                options={regionsReactSelectFormat && regionsReactSelectFormat}
                />
              <ErrorMessage name={"regions"} component="div" className="error-red"/>
            </div>
            :
            <>
            <ReactSelect
              className='input'
              placeholder='Choisissez vos lots'
              onChange={ event => {
                  let valuesSelected = [];
                  event && event.map(value => {
                    return valuesSelected = [...valuesSelected, value.value]
                  })
                  setFieldValue('departments', valuesSelected);
                }
              }
              value={values.departments}
              options={departmentsReactSelectFormat && departmentsReactSelectFormat}
              />
              <ErrorMessage name={"departments"} component="div" className="error-red"/>
            </>
          }

        </>
      }

      {phaseCandidate !== '2' && (values.departments.length > 0 || values.regions.length > 0) &&
        <>
          <h4>2.2 Cas particuliers en cas d’infructuosité sur certains lots non candidatés de votre catégorie :</h4>
          <label>Dans l’hypothèse d’une infructuosité de certains lots sur lesquels vous n’auriez pas postulé dans votre catégorie, merci de nous indiquer si vous seriez intéressés par tout ou partie d’entre eux :</label><br/>
          {lotIsRegion ?
            <div className="mb-3">
              <ReactSelect
                className='input'
                placeholder='Choisissez vos régions'
                onChange={ event => {
                    let valuesSelected = [];
                    event && event.map(value => {
                      return valuesSelected = [...valuesSelected, value.value]
                    })
                    setFieldValue('fruitlessnessRegions', valuesSelected);
                  }
                }
                value={values.fruitlessnessRegions}
                options={regionsRemainingReactSelectFormat && regionsRemainingReactSelectFormat}
                />
                <ErrorMessage name={"fruitlessnessRegions"} component="div" className="error-red"/>
            </div>
            :
            <>
              <ReactSelect
                className='input'
                placeholder='Choisissez vos lots'
                onChange={ event => {
                    let valuesSelected = [];
                    event && event.map(value => {
                      return valuesSelected = [...valuesSelected, value.value]
                    })
                    setFieldValue('fruitlessnessLots', valuesSelected);
                  }
                }
                value={values.fruitlessnessLots}
                options={departmentsRemainingReactSelectFormat && departmentsRemainingReactSelectFormat}
                />
                <ErrorMessage name={"fruitlessnessLots"} component="div" className="error-red"/>
            </>
          }

        </>
       }

    </Form>
  );

};

Step2.propTypes = {
};

Step2.defaultProps = {

};

export default Step2;
