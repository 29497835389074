import React from "react";
import { Row, Col } from 'reactstrap';
import { useForm } from "react-hook-form";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import CurrencyInput from 'react-currency-input-field';

const Step10Alt = (props) => {

  const {
    formRef,
    onSubmit,
    prices,
    viewMode
  } = props

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const handlePriceChange = (index, value) => {
    if (prices[index].article.unit === 'Forfait') {
      setValue(`prices.${index}.priceB`, value);
      setValue(`prices.${index}.priceC`, value);
    }
  };

  const validateBudgetPrice = (value) => {
    const numberValue = parseFloat(value.replace(',', '.'));
    const isValidNumber = /^\d+(\,\d{1,2})?$/.test(value);
    const noLetters = /^[\d,]+$/.test(value); // Vérifie qu'il n'y a pas de lettres
    return noLetters && isValidNumber && numberValue >= 0 && numberValue <= 1;
  };

  return (

    <form onSubmit={handleSubmit(onSubmit)} id="step10Alt" ref={formRef} className="mb-4">
      <h4>10.1 Bordereau de prix unitaires</h4>
      <p>Il est précisé que pour chaque article, il est indiqué 2 seuils de quantité (A et B). Ainsi, il est donné la possibilité au prestataire de faire varier son prix en fonction de la quantité qui sera
commandée par l’acheteur. A cet effet, il indique 3 prix ; le premier est le « Prix par Unité pour des unités inférieures au seuil A » ; le deuxième est le « Prix par Unité pour des unités
comprises entre les seuils A et B » ; et le troisième est le « Prix par Unité pour des unités supérieures au seuil B ». Les unités principales utilisées sont : « ml » pour mètre linéaire ; « F
» pour forfait ; « U » pour unités ; « J » pour jours ; « m2 » pour mètre-carré ; « m3 » pour mètre cube ; « T » pour Tonnes ; « PDL » pour Points de Livraison ; « KWh » pour Kilo Watt
heure ; « MWh cumac » pour Méga Watt heure cumac ; « S » pour semaine ; « € (budget) » pour budget de l’opération -> dans ce dernier cas, il convient de marquer votre offre en %
sous la forme 0.XX€ (exemple : si vous souhaitez pratiquer 5% d’honoraire sur le budget de l’opération, il convient d’indiquer 0.05 € comme prix unitaire pour l’unité « € »).
</p>
      <Col>
        {viewMode !== true && <button type="submit" className="btn-green mt-3" >
          Sauvegarder
        </button>}
        <br />
      </Col>

      <div className="sticky d-flex align-items-center">
        <Col xs="12" sm="1">Ref</Col>
        <Col xs="12" sm="5">Article</Col>
        <Col xs="12" sm="1">Seuil A</Col>
        <Col xs="12" sm="1">Seuil B</Col>
        <Col xs="12" sm="1">Unité</Col>
        <Col xs="12" sm="1">Prix par unité pour des unités inférieures au seuil A</Col>
        <Col xs="12" sm="1">Prix par unité pour des unités comprises entre les seuils A et B</Col>
        <Col xs="12" sm="1">Prix par unité pour des unités supérieures au seuil B</Col>
      </div>

      {prices?.map((price, index) => {
        const unitIsBudget = price.article.unit === '€ (Budget)'; // Ajout de cette ligne
        const unitIsForfait = price.article.unit === 'Forfait';

        return (
          <span key={index}>
            <input type="hidden" name={`prices.${index}.articlePriceId`} value={price.id}
              {...register(`prices.${index}.articlePriceId`, {
                required: true,
              })}
            />
            <input type="hidden" name={`prices.${index}.articleId`} value={price.article.id}
              {...register(`prices.${index}.articleId`, {
                required: true,
              })}
            />
            <Row noGutters key={index} className="align-middle article">
              <Col xs="12" sm="1" className="pl-2 my-auto">
                <p>{price.article.code}</p>
              </Col>
              <Col xs="12" sm="5" className="my-auto">
                <Tippy content={price.article.name}><p>{price.article.name}</p></Tippy>
              </Col>
              <Col xs="12" sm="1" className="my-auto">
                <p>{price.article.quantityB}</p>
              </Col>
              <Col xs="12" sm="1" className="my-auto">
                <p>{price.article.quantityC}</p>
              </Col>
              <Col xs="12" sm="1" className="my-auto">
                <p>{price.article.unit}</p>
              </Col>
              <Col xs="12" sm="1">
                <div className="mr-sm-1 mx-sm-1 form-group my-1">
                  <CurrencyInput
                    name={`prices.${index}.priceA`}
                    autoComplete="off"
                    placeholder="En €"
                    className={`form-control ${errors.prices?.[index]?.priceA ? 'is-invalid' : ''}`}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    defaultValue={price.priceA ? price.priceA / 100 : ''}
                    {...register(`prices.${index}.priceA`, {
                      required: false, onChange: (e) => handlePriceChange(index, e.target.value),
                      validate: value => !unitIsBudget || validateBudgetPrice(value) || 'Le prix doit être entre 0 et 1 pour les unités € (Budget).'
                    })}
                  />
                  {errors.prices?.[index]?.priceA && (
                    <div className="invalid-feedback">
                      {errors.prices[index].priceA.message}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs="12" sm="1">
                <div className="mr-sm-1 mx-sm-1 form-group my-1">
                  <CurrencyInput
                    name={`prices.${index}.priceB`}
                    autoComplete="off"
                    placeholder="En €"
                    defaultValue={price.priceB ? price.priceB / 100 : ''}
                    className={`form-control ${errors.prices?.[index]?.priceB ? 'is-invalid' : ''}`}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    disabled={unitIsForfait}
                    {...register(`prices.${index}.priceB`, {
                      required: false,
                      validate: value => !unitIsBudget || validateBudgetPrice(value) || 'Le prix doit être entre 0 et 1 pour les unités € (Budget).'
                    })}
                  />
                  {errors.prices?.[index]?.priceB && (
                    <div className="invalid-feedback">
                      {errors.prices[index].priceB.message}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs="12" sm="1">
                <div className="mr-sm-1 mx-sm-1 form-group my-1">
                  <CurrencyInput
                    name={`prices.${index}.priceC`}
                    autoComplete="off"
                    placeholder="En €"
                    className={`form-control ${errors.prices?.[index]?.priceC ? 'is-invalid' : ''}`}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    disabled={unitIsForfait}
                    defaultValue={price.priceC ? price.priceC / 100 : ''}
                    {...register(`prices.${index}.priceC`, {
                      required: false,
                      validate: value => !unitIsBudget || validateBudgetPrice(value) || 'Le prix doit être entre 0 et 1 pour les unités € (Budget).'

                    })}
                  />
                  {errors.prices?.[index]?.priceC && (
                    <div className="invalid-feedback">
                      {errors.prices[index].priceC.message}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <hr />
          </span>
        );
      })
      }
      {viewMode !== true && <button type="submit" className="btn-green mt-3" >
        Sauvegarder
      </button>}
    </form>
  );

};

Step10Alt.propTypes = {
};

Step10Alt.defaultProps = {

};

export default Step10Alt;
