import React from "react";
import './GlobalLoader.css';
import LoadingSvg from 'assets/img/loader.svg';

const GlobalLoader = (props) => {

    const {
      message
    } = props

    return(
        <div className="preloader-global">
            <div className="content-preloader-global">
                <img src={LoadingSvg} alt="Loader" /><br/>
                {message}
            </div>
        </div>
    )
}

export default GlobalLoader
