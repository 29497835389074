import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import Banner from "views/universal/Banner/Banner";
import Download from "./Download/Download";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import { storeOperations } from "state/ducks/store";

const Downloads = (props) => {
  const dispatch = useDispatch();

  const documents = useSelector((state) => state.store.documents, shallowEqual);

  useEffect(() => {
    if (
      props?.match?.params?.type &&
      props.match.params.type !== documents.type &&
      !documents.isLoading
    ) {
      if (props.match.params.type === "libre") {
        if (documents.type !== "free") {
          dispatch(storeOperations.getDocuments("free"));
        }
      }
      if (props.match.params.type === "formalise") {
        if (documents.type !== "formalized") {
          dispatch(storeOperations.getDocuments("formalized"));
        }
      }
    }
  }, [dispatch, props, documents]);

  return (
    <Layout>
      {documents.isLoading ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <>
          <Banner
            titleBanner="Documentations"
            desc={
              "Il est rappelé que lorsque l'acheteur commande, il devient l'unique pouvoir adjudicateur responsable du marché vis à vis de ses prestataires. Par conséquent, il convient de prendre connaissance des pièces écrites en vigueur avant d'engager des commandes."
            }
          />

          {documents.data.length > 0 &&
            documents.data.map((document, i) => {
              return (
                <Download
                  title={document.name}
                  subTitle=""
                  mediaName={document.image.filePath}
                  mediaUrl={document.image.contentUrl}
                  key={i}
                />
              );
            })}

        </>
      )}
    </Layout>
  );
};

Downloads.propTypes = {};

Downloads.defaultProps = {};

export default Downloads;
