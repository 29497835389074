import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import Paper from "@material-ui/core/Paper";
import { FormGroup, Row, Col, Input, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sessionOperations } from "state/ducks/session";

const Dev = (props) => {
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session, shallowEqual);

  const departments = useSelector(
    (state) => state.store.departments,
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: session.user !== null ? session.user.name : "",
      organism: session.user !== null ? session.user.organism : "collectivity",
      firstname: session.user !== null ? session.user.firstname : "",
      lastname: session.user !== null ? session.user.lastname : "",
      // email: session.user !== null ? session.user.email: '',
      phone: session.user !== null ? session.user.phone : "",
      address: {
        // id: session.user !== null ? `/api/addresses/${session.user.address.id}`: '',
        street: session.user !== null ? session.user.address.street : "",
        postal: session.user !== null ? session.user.address.postal : "",
        city: session.user !== null ? session.user.address.city : "",
        department:
          session.user !== null ? session.user.address.department : "",
      },
    },
    validationSchema: Yup.object({
      // email: Yup.string().email('Merci de renseigner votre mail').required('Ce champ est requis'),
      name: Yup.string().required("Ce champ est requis"),
      organism: Yup.string().required("Ce champ est requis"),
      firstname: Yup.string().required("Ce champ est requis"),
      lastname: Yup.string().required("Ce champ est requis"),
      phone: Yup.string().required("Ce champ est requis"),
      address: Yup.object({
        city: Yup.string().required("Ce champ est requis"),
        postal: Yup.string().required("Ce champ est requis"),
        department: Yup.string().required("Ce champ est requis"),
        street: Yup.string().required("Ce champ est requis"),
      }),
    }),
    onSubmit: (values) => {
      values.phone = values.phone.toString();
      dispatch(sessionOperations.registerSupplier(values));
    },
  });

  return (
    <Layout>
      <div style={{ padding: "0px" }}>
        <Paper elevation={1} style={{ marginBottom: "15px" }}>
          <div style={{ padding: "30px" }}>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <h2 style={{ width: "50%", float: "left" }}>
                  {session?.user?.name ? session.user.name : null}
                </h2>
                <a
                  style={{
                    color: "#DB7124",
                    textDecoration: "underline",
                    float: "right",
                    width: "50%",
                    textAlign: "right",
                  }}
                  href="mailto:contact@centralis.site?subject=Supression de compte"
                >
                  Demande de suppression du compte
                </a>
                <Label style={{ display: "block", paddingTop: "50px" }}>
                  Organisme acheteur
                </Label>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        name="name"
                        id="name"
                        placeholder="Nom de l'organisme*"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="input-error">{formik.errors.name}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        type="select"
                        name="organism"
                        id="organism"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.organism}
                      >
                        <option value={"collectivity"}>Collectivité</option>
                        <option value={"public_establishment"}>
                          Etablissement public
                        </option>
                        <option value={"epl"}>EPL</option>
                        <option value={"public_office"}>Office public</option>
                        <option value={"state"}>Etat</option>
                        <option value={"other"}>Autre</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Label style={{ display: "block", marginTop: "20px" }}>
                  Personne référente
                </Label>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        name="lastname"
                        id="lastname"
                        placeholder="Nom*"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastname}
                      />
                      {formik.touched.lastname && formik.errors.lastname ? (
                        <div className="input-error">
                          {formik.errors.lastname}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        name="firstname"
                        id="firstname"
                        placeholder="Prénom*"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstname}
                      />
                      {formik.touched.firstname && formik.errors.firstname ? (
                        <div className="input-error">
                          {formik.errors.firstname}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Label style={{ display: "block", marginTop: "20px" }}>
                  Adresse*
                </Label>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        name="address.street"
                        id="street"
                        placeholder="N et Rue"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address.street}
                      />
                      {formik.touched.address &&
                      formik.touched.address.street &&
                      formik.errors.address &&
                      formik.errors.address.street ? (
                        <div className="input-error">
                          {formik.errors.address.street}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        type="select"
                        name="address.department"
                        id="department"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address.department}
                      >
                        {departments.data.map((department, index) => (
                          <option value={department.code} key={index}>
                            {department.code} - {department.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        name="address.postal"
                        id="postal"
                        placeholder="Code postal*"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address.postal}
                      />
                      {formik.touched.address &&
                      formik.touched.address.postal &&
                      formik.errors.address &&
                      formik.errors.address.postal ? (
                        <div className="input-error">
                          {formik.errors.address.postal}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        name="address.city"
                        id="city"
                        placeholder="Ville*"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address.city}
                      />
                      {formik.touched.address &&
                      formik.touched.address.city &&
                      formik.errors.address &&
                      formik.errors.address.city ? (
                        <div className="input-error">
                          {formik.errors.address.city}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>

                <Label style={{ display: "block", marginTop: "20px" }}>
                  Contact*
                </Label>
                <Row>
                  <Col md="6">
                    {session?.user?.email ? session.user.email : ""}
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Input
                        name="phone"
                        id="phone"
                        placeholder="Téléphone*"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="input-error">{formik.errors.phone}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <button
                style={{ marginTop: "30px", marginLeft: "70%", width: "30%" }}
                type="submit"
                variant="contained"
                className="btn-green"
              >
                Créer ou Mettre à jour le profil
              </button>
            </form>
          </div>
        </Paper>
      </div>
    </Layout>
  );
};

Dev.propTypes = {};

Dev.defaultProps = {};

export default Dev;
