import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom'
import { Container } from "reactstrap";
import Layout from "views/universal/Layout/Layout";
import Banner from "views/universal/Banner/Banner";
import { storeOperations } from "state/ducks/store";

const Page = (props) => {

    const location = useLocation();
    const dispatch = useDispatch();

    const title = (location.pathname === '/conditions-generales') ? "Conditions générales" : (location.pathname === '/faq') ? "FAQ" : (location.pathname === '/mentions-legales') ? "Mentions légales" : (location.pathname === '/acheteurs') ? "Acheteurs" : "";

    const pages = useSelector(
      (state) => state.store.pages,
      shallowEqual
    )

    useEffect(() => {
      if (!pages.init) {
        dispatch(storeOperations.getPages());
      }
    }, [dispatch, pages]);

    const content = pages.data.filter((page) => page.name === title);
    const description = content[0] !== undefined ? content[0].description : '';

    return (
      <Layout>
        <div className={"main"}>
          <Banner
              titleBanner={title}
              desc={null}
          />
          <Container style={{background: "white", marginTop: "40px", padding: "20px"}}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Container>
        </div>
      </Layout>
    );

}

Page.propTypes = {};

Page.defaultProps = {};

export default Page;
