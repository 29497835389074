import React from "react";
import iconDl from "assets/img/generate-pdf-svgrepo-com.svg";

const Step12Phase2 = (props) => {
  const { accepted, handleResetForm, pdf } = props;

  return (
    <div className="card p-5">
      {accepted ? (
        <div className="text-center">
          <p className="mb-5 text-center">
            Votre candidature est complète. Elle a été validée par
            l'administrateur.
          </p>
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            <button className="btn-green min">
              <img src={iconDl} alt="icon search filter" className="svg-size" />
              <br />
              Télécharger ou imprimer
              <br /> une copie PDF
            </button>
          </a>
          <br />
          <button className="btn-white min mt-5" onClick={handleResetForm}>
            Soumettre une nouvelle demande
            <br />
          </button>
        </div>
      ) : (
        <div className="text-center">
          <p className="mb-5 text-center">
            Votre candidature est complète. Elle reste éditable tant qu'elle n'a
            pas été validée ou refusée par l'administrateur.
          </p>
        </div>
      )}
    </div>
  );
};

Step12Phase2.propTypes = {};

Step12Phase2.defaultProps = {};

export default Step12Phase2;
