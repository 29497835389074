import React from "react";
import { useDispatch } from "react-redux";
import Step3 from "./Step3"
import { Formik } from 'formik';
import * as Yup from 'yup';
import { formSupplierOperations } from "state/ducks/formSupplier";
import {SUPPORTED_FORMATS_IMAGES, SUPPORTED_FORMATS_IMAGES_MESSAGE, SUPPORTED_FORMATS_DOCUMENTS, SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, FILE_SIZE }from "constants/constants";


// Présentation du candidat
const Step3Container = (props) => {

  const {
    formRef,
    initialValues
  } = props

  const dispatch = useDispatch();

  //eslint-disable-next-line
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

  // Schéma de validation front
  const validationSchema = Yup.object().shape({
    typeCandidate: Yup.string().required('Ce champ est requis'),
    typeBusinessGroup: Yup.string().nullable(true)
    .when('typeCandidate', {
      is: 'businessGroup',
      then: Yup.string().required('Ce champ est requis'),
    }),
    mandatarySolidarity: Yup.string().nullable(true)
    .when('typeBusinessGroup', {
      is: 'conjoint',
      then: Yup.string().required('Ce champ est requis'),
    }),
    contractors: Yup.array().of(
      Yup.object().shape({
        organization: Yup.string().nullable().required('Ce champ est requis'),
        firstname: Yup.string().nullable().required('Ce champ est requis'),
        lastname: Yup.string().nullable().required('Ce champ est requis'),
        function: Yup.string().nullable().required('Ce champ est requis'),
        typeOrganization: Yup.string().nullable().required('Ce champ est requis'),
        capital: Yup.string().nullable().required('Ce champ est requis'),
        siret: Yup.string().nullable().required('Ce champ est requis'),
        rcs: Yup.string().nullable().required('Ce champ est requis'),
        addressNumber: Yup.string().nullable().required('Ce champ est requis'),
        addressStreet: Yup.string().nullable().required('Ce champ est requis'),
        addressPostalCode: Yup.string().nullable().required('Ce champ est requis'),
        addressCity: Yup.string().nullable().required('Ce champ est requis'),
        phone: Yup.string().nullable().matches(phoneRegExp, 'Le numéro de téléphone est invalide').required('Ce champ est requis'),
        email: Yup.string().nullable().email('Cet email est invalide.').required('Ce champ est requis'),
        logo: Yup.string().nullable(),
        logoUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_IMAGES_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_IMAGES.includes(value?.type)) ).when('logo', {
            is: null,
            then: Yup.mixed().nullable()
        }),
        fiscalRegularityText: Yup.string().nullable().when('fiscalRegularityFile', {
            is: null,
            then: Yup.string().nullable().when('fiscalRegularityUpload', {
              is: null,
              then: Yup.string().nullable().required('Ce champ est requis si vous ne renseignez pas de pièce jointe.'),
            })
        }),
        fiscalRegularityFile: Yup.string().nullable(),
        fiscalRegularityFileUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) ).when('fiscalRegularityFile', {
            is: null,
            then: Yup.mixed().nullable(),
        }),
        companyInsuranceName: Yup.string().nullable().required('Ce champ est requis'),
        insurancePolicyNumber: Yup.string().nullable().required('Ce champ est requis'),
        companyInsuranceFile: Yup.string().nullable(),
        companyInsuranceFileUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) ).when('companyInsuranceFile', {
            is: null,
            then: Yup.mixed().nullable(),
        }),
        companyInsuranceText: Yup.string().nullable().when('companyInsuranceFile', {
            is: null,
            then: Yup.string().nullable().when('companyInsuranceFileUpload', {
              is: null,
              then: Yup.string().nullable().required('Ce champ est requis si vous ne renseignez pas de pièce jointe.'),
            })
        }),
        is10insuranceCertificate: Yup.bool().nullable().oneOf([true], "Ce champ est requis"),
        isLegalRedress: Yup.bool().nullable(),
        legalRedressFile: Yup.string().nullable(),
        legalRedressFileUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) ).when('legalRedressFile', {
            is: null,
            then: Yup.mixed().nullable(),
        }),
        legalRedressText: Yup.string().nullable().when('isLegalRedress', {
          is: true,
          then: Yup.string().nullable().when('legalRedressFile', {
            is: null,
            then: Yup.string().nullable().when('legalRedressFileUpload', {
              is: null,
              then: Yup.string().nullable().required('Ce champ est requis si vous ne renseignez pas de pièce jointe.'),
            })
          })
        }),
        isSwornStatement: Yup.bool().nullable().oneOf([true], "Ce champ est requis"),
        authorizationMandataryFile: Yup.string().nullable(),
        authorizationMandataryFileUpload: Yup.mixed().nullable().test('fileSize', "Le fichier ne doit pas dépasser 8mo", value => value === null || value?.size <= FILE_SIZE).test('fileType', SUPPORTED_FORMATS_DOCUMENTS_MESSAGE, value => ( value === null || SUPPORTED_FORMATS_DOCUMENTS.includes(value?.type)) ).when('authorizationMandataryFile', {
            is: null,
            then: Yup.mixed().nullable(),
        }),
        authorizationMandataryText: Yup.string().nullable().when('typeCandidate', {
          is: 'businessGroup',
          then: Yup.string().nullable().when('authorizationMandataryFile', {
            is: null,
            then: Yup.string().nullable().when('authorizationMandataryFileUpload', {
              is: null,
              then: Yup.string().nullable().required('Ce champ est requis si vous ne renseignez pas de pièce jointe.'),
            })
          })
        }),
      })).min(1, "Merci d'ajouter un contractant").when('typeCandidate', {
          is: 'businessGroup',
          then: Yup.array().min(2, "Merci d'ajouter un contractant"),
        }),
  })

  return (
    <>
    <Formik
       initialValues={initialValues?.submittedInputs?.step3 ? initialValues.submittedInputs.step3 : initialValues.step3}
       validationSchema={validationSchema}
       onSubmit={values => {
         // 05/23 - Stock les valeurs des inputs en cas de pb (unicité des emails)
         dispatch(
           formSupplierOperations.saveSubmittedInputs({ step3: values })
         );
         const submitReponse = dispatch(
           formSupplierOperations.setStep3(values, initialValues.id)
         );
         submitReponse.then((res) => {
           dispatch(formSupplierOperations.saveSubmittedInputs({}));
         });
       }}
     >
       {({ errors, touched, values, setFieldValue }) => (
         <>
          <Step3 formRef={formRef} values={values} setFieldValue={setFieldValue} touched={touched} errors={errors} initialValues={initialValues}/>
         </>
       )}
     </Formik>
     </>
  );

};

Step3Container.propTypes = {
};

Step3Container.defaultProps = {

};

export default Step3Container;
