import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingSvg from "assets/img/icon-preloader-connect.svg";
import { sessionOperations } from "state/ducks/session";
import { modalsOperations } from "state/ducks/modal";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";

const AddFreeArticle = (props) => {
  const { departments, session, categories, article } = props;

  const [departementsChoosen, setDepartementsChoosen] = useState([]);
  const [init, setInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let dptOptions = [];
    if (article && article.id && article.departments && init === false) {
      for (var i = 0; i < article.departments.length; i++) {
        dptOptions[i] = {
          value: `/api/departments/${article.departments[i].id}`,
          label: `${article.departments[i].name} - ${article.departments[i].code}`,
        };
      }
      setDepartementsChoosen(dptOptions);
      setInit(true);
    } else if (session?.user?.departments && init === false) {
      for (var a = 0; a < session.user.departments.length; a++) {
        dptOptions[a] = {
          value: `/api/departments/${session.user.departments[a].id}`,
          label: `${session.user.departments[a].name} - ${session.user.departments[a].code}`,
        };
      }
      setDepartementsChoosen(dptOptions);
      setInit(true);
    }
  }, [article, departementsChoosen, init, session]);

  const formik = useFormik({
    initialValues: {
      name: article && article.name ? article.name : "",
      description: article && article.description ? article.description : "",
      unit: article && article.unit ? article.unit : "",
      priceA: article && article.priceA ? article.priceA / 100 : 0,
      priceB: article && article.priceB ? article.priceB / 100 : "",
      priceC: article && article.priceC ? article.priceC / 100 : "",
      quantityB: article && article.quantityB ? article.quantityB : "",
      quantityC: article && article.quantityC ? article.quantityC : "",
      category:
        article && article.category && article.category.id
          ? `/api/categories/${article.category.id}`
          : session?.user?.category
          ? `/api/categories/${session.user.category.id}`
          : "",
    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string().required("Ce champ est requis"),
        description: Yup.string().required("Ce champ est requis"),
        category: Yup.string().required("Ce champ est requis"),
        unit: Yup.string().required("Ce champ est requis"),
        priceA: Yup.number()
          .required("Ce champ est requis")
          .positive("Un prix ne peut pas être négatif"),
        quantityB: Yup.number()
          .nullable()
          .positive("Une quantité ne peut pas être négative")
          .when("priceB", (other, schema) =>
            other > 0
              ? schema.required(
                  "Ce champ est requis si vous avez indiqué un prix"
                )
              : schema
          ),
        priceB: Yup.number()
          .nullable()
          .positive("Un prix ne peut pas être négatif")
          .lessThan(
            Yup.ref("priceA"),
            "Le palier 2 doit être inférieur au prix initial"
          )
          .when("quantityB", (other, schema) =>
            other > 0
              ? schema.required(
                  "Ce champ est requis si vous indiquez une quantité"
                )
              : schema
          ),
        quantityC: Yup.number()
          .nullable()
          .positive("Une quantité ne peut pas être négative")
          .moreThan(
            Yup.ref("quantityB"),
            "Ce palier doit être supérieur à la quantité du palier 2"
          )
          .when("priceC", (other, schema) =>
            other > 0
              ? schema.required(
                  "Ce champ est requis si vous avez indiqué un prix"
                )
              : schema
          ),
        priceC: Yup.number()
          .nullable()
          .positive("Un prix ne peut pas être négatif")
          .lessThan(
            Yup.ref("priceB"),
            "Le palier 3 doit être inférieur au palier 2"
          )
          .when("quantityC", (other, schema) =>
            other > 0
              ? schema.required(
                  "Ce champ est requis si vous indiquez une quantité"
                )
              : schema
          ),
      },
      [
        ["priceB", "quantityB"],
        ["quantityB", "priceB"],
        ["priceC", "quantityC"],
        ["quantityC", "priceC"],
      ]
    ),
    onSubmit: (values) => {
      let departmentsURI = [];
      for (var i = 0; i < departementsChoosen.length; i++) {
        departmentsURI[i] = departementsChoosen[i].value;
      }
      // MAJ
      if (article && article.id) {
        dispatch(
          sessionOperations.updateFreeArticle(article.id, {
            ...values,
            departments: departmentsURI,
            priceA: values.priceA * 100,
            priceB: values.priceB !== null ? values.priceB * 100 : null,
            priceC: values.priceC !== null ? values.priceC * 100 : null,
            quantityB: values.quantityB === "" ? null : values.quantityB,
            quantityC: values.quantityC === "" ? null : values.quantityC,
          })
        ).then(
          (result) => {
            dispatch(modalsOperations.getModalStatus(null, false, null));
          },
          (error) => {}
        );
      }
      // AJOUT
      else {
        dispatch(
          sessionOperations.addFreeArticle({
            ...values,
            departments: departmentsURI,
            priceA: values.priceA * 100,
            priceB: values.priceB !== null ? values.priceB * 100 : null,
            priceC: values.priceC !== null ? values.priceC * 100 : null,
            quantityB: values.quantityB === "" ? null : values.quantityB,
            quantityC: values.quantityC === "" ? null : values.quantityC,
          })
        ).then(
          (result) => {
            dispatch(modalsOperations.getModalStatus(null, false, null));
          },
          (error) => {}
        );
      }
    },
  });

  const animatedComponents = makeAnimated();

  let departmentOptions = [];
  if (departments.data) {
    for (var i = 0; i < departments.data.length; i++) {
      departmentOptions[i] = {
        value: `/api/departments/${departments.data[i].id}`,
        label: `${departments.data[i].name} - ${departments.data[i].code}`,
      };
    }
  }
  const departmentsOnChange = (values) => {
    let dpts = [];
    for (var i = 0; i < values.length; i++) {
      dpts.push(values[i]);
    }
    setDepartementsChoosen(dpts);
  };

  return (
    <Row>
      {departments.data.length <= 0 || categories.data.length <= 0 ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <Col md="12">
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="name">Nom</Label>
              <Input
                name="name"
                id="name"
                placeholder="Nom"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="input-error">{formik.errors.name}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                name="description"
                id="description"
                placeholder="Description"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="input-error">{formik.errors.description}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="categories">Catégorie</Label>
              <Input
                type="select"
                name="category"
                id="categories"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
              >
                <option>Choisir une catégorie</option>
                {categories &&
                  categories.data &&
                  categories.data.map((category, index) => (
                    <option
                      value={`/api/categories/${category.id}`}
                      key={index}
                    >
                      {category.name}
                    </option>
                  ))}
              </Input>
              {formik.touched.category && formik.errors.category ? (
                <div className="input-error">{formik.errors.category}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="departements">Départements</Label>
              <Select
                id="departements"
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={departementsChoosen}
                value={departementsChoosen}
                isMulti
                options={departmentOptions}
                onChange={departmentsOnChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="unit">Unité</Label>
              <Input
                name="unit"
                id="unit"
                placeholder="Unité"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.unit}
              />
              {formik.touched.unit && formik.errors.unit ? (
                <div className="input-error">{formik.errors.unit}</div>
              ) : null}
            </FormGroup>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label for="priceA">Prix initial</Label>
                  <Input
                    name="priceA"
                    id="priceA"
                    placeholder="Prix"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.priceA}
                  />
                  {formik.touched.priceA && formik.errors.priceA ? (
                    <div className="input-error">{formik.errors.priceA}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="quantityB">
                    Palier 2 (non obligatoire, laisser vide si non nécessaire)
                  </Label>
                  <Input
                    name="quantityB"
                    id="quantityB"
                    placeholder="A partir de..."
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantityB}
                  />
                  {formik.touched.quantityB && formik.errors.quantityB ? (
                    <div className="input-error">{formik.errors.quantityB}</div>
                  ) : null}
                  <br />
                  <Input
                    name="priceB"
                    id="priceB"
                    placeholder="Prix"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.priceB}
                  />
                  {formik.touched.priceB && formik.errors.priceB ? (
                    <div className="input-error">{formik.errors.priceB}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="quantityC">
                    Palier 3 (non obligatoire, laisser vide si non nécessaire)
                  </Label>
                  <Input
                    name="quantityC"
                    id="quantityC"
                    placeholder="A partir de..."
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quantityC}
                  />
                  {formik.touched.quantityC && formik.errors.quantityC ? (
                    <div className="input-error">{formik.errors.quantityC}</div>
                  ) : null}
                  <br />
                  <Input
                    name="priceC"
                    id="priceC"
                    placeholder="Prix"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.priceC}
                  />
                  {formik.touched.priceC && formik.errors.priceC ? (
                    <div className="input-error">{formik.errors.priceC}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              id="btn-connect-modal"
              type="submit"
              className="btn-green"
              disabled={session.isLoading}
            >
              {article && article.id ? "Mettre à jour" : "Créer"}
              <div
                style={
                  session.isLoading
                    ? { marginLeft: "0", opacity: "1" }
                    : { marginLeft: "0", opacity: "0" }
                }
                className="preloader-connect-user"
              >
                <img src={LoadingSvg} alt="Loader" />
              </div>
            </Button>
          </Form>
        </Col>
      )}
    </Row>
  );
};

export default AddFreeArticle;
