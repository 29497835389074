import React from "react";
import { Row, Col, Table } from "reactstrap";
import PropTypes from "prop-types";
import { centimesToCurrency } from "utils/Utils";

/**
 * Affiche les variations de prix d'un produit dans un tableau en fonction du rang et du palier
 */
const ArticleVariations = (props) => {
  const { priceA, unit, quantityB, quantityC, priceB, priceC } = props;

  return (
    <Row>
      <Col md="12">
        <Table>
          <thead>
            <tr>
              <th>Palier ({unit})</th>
              {quantityB && quantityC ? (
                <>
                  <th>1 à {quantityB}</th>
                  <th>
                    {quantityB} à {quantityC}
                  </th>
                  <th>> à {quantityC}</th>
                </>
              ) : (
                <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Prix</td>
              {quantityB && quantityC ? (
                <>
                  <td>{centimesToCurrency(priceA)}</td>
                  <td>{centimesToCurrency(priceB)}</td>
                  <td>{centimesToCurrency(priceC)}</td>
                </>
              ) : (
                <td>{centimesToCurrency(priceA)}</td>
              )}
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

ArticleVariations.propTypes = {
  priceA: PropTypes.number,
  unit: PropTypes.string,
  quantityB: PropTypes.number,
  quantityC: PropTypes.number,
  priceB: PropTypes.number,
  priceC: PropTypes.number,
};

ArticleVariations.defaultProps = {
  priceA: 0,
  unit: "",
  quantityB: null,
  quantityC: null,
  priceB: null,
  priceC: null,
};

export default ArticleVariations;
