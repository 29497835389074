import React from "react";
import { Row, Col} from "reactstrap";
import {useDispatch} from "react-redux";

import tuto_send_cart from "assets/img/tuto_send_cart.png";

const SendCartToCustomer = (props) => {
    const {cartId, cartAddress, cartCountArticles} = props;

    return (
        <Row>
            <Col md="12">
                <div>
                    <h2>Comment envoyer le panier à un client</h2>
                    <ol>
                        <li>Pour envoyer le panier à un client, vous devez lui communiquer par mail ou téléphone le numéro du panier <br/> (qui
                            apparaît à côté du bouton 'envoyer le panier au client').
                        </li>
                        {cartAddress && cartCountArticles ?
                            <li>Pour rappel, le numéro de votre panier est : <b>{cartId}</b></li> :
                            <li>Vous devez renseigner les différentes informations du panier. Et le panier doit avoir au moins 1 article pour pouvoir l'envoyer.</li>
                        }
                        <li>Du côté du client, dans l'onglet 'Récupérer un panier' du profil, celui-ci peut saisir le numéro du panier pour le récupérer.</li>
                        <img width="700px" src={tuto_send_cart} alt="image_tuto"/>
                        <li>Une fois que le client aura saisi le code du panier, celui-ci apparaîtra dans sa liste des paniers proposés.</li>
                    </ol>
                </div>
            </Col>
        </Row>
    );
};

export default SendCartToCustomer;
