import React from 'react';

// import styles
import './MiniLoader.css';
import Preloader from "assets/img/icon-preloader.svg";

const MiniLoader = (props) => {

    return(
      <div className="container">
          <div className="row">
              <div className="col-lg-12 text-center pt-4">
                  <div className="preloader">
                      <img src={Preloader} alt="preloader img"/>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default MiniLoader
