export const GET_CATEGORIES_LOT = "store/GET_CATEGORIES_LOT";
export const GET_CATEGORIES_LOT_COMPLETED =
  "store/GET_CATEGORIES_LOT_COMPLETED";
export const GET_CATEGORIES_LOT_FAILED = "store/GET_CATEGORIES_LOT_FAILED";

export const GET_LOTS = "store/GET_LOTS";
export const GET_LOTS_COMPLETED = "store/GET_LOTS_COMPLETED";
export const GET_LOTS_FAILED = "store/GET_LOTS_FAILED";

export const FILTERS_LOTS = "store/FILTERS_LOTS";

export const GET_DEPARTMENTS = "department/GET_DEPARTMENTS";
export const GET_DEPARTMENTS_COMPLETED = "department/GET_DEPARTMENTS_COMPLETED";
export const GET_DEPARTMENTS_FAILED = "department/GET_DEPARTMENTS_FAILED";

export const GET_SUPPLIERS = "store/GET_SUPPLIERS";
export const GET_SUPPLIERS_COMPLETED = "store/GET_SUPPLIERS_COMPLETED";
export const GET_SUPPLIERS_FAILED = "store/GET_SUPPLIERS_FAILED";

export const FILTERS_SUPPLIERS = "store/FILTERS_SUPPLIERS";
export const RESET_FILTERS_SUPPLIERS = "store/RESET_FILTERS_SUPPLIERS";

export const FILTERS_FREE_SUPPLIERS = "store/FILTERS_FREE_SUPPLIERS";
export const RESET_FILTERS_FREE_SUPPLIERS = "store/RESET_FILTERS_FREE_SUPPLIERS";

export const GET_CUSTOMERS = "store/GET_CUSTOMERS";
export const GET_CUSTOMERS_COMPLETED = "store/GET_CUSTOMERS_COMPLETED";
export const GET_CUSTOMERS_FAILED = "store/GET_CUSTOMERS_FAILED";

export const GET_DOCUMENTS = "department/GET_DOCUMENTS";
export const GET_DOCUMENTS_COMPLETED = "department/GET_DOCUMENTS_COMPLETED";
export const GET_DOCUMENTS_FAILED = "department/GET_DOCUMENTS_FAILED";

export const GET_PAGES = "store/GET_PAGES";
export const GET_PAGES_COMPLETED = "store/GET_PAGES_COMPLETED";
export const GET_PAGES_FAILED = "store/GET_PAGES_FAILED";

export const GET_CATEGORIES = "department/GET_CATEGORIES";
export const GET_CATEGORIES_COMPLETED = "department/GET_CATEGORIES_COMPLETED";
export const GET_CATEGORIES_FAILED = "department/GET_CATEGORIES_FAILED";

export const GET_FREE_ARTICLES = "store/GET_FREE_ARTICLES";
export const GET_FREE_ARTICLES_COMPLETED = "store/GET_FREE_ARTICLES_COMPLETED";
export const GET_FREE_ARTICLES_FAILED = "store/GET_FREE_ARTICLES_FAILED";

export const FILTERS_FREE_ARTICLES = "store/FILTERS_FREE_ARTICLES";

export const GET_FREE_ARTICLES_BY_SUPPLIER =
  "store/GET_FREE_ARTICLES_BY_SUPPLIER";
export const GET_FREE_ARTICLES_BY_SUPPLIER_COMPLETED =
  "store/GET_FREE_ARTICLES_BY_SUPPLIER_COMPLETED";
export const GET_FREE_ARTICLES_BY_SUPPLIER_FAILED =
  "store/GET_FREE_ARTICLES_BY_SUPPLIER_FAILED";

// Retourne les prestataires concernant des articles libres en fonction de la catégorie
export const GET_SUPPLIERS_FROM_FREE_ARTICLE =
  "store/GET_SUPPLIERS_FROM_FREE_ARTICLE";
export const GET_SUPPLIERS_FROM_FREE_ARTICLE_COMPLETED =
  "store/GET_SUPPLIERS_FROM_FREE_ARTICLE_COMPLETED";
export const GET_SUPPLIERS_FROM_FREE_ARTICLE_FAILED =
  "store/GET_SUPPLIERS_FROM_FREE_ARTICLE_FAILED";

// Permet de gérer les articles libres pour un fournisseur donnée
export const GET_FREE_ARTICLES_SUPPLIER = "store/GET_FREE_ARTICLES_SUPPLIER";
export const GET_FREE_ARTICLES_SUPPLIER_COMPLETED =
  "store/GET_FREE_ARTICLES_SUPPLIER_COMPLETED";
export const GET_FREE_ARTICLES_SUPPLIER_FAILED =
  "store/GET_FREE_ARTICLES_SUPPLIER_FAILED";

export const FILTERS_FREE_ARTICLES_BY_SUPPLIER =
  "store/FILTERS_FREE_ARTICLES_BY_SUPPLIER";
