import React from "react";
import { Field, ErrorMessage } from 'formik';
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError"

const Contractor = (props) => {

  const {
    values,
    index,
    setFieldValue
  } = props

  return (

      <fieldset className="form-group">
        <h5>
          {values.typeCandidate === "single" ?
            "Contractant UNIQUE" :
          (values.typeCandidate !== "single" && index === 0 ) ?
            "Contractant "+(index + 1)+" : MANDATAIRE du groupement" :
          "Contractant "+(index + 1)}
        </h5>

        <label>Organisme candidat*</label>
        <FieldWithError name={`contractors.${index}.organization`} placeholder={"Nom de la société*"}/>

        <label>Représentant légal*</label>
        <div className="form-inline">
          <FieldWithError name={`contractors.${index}.firstname`} placeholder={"Prénom"}/>
          <FieldWithError name={`contractors.${index}.lastname`} placeholder={"Nom"}/>
          <FieldWithError name={`contractors.${index}.function`} placeholder={"Fonction"}/>
        </div>

        <label>Forme juridique*</label>
        <div className="form-inline">
          <FieldWithError name={`contractors.${index}.typeOrganization`} placeholder={"Type d'organisme"}/>
          <FieldWithError name={`contractors.${index}.capital`} placeholder={"Capital en €"}/>
        </div>

        <label>Enregistrement*</label>
        <div className="form-inline">
          <FieldWithError name={`contractors.${index}.siret`} placeholder={"SIRET ou N° d’identification européen ou international ou propre au pays d’origine du candidat issu d’un répertoire figurant dans la liste des ICD."}/>
          <FieldWithError name={`contractors.${index}.rcs`} placeholder={"RCS"}/>
        </div>

        <label>Adresse du siège social*</label>
        <div className="form-inline">
          <FieldWithError name={`contractors.${index}.addressNumber`} placeholder={"N°"}/>
          <FieldWithError name={`contractors.${index}.addressStreet`} placeholder={"Rue"}/>
          <FieldWithError name={`contractors.${index}.addressPostalCode`} placeholder={"Code postal"}/>
          <FieldWithError name={`contractors.${index}.addressCity`} placeholder={"Ville"}/>
        </div>

        <label>Coordonnée*</label>
        <div className="form-inline">
          <FieldWithError name={`contractors.${index}.phone`} placeholder={"Téléphone"}/>
          <FieldWithError name={`contractors.${index}.email`} placeholder={"Mail général"}/>
        </div>

        <label>Logo de l'organisme (JPG ou PNG, taille max 8mo)</label>
        <div className="form-inline">
          {values?.contractors && values.contractors[index]?.logo ?
            <a href={values.contractors[index].logo} target="_blank" rel="noopener noreferrer" className="fileLink">Voir mon fichier chargé</a>
            :
            null
          }
          <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`contractors.${index}.logoUpload`} type="file" accept="image/*" onChange={(event) => {
            setFieldValue(`contractors.${index}.logoUpload`, event.currentTarget.files[0]);
          }} />
          <ErrorMessage name={`contractors.${index}.logoUpload`} component="div" className="error-red"/>
        </div>

        <label>Justificatif attestant de la régularité fiscale et sociale (JPG ou PDF, taille max 8mo)</label>
        <div className="form-inline">
          {values?.contractors[index]?.fiscalRegularityFile ?
            <a href={values.contractors[index].fiscalRegularityFile} target="_blank" rel="noopener noreferrer" className="fileLink">Voir mon fichier chargé</a> : null}
          <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`contractors.${index}.fiscalRegularityFileUpload`} type="file" onChange={(event) => {
            setFieldValue(`contractors.${index}.fiscalRegularityFileUpload`, event.currentTarget.files[0]);
            setFieldValue(`contractors.${index}.companyInsuranceFile`, '')
          }} />
          <ErrorMessage name={`contractors.${index}.fiscalRegularityFile`} component="div" className="error-red"/>
        </div>
        <FieldWithError as="textarea" name={`contractors.${index}.fiscalRegularityText`} placeholder={"Le cas échéant, adresse internet à laquelle les documents justificatifs et moyens de preuve sont accessibles directement et gratuitement. Renseignements nécessaires pour y accéder"}/>

        <label>Compagnie d’assurance RCP*</label>
        <div className="form-inline">
          <FieldWithError name={`contractors.${index}.companyInsuranceName`} placeholder={"Nom de la compagnie"}/>
          <FieldWithError name={`contractors.${index}.insurancePolicyNumber`} placeholder={"N° Police d’assurance"}/>
        </div>

        <label>Justificatif d’assurance RCP (JPG ou PDF, taille max 8mo)</label>
        <div className="form-inline">
          {values?.contractors[index]?.companyInsuranceFile ?
            <a href={values.contractors[index].companyInsuranceFile} target="_blank" rel="noopener noreferrer" className="fileLink">Voir mon fichier chargé</a> : null}
          <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`contractors.${index}.companyInsuranceFileUpload`} type="file" onChange={(event) => {
            setFieldValue(`contractors.${index}.companyInsuranceFileUpload`, event.currentTarget.files[0]);
            setFieldValue(`contractors.${index}.companyInsuranceFile`, '')
          }} />
          <ErrorMessage name={`contractors.${index}.companyInsuranceFileUpload`} component="div" className="error-red"/>
        </div>
        <FieldWithError as="textarea" name={`contractors.${index}.companyInsuranceText`} placeholder={"Le cas échéant, adresse internet à laquelle les documents justificatifs et moyens de preuve sont accessibles directement et gratuitement. Renseignements nécessaires pour y accéder"}/>

        <label>Attestation d’assurance décennale</label>
        <div className="form-check">
           <Field id={`is10insuranceCertificate.${index}`} type="checkbox" name={`contractors.${index}.is10insuranceCertificate`} className="form-check-input"/>
           <label className="checkbox-label" htmlFor={`is10insuranceCertificate.${index}`}>
            En cochant cette case, le candidat déclare qu’il a ou qu’il s’engage à avoir souscrit un contrat d’assurance le couvrant au regard de la responsabilité décennale (article L. 241-1 du code des assurances), avant le démarrage du contrat.
           </label>
          <ErrorMessage name={`contractors.${index}.is10insuranceCertificate`} component="div" className="error-red"/>
        </div>

        <label>Redressement judiciaire</label>
        <div className="form-check">
           <Field id={`isLegalRedress.${index}`} type="checkbox" name={`contractors.${index}.isLegalRedress`} className="form-check-input"/>
           <label className="checkbox-label" htmlFor={`isLegalRedress.${index}`}>
            L’organisme est en redressement judiciaire
           </label>
          <ErrorMessage name={`contractors.${index}.isLegalRedress`} component="div" className="error-red"/>
        </div>
        {values.contractors[index].isLegalRedress &&
          <>
            <label>Copie du jugement (JPG ou PDF, taille max 8mo)</label>
            <div className="form-inline">
              {values?.contractors[index]?.legalRedressFile ?
                <a href={values.contractors[index].legalRedressFile} target="_blank" rel="noopener noreferrer" className="fileLink">Voir mon fichier chargé</a> : null}
              <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`contractors.${index}.legalRedressFileUpload`} type="file" onChange={(event) => {
                setFieldValue(`contractors.${index}.legalRedressFileUpload`, event.currentTarget.files[0]);
                setFieldValue(`contractors.${index}.legalRedressFile`, '')
              }} />
              <ErrorMessage name={`contractors.${index}.legalRedressFileUpload`} component="div" className="error-red"/>
            </div>
            <FieldWithError as="textarea" name={`contractors.${index}.legalRedressText`} placeholder={"Le cas échéant, adresse internet à laquelle les documents justificatifs et moyens de preuve sont accessibles directement et gratuitement. Renseignements nécessaires pour y accéder"}/>
          </>
        }

        <label>Déclaration sur l'honneur</label>
        <div className="form-check">
           <Field id={`isSwornStatement.${index}`} type="checkbox" name={`contractors.${index}.isSwornStatement`} className="form-check-input"/>
           <label className="checkbox-label" htmlFor={`isSwornStatement.${index}`}>
            En cochant cette case, le candidat déclare sur l’honneur, ne pas entrer dans l’un des cas d’exclusion prévus aux articles L. 2141-1 à L. 2141-5 ou aux articles L. 2141-7 à L. 2141-10 du code de la commande publique (Lorsqu'un opérateur économique est, au cours de la procédure de passation d'un marché, placé dans l'un des cas d'exclusion mentionnés aux articles L. 2141-1 à L. 2141-5, aux articles L. 2141-7 à L. 2141-10 ou aux articles L. 2341-1 à L. 2341-3  du code de la commande publique, il informe sans délai l'acheteur de ce changement de situation) ;
           </label>
          <ErrorMessage name={`contractors.${index}.isSwornStatement`} component="div" className="error-red"/>
        </div>

        {(values.typeCandidate !== "single" && index === 0 ) &&
          <>
            <label>Habilitation du mandataire du groupement (JPG ou PDF, taille max 8mo)</label>
            <div className="form-inline">
              {values?.contractors[index]?.authorizationMandataryFile ?
                <a href={values.contractors[index].authorizationMandataryFile} target="_blank" rel="noopener noreferrer" className="fileLink">Voir mon fichier chargé</a> : null}
              <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`contractors.${index}.authorizationMandataryFileUpload`} type="file" onChange={(event) => {
                setFieldValue(`contractors.${index}.authorizationMandataryFileUpload`, event.currentTarget.files[0]);
                setFieldValue(`contractors.${index}.authorizationMandataryFile`, '')
              }} />
              <ErrorMessage name={`contractors.${index}.authorizationMandataryFileUpload`} component="div" className="error-red"/>
            </div>
            <FieldWithError as="textarea" name={`contractors.${index}.authorizationMandataryText`} placeholder={"Le cas échéant, adresse internet à laquelle les documents justificatifs et moyens de preuve sont accessibles directement et gratuitement. Renseignements nécessaires pour y accéder"}/>
          </>
        }

      </fieldset>
  );

};

Contractor.propTypes = {
};

Contractor.defaultProps = {

};

export default Contractor;
