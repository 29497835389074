import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { apiService, createLogger } from "./middlewares";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import * as reducers from "./ducks";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["session", "store", "notifications", "modal", "formSupplier", "supercustomer", "supersupplier", "general"],
  stateReconciler: autoMergeLevel2,
  timeout: null,
};

/**
 * Candidature
 * garde l'id de la candidature et l'étapex
 */
const formSupplierPersistConfig = {
  key: 'formSupplier',
  storage: storage,
  whitelist: ['stepInprogress', 'id']
}

const rootReducerBis = combineReducers({...reducers,
  formSupplier: persistReducer(formSupplierPersistConfig, reducers.formSupplier)
})

const persistedReducer = persistReducer(persistConfig, rootReducerBis);

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  storeEnhancers(
    applyMiddleware(thunkMiddleware, apiService, createLogger(true))
  )
);

export const persistor = persistStore(store);
