export const GET_CUSTOMER_ACCOUNTS = "supercustomer/GET_CUSTOMER_ACCOUNTS";
export const GET_CUSTOMER_ACCOUNTS_COMPLETED = "supercustomer/GET_CUSTOMER_ACCOUNTS_COMPLETED";
export const GET_CUSTOMER_ACCOUNTS_FAILED = "supercustomer/GET_CUSTOMER_ACCOUNTS_FAILED";

export const ADD_CUSTOMER_ACCOUNT = "supercustomer/ADD_CUSTOMER_ACCOUNT";
export const ADD_CUSTOMER_ACCOUNT_COMPLETED = "supercustomer/ADD_CUSTOMER_ACCOUNT_COMPLETED";
export const ADD_CUSTOMER_ACCOUNT_FAILED = "supercustomer/ADD_CUSTOMER_ACCOUNT_FAILED";

export const UPDATE_CUSTOMER_ACCOUNT = "supercustomer/UPDATE_CUSTOMER_ACCOUNT";
export const UPDATE_CUSTOMER_ACCOUNT_COMPLETED = "supercustomer/UPDATE_CUSTOMER_ACCOUNT_COMPLETED";
export const UPDATE_CUSTOMER_ACCOUNT_FAILED = "supercustomer/UPDATE_CUSTOMER_ACCOUNT_FAILED";

export const REMOVE_CUSTOMER_ACCOUNT = "supercustomer/REMOVE_CUSTOMER_ACCOUNT";
export const REMOVE_CUSTOMER_ACCOUNT_COMPLETED = "supercustomer/REMOVE_CUSTOMER_ACCOUNT_COMPLETED";
export const REMOVE_CUSTOMER_ACCOUNT_FAILED = "supercustomer/REMOVE_CUSTOMER_ACCOUNT_FAILED";

export const GET_CUSTOMER_CARTS = "supercustomer/GET_CUSTOMER_CARTS";
export const GET_CUSTOMER_CARTS_COMPLETED = "supercustomer/GET_CUSTOMER_CARTS_COMPLETED";
export const GET_CUSTOMER_CARTS_FAILED = "supercustomer/GET_CUSTOMER_CARTS_FAILED";
