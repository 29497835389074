import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Redirect, Link, useHistory  } from "react-router-dom";
import Layout from "views/universal/Layout/Layout";
import Banner from "./Banner/Banner";
import Actions from "./Actions/Actions";
import AddressForm from "./AddressForm/AddressForm";
import Suppliers from "./Suppliers/Suppliers";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import BlocInfos from "views/universal/BlocInfos/BlocInfos";
import IconPanier from "assets/img/icon-panier.svg";
import { sessionOperations } from "state/ducks/session";

const FreeCart = (props) => {

  // L'id du panier dans l'url
  const cartId = parseInt(props.match.params.id);

  const dispatch = useDispatch();

  /**
   * Gère la redirection s'il y a un problème avec le chargement du panier
   */
  const [redirect, setRedirect] = useState(false);

  const session = useSelector((state) => state.session, shallowEqual);

  const lastLoadedCart = useSelector(
    (state) => state.session.lastLoadedCart,
    shallowEqual
  );

  const activeCart = useSelector(
    (state) => state.session.activeFreeCart,
    shallowEqual
  );
  let cart = null;
  if (lastLoadedCart?.id && lastLoadedCart.id === cartId) {
    cart = lastLoadedCart;
  } else if (activeCart?.id && activeCart.id === cartId) {
    cart = activeCart;
  }

  /**
   * Si le panier est consulté en tant que admin (super vs gestionnaire)
   */
  let seeAsSuper = false;

  useEffect(() => {
    if (
      session.isLoading === false &&
      session.init === true &&
      !session.lastLoadedCart
    ) {
      dispatch(sessionOperations.loadCart(cartId, "free")).catch((error) => {
        setRedirect(true);
      });
    } else if (
      session.isLoading === false &&
      session.init === true &&
      session.lastLoadedCart.id !== cartId
    ) {
      dispatch(sessionOperations.loadCart(cartId, "free")).catch((error) => {
        setRedirect(true);
      });
    }
  }, [dispatch, session, cartId, setRedirect]);

  if (cart) {
    if (session.user.roles.includes("ROLE_SUPPLIER")) {
      if (
        !cart.supplier ||
        cart.status !== 5 ||
        cart.supplier.id !== session.user.id
      ) {
        return <Redirect to="/compte/fournisseur/paniers" />;
      }
    }
    if (session.user.roles.includes("ROLE_CUSTOMER")) {
      if (cart.status !== 10 && cart.status !== 1) {
        return <Redirect to="/compte/paniers" />;
      }
      if (session.user.roles.includes("ROLE_SUPER_CUSTOMER")) {
        if (
          session?.user?.accounts &&
          session.user.accounts.find(
            (account) => cart.customer.id === account.id
          ) !== undefined
        ) {
          seeAsSuper = true;
        }
      }
      if (seeAsSuper === false && cart.customer.id !== session.user.id) {
        return <Redirect to="/compte/paniers" />;
      }
    }
  }

  if (redirect) {
    if (session.user.roles.includes("ROLE_SUPPLIER")) {
      return <Redirect to="/compte/fournisseur/paniers" />;
    }
    return <Redirect to="/compte/paniers" />;
  }

  return (
    <Layout>
      {session.isLoading || !cart ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <div style={{ position: "relative" }}>
          <section className="p-section" style={{backgroundColor: '#8E8E8E'}}>
            <Container>

          {/*<Banner cart={cart} user={session.user} />*/}
            {/*<Actions*/}
            {/*  cart={cart}*/}
            {/*  isSupplier={session.user.roles.includes("ROLE_SUPPLIER")}*/}
            {/*  seeAsSuper={seeAsSuper}*/}
            {/*/>*/}
              <AddressForm cart={cart} seeAsSuper={seeAsSuper} user={session.user}  isSupplier={session.user.roles.includes("ROLE_SUPPLIER")} />
              <Row>
                <Col md="12">
                  <h4 style={{ marginBottom: "25px" }}>Articles</h4>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  {!cart.countArticles === null || cart.countArticles < 1 ? (
                    <BlocInfos
                      title="Vous n'avez aucun article présent dans votre panier"
                      icon={IconPanier}
                    />
                  ) : (
                    <Suppliers
                      isAuthenticated={session.isAuthenticated}
                      cart={cart}
                      seeAsSuper={seeAsSuper}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
    </Layout>
  );
};

FreeCart.propTypes = {};

FreeCart.defaultProps = {};

export default FreeCart;
