import React, {useEffect} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Layout from "views/universal/Layout/Layout";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import Banner from "views/universal/Banner/Banner";
import Filters from "./Filters/Filters";
import SuppliersList from "./SuppliersList/SuppliersList";
import { storeOperations } from "state/ducks/store";

const Suppliers = (props) => {
  const dispatch = useDispatch();

  let cartType = props.match.params.type;
  if (cartType === "formalise") {
    cartType = "formalized";
  }
  if (cartType === "libre") {
    cartType = "free";
  }

  const session = useSelector((state) => state.session, shallowEqual);

  const categoriesLot = useSelector(
    (state) => state.store.categoriesLot,
    shallowEqual
  );

  const departments = useSelector(
    (state) => state.store.departments,
    shallowEqual
  );

  const categories = useSelector(
    (state) => state.store.categories,
    shallowEqual
  );

  const suppliers = useSelector((state) => state.store.suppliers, shallowEqual);

  useEffect(() => {
    dispatch(storeOperations.getDocuments('formalized'));
  }, [dispatch]);
  const documents = useSelector((state) => state.store.documents.data, shallowEqual);

const isSupplier =
    session?.user?.roles && session.user.roles.includes("ROLE_SUPPLIER");

  if (isSupplier) {
    return (window.location.href = "/catalogue/libre");
  }

  const loadMore = () => {
    let limit  = 100;
    let offset = 0;

    if (cartType === "free") {
      offset = suppliers.freeFilters.offset !== undefined && suppliers.freeFilters.offset !== null ? suppliers.freeFilters.offset + limit : 0;
      dispatch(
        storeOperations.filtersFreeSuppliers(
          suppliers.freeFilters.company,
          suppliers.freeFilters.department,
          suppliers.freeFilters.rank,
          suppliers.freeFilters.category,
          cartType,
          suppliers.freeFilters.edition,
          limit,
          offset
        )
      );
      dispatch(
        storeOperations.getSuppliers(
          suppliers.freeFilters.company,
          suppliers.freeFilters.department,
          suppliers.freeFilters.rank,
          suppliers.freeFilters.category,
          cartType,
          suppliers.freeFilters.edition,
          limit,
          offset
        )
      )
    }
    else {
      limit  = suppliers.filters.limit;
      offset = suppliers.filters.offset !== undefined && suppliers.filters.offset !== null ? suppliers.filters.offset + limit : 0;
      dispatch(
        storeOperations.filtersSuppliers(
          suppliers.filters.company,
          suppliers.filters.department,
          suppliers.filters.rank,
          suppliers.filters.category,
          cartType,
          suppliers.filters.edition,
          limit,
          offset
        )
      );
      dispatch(
        storeOperations.getSuppliers(
          suppliers.filters.company,
          suppliers.filters.department,
          suppliers.filters.rank,
          suppliers.filters.category,
          cartType,
          suppliers.filters.edition,
          limit,
          offset
        )
      )
    }
  };

  return (
    <Layout>
      {(categoriesLot.isLoading || departments.isLoading) && (
        <GlobalLoader message={"Chargement en cours ..."} />
      )}
      <Banner
        titleBanner={"Les prestataires"}
        desc={
          "Avant de commander sur Centralis, nous conseillons aux acheteurs d’entrer en contact avec les prestataires pour évoquer leur projet et vérifier que les articles choisis et les quantités envisagées correspondent à leurs besoins."
        }
      />
      <Filters
        categoriesLot={categoriesLot}
        departments={departments}
        filters={cartType === 'free' ? suppliers.freeFilters : suppliers.filters}
        cartType={cartType}
        categories={categories}
      />

      <SuppliersList
        suppliers={cartType === 'free' ? suppliers.data.freeSuppliers : suppliers.data.suppliers}
        isLoading={suppliers.isLoading}
        isAuthenticated={
          session?.isAuthenticated ? session.isAuthenticated : false
        }
        isSupplier={isSupplier}
        cartType={cartType}
        loadMore={loadMore}
        documents={documents}
      />
    </Layout>
  );
};

Suppliers.propTypes = {};

Suppliers.defaultProps = {};

export default Suppliers;
