import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Collapse, Button, Input } from "reactstrap";
import { modalsOperations } from "state/ducks/modal";
import { sessionOperations } from "state/ducks/session";
import ArticleVariations from "./ArticleVariations";
import { centimesToCurrency } from "utils/Utils";

const Article = (props) => {
  const dispatch = useDispatch();

  const {
    departmentCode,
    lot,
    article,
    isAuthenticated,
    cartSelector,
    setDetailToggleArticleId,
    detailToggleArticleId,
    user,
  } = props;

  const userIsFormalized = user?.type ? user.type === "formalized" : null;
  const isSupplier =
    user?.roles && user.roles.includes("ROLE_SUPPLIER") ? true : false;

  // Permet de savoir si l'article et le lot sont deja present dans le panier
  const lotInCart = cartSelector.lots.find(function (item) {
    return item.codeLot.toString() === lot.code.toString();
  });

  const articleInCart = cartSelector.articles.find(function (item) {
    return (
      item.codeArticle.toString() === article.code.toString() &&
      item.codeLot.toString() === lot.code.toString()
    );
  });

  // Si c'est un fournisseur retourne le rang pour lequel il est classé sur cet article
  let rank = 1;
  let supplierInfoOnThisLot;
  if (user?.lotGeos) {
    supplierInfoOnThisLot = user?.lotGeos.find(
      (lotGeo) =>
        parseInt(lotGeo.lotCode) === parseInt(lot.code) &&
        parseInt(lotGeo.edition) === parseInt(lot.edition) &&
          parseInt(lotGeo.departmentCode) === parseInt(cartSelector.department)
    );
    if (supplierInfoOnThisLot) {
      rank = supplierInfoOnThisLot.rank;
    }
  }

  // Si c'est un panier qui vient d'une proposition, rend impossible sa modification
  let canEditCart = false;
  if (isSupplier) {
    if (cartSelector.status === 5) {
      canEditCart = true;
    } else {
      canEditCart = false;
    }
  } else {
    canEditCart = !cartSelector.fromSupplier;
  }

  // Retourne le prix en fonction de la quantité
  const calculatePrice = useCallback(
    (qty, article) => {
      let prices = article.prices;
      let quantityB = article.quantityB;
      let quantityC = article.quantityC;
      let quantity = parseInt(qty);

      // En fonction des paliers dépassés, on choisit le prix à appliquer
      if (prices && quantity > 0) {
        if (quantityB === 1 || quantityB === null) {
          return quantity * parseInt(prices[rank - 1][0]);
        } else if (quantityC === 1 || quantityB === null) {
          if (quantity < quantityB) {
            return quantity * parseInt(prices[rank - 1][0]);
          } else {
            return quantity * parseInt(prices[rank - 1][1]);
          }
        } else {
          if (quantity < quantityB) {
            return quantity * parseInt(prices[rank - 1][0]);
          } else if (quantity >= quantityB && quantity <= quantityC) {
            return quantity * parseInt(prices[rank - 1][1]);
          } else {
            return quantity * parseInt(prices[rank - 1][2]);
          }
        }
      }
      return 0;
    },
    [rank]
  );

  // Ajoute un article dans le panier
  const addArticleInCart = (lotInCart, cartId, isSupplier, rank) => {
    if (parseInt(quantity) > 0) {
      let data = {
        articleId: article.id,
        quantity: parseInt(quantity),
        price: parseInt(totalPrice),
      };
      if (lotInCart !== undefined) {
        data.orderLotId = lotInCart.orderLotId;
      } else {
        data.lotId = lot.id;
        data.cartId = cartId;
      }
      // Envoie le rang si c'est un fournisseur
      if (isSupplier) {
        data.supplierId = user.id;
        dispatch(
          sessionOperations.addArticle(data, cartId, "formalized", rank)
        );
      } else {
        dispatch(sessionOperations.addArticle(data, cartId, "formalized"));
      }
    }
  };

  const renderSwitchMode = (articleInCart) => {
    let mode = articleInCart !== undefined ? "panier" : "catalog";

    switch (mode) {
      case "catalog": {
        return isSupplier && supplierInfoOnThisLot === undefined ? (
          <span></span>
        ) : !isSupplier && !canEditCart ? (
          <span></span>
        ) : (
          <Button
            onClick={() =>
              cartSelector.id !== null
                ? addArticleInCart(lotInCart, cartSelector.id, isSupplier, rank)
                : dispatch(
                    modalsOperations.getModalStatus("panier_create", true, null)
                  )
            }
            style={{ marginRight: "10px" }}
            className="btn-white"
            disabled={quantity > 0 || cartSelector.id !== null ? false : true}
          >
            Ajouter au panier
          </Button>
        );
      }
      case "panier": {
        return (
          <Button
            onClick={(e) =>
              dispatch(
                sessionOperations.removeArticle(articleInCart.orderArticleId)
              )
            }
            style={{ marginRight: "10px" }}
            className="btn-grey"
          >
            Retirer du panier
          </Button>
        );
      }
      default:
        break;
    }
  };

  // Prix
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (articleInCart) {
      setQuantity(articleInCart.quantity);
      setTotalPrice(calculatePrice(articleInCart.quantity, article));
    }
  }, [articleInCart, article, calculatePrice]);

  // Toggle
  const [detailToggle, setDetailToggle] = useState(false);
  const updateToggle = (toggle, detailToggleArticleId) => {
    if (toggle) {
      setDetailToggleArticleId(detailToggleArticleId);
    } else {
      setDetailToggleArticleId(null);
    }
  };
  useEffect(() => {
    setDetailToggle(detailToggleArticleId === article.id);
  }, [detailToggleArticleId, article]);
  return (
    <div className="article-bloc">
      <Row>
        <Col md="1" style={{ width: "9%", paddingLeft: "10px" }}>
          <p>
            <b>
              {departmentCode} {lot.code} {article.code} {rank}
            </b>
          </p>
        </Col>
        <Col md="4">
          <p>
            <b>{article.name}</b>
          </p>
        </Col>
        <Col md="2">
          {article.prices && (
            <p>
              à partir de{" "}
              <b>
                {centimesToCurrency(article.prices[rank - 1][2])} /{" "}
                {article.unit}
              </b>
            </p>
          )}
        </Col>
        <Col md="1" style={{ width: "10%", paddingLeft: "10px" }}>
          {article.prices && (
            <Input
              onChange={(e) => {
                setQuantity(e.target.value);
                setTotalPrice(calculatePrice(e.target.value, article));
              }}
              type="number"
              min="0"
              max="1000000"
              value={quantity}
              disabled={
                articleInCart !== undefined && articleInCart.quantity > 0
                  ? true
                  : false
              }
            />
          )}
        </Col>
        <Col md="1">
          <div>
            {article.prices && (
              <span>
                Total HT : <br />
                <b>{centimesToCurrency(totalPrice)}</b>
              </span>
            )}
          </div>
        </Col>
        {cartSelector.id && !canEditCart ? (
          <Col md="3" style={{ marginTop: "15px" }}>
            Impossible de modifier une proposition de panier formalisé
          </Col>
        ) : (
          <Col md="3" style={{ marginTop: "15px" }}>
            {!isAuthenticated ? (
              <div className="text-right" style={{ marginRight: "15px" }}>
                <Button
                  className="btn-white"
                  onClick={() =>
                    dispatch(
                      modalsOperations.getModalStatus("login", true, null)
                    )
                  }
                >
                  Détails (Réservés aux acheteurs formalisés)
                </Button>
              </div>
            ) : userIsFormalized !== true ? (
              <div className="text-right" style={{ marginRight: "15px" }}>
                <Button className="btn-white">
                  Détails (Réservés aux acheteurs formalisés)
                </Button>
              </div>
            ) : isSupplier && supplierInfoOnThisLot === undefined ? (
              <div className="text-right" style={{ marginRight: "15px" }}>
                <Button className="btn-white">
                  Vous n'êtes pas sur cet article
                </Button>
              </div>
            ) : (
              <div className="text-right" style={{ marginRight: "15px" }}>
                {article.prices && renderSwitchMode(articleInCart)}
                <Button
                  className="btn-white"
                  onClick={() => updateToggle(!detailToggle, article.id)}
                >
                  Détails
                </Button>
              </div>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col md="12">
          <Collapse isOpen={detailToggle}>
            <div style={{ padding: "20px 10px" }}>
              <h4 style={{ fontSize: "20px", marginBottom: "20px" }}>
                <b style={{ color: "#DB7124" }}>{lot.code}</b> : {lot.name}
              </h4>
              <p style={{ marginBottom: "30px" }}>{article.description}</p>
              {article.prices ? (
                <div>
                  <div>
                    <p>Prix unitaire des entreprises:</p>
                  </div>
                  <ArticleVariations
                    quantityB={article.quantityB}
                    quantityC={article.quantityC}
                    prices={article.prices}
                    unit={article.unit}
                    supplierInfoOnThisLot={supplierInfoOnThisLot}
                  />
                </div>
              ) : (
                <div>
                  <div>
                    <p>Aucun prestataire</p>
                  </div>
                </div>
              )}
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

Article.propTypes = {};

Article.defaultProps = {};

export default Article;
