import * as types from "./types";

export const getCustomerAccounts = (customerId) => {
  return {
    type: types.GET_CUSTOMER_ACCOUNTS,
    meta: {
      async: true,
      path: `customers/${customerId}/accounts`,
      method: "GET",
      body: {}
    }
  }
}

export const addCustomerAccount = (data) => {
  return {
    type: types.ADD_CUSTOMER_ACCOUNT,
    meta: {
      async: true,
      path: `customers`,
      method: "POST",
      body: data
    }
  }
}

export const updateCustomerAccount = (customerId, data) => {
  return {
    type: types.UPDATE_CUSTOMER_ACCOUNT,
    meta: {
      async: true,
      path: `customers/${customerId}`,
      method: "PUT",
      body: data,
      customerId: customerId
    }
  }
}

export const removeCustomerAccount = (customerId) => {
  return {
    type: types.REMOVE_CUSTOMER_ACCOUNT,
    meta: {
      async: true,
      path: `customers/${customerId}`,
      method: "DELETE",
      customerId:customerId
    }
  }
}

export const getCustomerCarts = (customerId) => ({
  type: types.GET_CUSTOMER_CARTS,
  meta: {
    async: true,
    blocking: true,
    path: `supercustomer/customercarts/${customerId}`,
    method: "GET"
  }
});
