import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Container } from "reactstrap";
import Layout from "views/universal/Layout/Layout";
import GlobalLoader from "views/universal/Loader/GlobalLoader/GlobalLoader";
import Banner from "views/universal/Banner/Banner";
import Filters from "./Filters/Filters";
import Article from "./Article/Article";
import Rgpd from "views/universal/Rgpd/Rgpd";
import queryString from "query-string";
import { storeOperations } from "state/ducks/store";
import { sessionSelectors } from "state/ducks/session";

/**
 * Vu des articles libres d'un prestataire
 */
const FreeCatalogSupplier = (props) => {
  const dispatch = useDispatch();

  const paramUrl = queryString.parse(props.location.search);
  const supplierId = parseInt(props.match.params.supplierId);

  const [init, setInit] = useState(false);
  const [freeArticles, setFreeArticles] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [detailToggleArticleId, setDetailToggleArticleId] = useState(null);

  const session = useSelector((state) => state.session, shallowEqual);

  // Recupère le selector du panier libre
  const cart = useSelector((state) => {
    return sessionSelectors.activeFreeCartSelector(state);
  });

  const categories = useSelector(
    (state) => state.store.categories,
    shallowEqual
  );

  const departments = useSelector(
    (state) => state.store.departments,
    shallowEqual
  );

  const freeArticlesBySupplier = useSelector(
    (state) => state.store.freeArticlesBySupplier,
    shallowEqual
  );

  const isAuthenticated = session.isAuthenticated;
  const user = session.user;
  const isSupplier =
    user?.roles && user?.roles.includes("ROLE_SUPPLIER") ? true : false;

  let forbidAccessAsSupplier = false;
  if (supplierId && isSupplier) {
    if (parseInt(user.id) !== parseInt(supplierId) && !user?.admin) {
      forbidAccessAsSupplier = true;
    } else if (user?.admin?.id) {
      if (
        parseInt(user.id) !== parseInt(supplierId) &&
        parseInt(user.admin.id) !== parseInt(user.id)
      ) {
        forbidAccessAsSupplier = true;
      }
    }
  }

  let categoryId = paramUrl.category;
  let departmentId = paramUrl.department;
  let category;

  if (categoryId === undefined) {
    categoryId = "all";
  } else {
    category = categories.data.find((value) => {
      return parseInt(value.id) === parseInt(categoryId);
    });
  }

  if (cart?.department?.id) {
    departmentId = cart.department.id;
  }

  if (departmentId === undefined) {
    departmentId = "74"; // Paris
  }

  useEffect(() => {
    if (!init && supplierId) {
      dispatch(storeOperations.getFreeArticleSupplier(supplierId)).then(
        (result) => {
          if (result?.data?.supplier) {
            setSupplier(result.data.supplier);
          }
          if (result?.data?.freeArticles) {
            setFreeArticles(result.data.freeArticles);
          }
          setInit(true);
        }
      );
    }
  }, [dispatch, init, supplierId]);

  const descriptionBanner =
    "Attention, dans ce catalogue, il appartient à chaque acheteur de vérifier le profil des prestataires et de respecter les seuils ainsi que les règles de passation imposées par le Code de la commande publique. Les entreprises n'ont pas été mises en concurrence et se sont inscrites librement dans cet espace de référencement.";

  const freeArticlesFilter = freeArticles.filter((article) => {
    return (
      article.departments.filter(
        (d) =>
          (cart?.department &&
            cart.department.code.toString() === d.code.toString()) ||
          (!cart?.department &&
            (d.id.toString() === departmentId || departmentId === "all"))
      ).length > 0 &&
      (categoryId === "all" || parseInt(categoryId) === article.category.id)
    );
  });

  return (
    <Layout>
      {!init || freeArticlesBySupplier.isLoading ? (
        <GlobalLoader message={"Chargement en cours ..."} />
      ) : (
        <>
          {category !== "all" &&
          category !== undefined &&
          category?.image?.contentUrl ? (
            <Banner
              titleBanner={category.name}
              image={encodeURI(category.image.contentUrl)}
            />
          ) : (
            <Banner
              titleBanner="Catalogue de prestations issu d’un référencement libre"
              desc={descriptionBanner}
            />
          )}
          {forbidAccessAsSupplier ? (
            <section className="p-section">
              <Container>
                <div>Accès réservé aux acheteurs libres</div>
              </Container>
            </section>
          ) : (
            <>
              <Filters
                filters={freeArticlesBySupplier.filters}
                categories={categories.data}
                departments={departments.data}
                departmentCart={cart?.department}
              />

              <section className="p-section">
                <Container>
                  <div id={"d" + supplier.id}>
                    <div className="title-bloc-lot">
                      <p className="text-left">Prestataire: {supplier.name}</p>
                    </div>
                    {freeArticlesFilter.map((article) => {
                      return (
                        <div
                          key={"ld-" + supplier.id + "-article-" + article.id}
                        >
                          <Article
                            article={article}
                            isAuthenticated={isAuthenticated}
                            cart={cart}
                            setDetailToggleArticleId={setDetailToggleArticleId}
                            detailToggleArticleId={detailToggleArticleId}
                            user={user}
                            supplierId={supplier.id}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Container>
              </section>
            </>
          )}
        </>
      )}

      <Rgpd />
    </Layout>
  );
};

FreeCatalogSupplier.propTypes = {};

FreeCatalogSupplier.defaultProps = {};

export default FreeCatalogSupplier;
