import React from "react";
import { Field, FieldArray, Form, ErrorMessage } from 'formik';
import FieldWithError from "views/pages/FormSupplier/Common/Inputs/FieldWithError"
import { Row, Col } from 'reactstrap';
import { INITIAL_FORM_SUPPLIER } from "constants/constants"
import ScrollToError from "views/pages/FormSupplier/Common/ScrollToError"
import swal from 'sweetalert';

const Step9 = (props) => {

  const {
    formRef,
    values,
    setFieldValue
  } = props

  React.useEffect(() => {
    setFieldValue("authorizationSEPA", "yes", false);
  }, [setFieldValue]);
  
  const removeContractorConfirm = (arrayHelpers, index) => {
    swal({
      title: "Etes vous de sûr de vouloir faire cette suppression ? ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
          arrayHelpers.remove(index)
        }
    });
  }

  return (
    <Form className="mb-4" id="step*" ref={formRef}>
      <ScrollToError />
      <h4 className="mb-3">9.1 Présentation des moyens humains et matériels dédiés au marché</h4>
      <FieldWithError as="textarea" name={"presentationHuman"} placeholder={"Présentation (maximum 3000 caractères) ..."}/>

      <p className="mt-3">Seules des pièces jointes de CV et/ou certificats (5 maximum) sont pris en compte pour l’analyse. Tout autre chargement sera considéré comme inexistant.</p>
      <FieldArray
        name="proofs"
        render={arrayHelpers => {
          const proofs = values.proofs;
          return (
            <div>
              {proofs && proofs.map((proof, index) => (
                <>
                  <div className="card mb-4" key={index}>
                    <Row>
                      <Col xs="12" sm="12">
                        <label>Insérer CV et/ou certificat en pièce jointe (JPG ou PDF, taille max 8mo)</label>
                        <div className="form-inline">
                          {values?.proofs[0]?.proof ? <a href={values?.proofs[0]?.proof} target="_blank" rel="noopener noreferrer" className="fileLink">Voir mon fichier chargé</a> : null}
                          <input className="mr-sm-1 mx-sm-1 form-group my-1" name={`proofs.${index}.proofUpload`} type="file" onChange={(event) => {
                            setFieldValue(`proofs.${index}.proofUpload`, event.currentTarget.files[0]);
                          }} />
                        </div>
                        <ErrorMessage name={`proofs.${index}.proofUpload`} component="div" className="error-red"/>
                      </Col>
                    </Row>
                    <div className="text-right">
                      <button
                        className="btn-red mb-2"
                        type="button"
                        onClick={() => removeContractorConfirm(arrayHelpers, index)}
                      >
                        Supprimer ce fichier
                      </button>
                    </div>
                  </div>
                </>
              ))}

              {values.proofs.length < 5 &&
                <>
                  <button type="button" onClick={() => arrayHelpers.push(INITIAL_FORM_SUPPLIER.step9.proofs[0])} className="btn-grey">
                    Ajouter un fichier
                  </button>
                </>
              }

            </div>
          );
        }}
      />

      <h4>9.2 Présentation des modes opératoires pour l’exécution du marché</h4>
      <FieldWithError as="textarea" name={"presentationOperative"} placeholder={"Présentation (maximum 2000 caractères) ..."}/>
         {/* <Field type="radio" name="authorizationSEPA" value="yes" checked="yes"/> J’autorise la mise en place d’un prélèvement SEPA pour honorer le paiement des factures */}
         <input type="hidden" name="authorizationSEPA" value="yes" />
      <h4>9.3 Présentation des mesures en matière environnementale et sociale</h4>
      <FieldWithError as="textarea" name={"presentationEnvironment"} placeholder={"Présentation (maximum 2000 caractères) ..."}/>

    </Form>

  );

};

Step9.propTypes = {
};

Step9.defaultProps = {

};

export default Step9;
