import React from "react";
import { useDispatch } from "react-redux";
import Step12 from "./Step12"
import { formSupplierOperations } from "state/ducks/formSupplier";
import swal from 'sweetalert';

// Mémoire technique
const Step12Container = (props) => {

  const {
    initialValues,
  } = props

  const dispatch = useDispatch();

  const handleCloseAndGenerateForm = () => {
    swal({
      title: "Merci de valider votre signature",
      text: "Attention à la validation de la signature, vous ne pourrez plus revenir sur votre déclaration.",
      icon: "warning",
      buttons: ["Annuler", "Valider la signature"],
    })
    .then((confirm) => {
      if (confirm) {
        dispatch(formSupplierOperations.setStep12({closeAndGenerate: true}, initialValues.id));
      }
    });
  }

  const handleResetForm = () => {
    swal({
      title: "Etes-vous sûr de faire une nouvelle demande ?",
      text: "Vous perdrez la possibilité de télécharger votre accord cardre actuel, pensez à bien l'enregistrer avant de valider.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        dispatch(formSupplierOperations.resetCandidate());
      }
    });
  }

  return (
    <Step12 closeAndGenerate={initialValues.step12.closeAndGenerate} pdf={initialValues.step12.finalPDF} handleCloseAndGenerateForm={handleCloseAndGenerateForm} handleResetForm={handleResetForm}/>
  );

};

Step12Container.propTypes = {
};

Step12Container.defaultProps = {

};

export default Step12Container;
