import { uploadFile } from "./actions";

/**
 * Fonction pour l'uplod de media
 */
const upload = async (file, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(uploadFile(file))
    .then((success) => {
      return resolve(`api/media/${success.data.id}`);
    })
    .catch((error) => {
      return reject(error);
    })
    ;
  })
}

export { upload };
