import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Suppliers from "./Suppliers";
import { useDispatch } from "react-redux";

import { storeOperations } from "state/ducks/store";

const Categories = (props) => {
  const dispatch = useDispatch();

  const { categories, filters, cartSelector, user } = props;

  return (
    <section className="p-section">
      <Container>
        {filters.category === "all" ? (
          <Row>
            {categories.map((category) => {
              return (
                <Col
                  md="3"
                  key={category.id}
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <span
                    style={{
                      backgroundColor: "black",
                      background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('${category?.image?.contentUrl}')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      textAlign: "center",
                      alignItems: "center",
                      fontSize: 20,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      height: "300px",
                      margin: "5px",
                    }}
                  >
                    <span style={{ color: "white" }}>
                      <Link
                        to={{
                          pathname: `/catalogue/libre`,
                          search: "category=" + category.id,
                        }}
                        onClick={() => {
                          dispatch(
                            storeOperations.filtersFreeArticlesBySupplier(
                              category.id,
                              filters.department
                            )
                          );
                          dispatch(
                            storeOperations.getSuppliersFromFreeArticle(
                              category.id,
                              filters.department
                            )
                          );
                          window.scrollTo(0, 0);
                        }}
                      >
                        {category.name}
                      </Link>
                    </span>
                  </span>
                </Col>
              );
            })}
          </Row>
        ) : (
          <Suppliers
            departmentId={filters.department}
            categoryId={filters.category}
            cart={cartSelector}
            user={user}
          />
        )}
      </Container>
    </section>
  );
};

Categories.propTypes = {};

Categories.defaultProps = {};

export default Categories;
