import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { sessionOperations } from "state/ducks/session";

const Actions = (props) => {
  const { cart, isSupplier, seeAsSuper } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const session = useSelector((state) => state.session, shallowEqual);

  const removeCart = () => {
    if (window.confirm("Voulez-vous vraiment supprimer ce panier ?")) {
      dispatch(sessionOperations.removeCart(cart.id, "free")).then(
        (success) => {
          if (session.user.roles.includes("ROLE_SUPPLIER")) {
            return history.push("/compte/fournisseur/paniers");
          }
          history.push("/compte/paniers");
          window.location.reload();
          return;
        }
      );
    }
  };

  return (
    <Container>
      <Row id="navigation-detail-panier" className="mr-0 ml-0">
        <Col md="4">
          {(cart.status === 1 || (cart.status === 10 && isSupplier)) &&
            !seeAsSuper && (
              <button onClick={() => removeCart()}>Supprimer le panier</button>
            )}
        </Col>
        <Col md="4">
          {session?.user?.id && cart.id && cart.status === 1 && !seeAsSuper && (
            <Link
              to="/"
              onClick={() =>
                dispatch(
                  sessionOperations.changeActiveCart(
                    session.user.id,
                    session.user.roles,
                    cart.id,
                    "free"
                  )
                )
              }
            >
              <button>Compléter le panier</button>
            </Link>
          )}
        </Col>
        <Col md="4">
          {cart.orderFreeSuppliers && cart.orderFreeSuppliers.length > 0 && (
            <button
              onClick={() =>
                dispatch(sessionOperations.printCart(cart.id, "free"))
              }
            >
              Imprimer
            </button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

Actions.propTypes = {};

Actions.defaultProps = {};

export default Actions;
