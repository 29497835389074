import { getCustomerAccounts, addCustomerAccount, getCustomerCarts, updateCustomerAccount, removeCustomerAccount } from "./actions";

const createCustomerAsSuper = (adminId, values) => async (dispatch, getState) => {
  let data = {...values, admin: `api/customers/${adminId}`}
  return dispatch(addCustomerAccount(data));
}

const updateCustomerAsSuper = (customerId, values) => async (dispatch, getState) => {
  return dispatch(updateCustomerAccount(customerId, values));
}

const removeCustomerAsSuper = (customerId) => async (dispatch, getState) => {
  return dispatch(removeCustomerAccount(customerId));
}

export { getCustomerAccounts, createCustomerAsSuper, updateCustomerAsSuper, removeCustomerAsSuper, getCustomerCarts };
