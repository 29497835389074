import React from "react";
import { Link } from 'react-router-dom';
import Error from "views/universal/Errors/Api/Error";

const Layout = (props) => {

  return (
    <div id="formSupplier">
      <header className="text-center my-5" >
        <Link to="/" style={{textAlign: "center", lineHeight:"1", padding:"0"}}>
           <span style={{fontFamily:'CocoGoo', fontSize:"2.6em"}}>Centralis<sup style={{verticalAlign:"bottom", fontSize:"0.6em"}}>®</sup></span>
         </Link>
      </header>
      <div className="container-fluid">
        <h3 className="text-center">CATALOGUE ELECTRONIQUE DE CANDIDATURE ET D’OFFRE</h3>
        {props.children}
      </div>
      <Error />
    </div>
  );

};

Layout.propTypes = {
};

Layout.defaultProps = {

};

export default Layout;
