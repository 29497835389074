import React from "react";
import { Container, Row, Col } from "reactstrap";
import moniteurLogo from "assets/img/about/logo-moniteur.svg";
import wekaLogo from "assets/img/about/logo-wekafr-small.jpg";
import leseplLogo from "assets/img/about/lesepl.svg";

const MediaBloc = (props) => {
  return (
    <section id="section9" className="container section-bloc-about">
      <Row className="bloc-about">
        <Col
          sm={12}
          className="align-self-center content-bloc content-bloc-white"
        >
          <div style={{ textAlign: "center" }}>
            <h2 className="title-section">
              On <strong>parle</strong> de nous
            </h2>
            <br />
          </div>
          <Container>
            <Row style={{ minHeight: "100px" }}>
              <Col sm={3}>
                <img
                  src={moniteurLogo}
                  alt="moniteur logo"
                  style={{ maxWidth: "100px" }}
                />
              </Col>
              <Col sm={9}>
                <p>
                  « Le succès de Centralis est au rendez-vous »<br />
                  <a
                    href="https://www.lemoniteur.fr/article/centralis-la-centrale-d-achats-publics-qui-gagne-du-terrain.2098484"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fileLink"
                  >
                    Voir l'article
                  </a>
                </p>
              </Col>
            </Row>
            <Row style={{ minHeight: "100px" }}>
              <Col sm={3}>
                <img
                  src={wekaLogo}
                  alt="weka logo"
                  style={{ maxWidth: "100px" }}
                />
              </Col>
              <Col sm={9}>
                <p>
                  « Une solution clé en main pour les acheteurs publics »<br />
                  <a
                    href="https://www.weka.fr/actualite/commande-publique/article/centralis-la-petite-centrale-d-achats-publics-voit-grand-103971/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fileLink"
                  >
                    Voir l'article
                  </a>
                </p>
              </Col>
            </Row>
            <Row style={{ minHeight: "100px" }}>
              <Col sm={3}>
                <img
                  src={leseplLogo}
                  alt="lesepl logo"
                  style={{ maxWidth: "100px" }}
                />
              </Col>
              <Col sm={9}>
                <p>
                  « Une solution appréciée par plusieurs acheteurs publics dans
                  le secteur de la rénovation de patrimoine immobilier et de
                  l’aménagement du territoire »<br />
                  <a
                    href="https://www.lesepl.fr/epl-en-action/centralis-la-petite-centrale-dachats-publics-qui-monte-qui-monte/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fileLink"
                  >
                    Voir l'article
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </section>
  );
};

export default MediaBloc;
