import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Step10Alt from "./Step10Alt";
import { FormGroup } from "reactstrap";
import { formSupplierOperations } from "state/ducks/formSupplier";
import { Col } from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";

// Offre financière
const Step10AltContainer = (props) => {
  const { formRef, initialValues, viewMode } = props;

  const dispatch = useDispatch();

  const [zoneCode, setZoneCode] = useState(null); // Raccourcis pour le code de la zone géo
  const [zoneName, setZoneName] = useState(null); // Raccourcis pour le nom de la zone géo
  const [zoneCodes, setZoneCodes] = useState(null);
  const [isRegion, setIsRegion] = useState(null);

  /**
   * Recupère les prix d'un département ou de l'offre identique
   * Le io permet à la fonctione d'être utilisé dans handleIdenticalOfferChange
   */
  const getPrices = useCallback(
    (event, io) => {
      if (event) {
        event.preventDefault();
      }

      let params = { token: initialValues.token };
      if (initialValues.identicalOffer === true || io === true) {
        if (isRegion) {
          // params.regionCode = 'all';
        } else {
          // params.departmentCode = 'all';
        }
      } else {
        if (isRegion) {
          params.regionCode = zoneCode;
          params.regionName = zoneName;
        } else {
          params.departmentCode = zoneCode;
          params.departmentName = zoneName;
        }

        if (!params.departmentCode && !params.regionCode) {
          return swal({
            title: "Merci d'indiquer une lot géographique",
            icon: "warning",
          });
        }
      }

      dispatch(formSupplierOperations.getCandidatePrices(params));
    },
    [dispatch, initialValues, isRegion, zoneCode, zoneName]
  );

  /**
   * Permet de génerer le listing des départements
   */
  useEffect(() => {
    if (zoneCodes === null && initialValues?.step10?.departments) {
      let departmentsReactSelectFormat = [];
      initialValues.step10.departments.forEach((department, index) => {
        departmentsReactSelectFormat = [
          ...departmentsReactSelectFormat,
          {
            value: department.code,
            label: `${department.name} (${department.code})`,
          },
        ];
      });
      setZoneCodes(departmentsReactSelectFormat);
      if (
        initialValues?.step2?.regions &&
        initialValues.step2.regions.length > 0
      ) {
        setIsRegion(true);
      } else {
        setIsRegion(false);
      }
    }

    if (
      initialValues.token &&
      initialValues.identicalOffer === true &&
      initialValues.step10Alt.prices.length < 1 &&
      initialValues.isLoading === false
    ) {
      getPrices();
    }

    if (initialValues?.step10Alt?.filters?.region) {
      setZoneCode(initialValues.step10Alt.filters.region.code);
      setZoneName(initialValues.step10Alt.filters.region.name);
    } else if (initialValues?.step10Alt?.filters?.department) {
      setZoneCode(initialValues.step10Alt.filters.department.code);
      setZoneName(initialValues.step10Alt.filters.department.name);
    } else {
      setZoneCode(null);
      setZoneName(null);
    }
  }, [dispatch, initialValues, zoneCodes, getPrices]);

  /**
   * Sauvegarde les prix
   */
  const savePrices = (data) => {
    let params = { prices: data.prices, token: initialValues.token };
    if (isRegion) {
      params.regionCode = zoneCode;
      params.regionName = zoneName;
    } else {
      params.departmentCode = zoneCode;
      params.departmentName = zoneName;
    }

    dispatch(formSupplierOperations.updateCandidatePrices(params)).then(
      (result) => {
        getPrices();
      }
    );
  };

  /**
   * Change le département local
   */
  const onChangeDepartment = (value) => {
    if (isRegion) {
      dispatch(
        formSupplierOperations.setFilterCandidatePrices({
          region: { code: value.value, name: value.label },
        })
      );
    } else {
      dispatch(
        formSupplierOperations.setFilterCandidatePrices({
          department: { code: value.value, name: value.label },
        })
      );
    }
    dispatch(formSupplierOperations.resetCandidatePrices());
  };

  /**
   * Pour le changement du département, lance une alerte de confirmation avant
   */
  const confirmChangeDepartment = () => {
    swal({
      title:
        "N'oubliez pas de sauvegarder avant de changer de département. Etes vous de sûr de vouloir changer de département ? ",
      icon: "warning",
      dangerMode: true,
      buttons: ["Annuler", "OK"],
    }).then((confirm) => {
      if (confirm) {
        dispatch(
          formSupplierOperations.setFilterCandidatePrices({
            department: null,
            region: null,
          })
        );
        dispatch(formSupplierOperations.resetCandidatePrices());
      }
    });
  };

  /**
   * Gère le changement entre offre commune/différente pour les prix et met à jour l'attr identicalOffer qui détermine le type de prix (DepartmentInterlocutorPrice VS IdenticalPrice)
   * Ce changement va recharger le composant car il met à jour le props initialValues, donc pas de besoin de gérer l'état local ensuite
   */
  const handleIdenticalOfferChange = async (io) => {
    await dispatch(
      formSupplierOperations.updateIdenticalOffer(io, initialValues.id)
    );
    dispatch(
      formSupplierOperations.setFilterCandidatePrices({
        department: null,
        region: null,
      })
    );
    dispatch(formSupplierOperations.resetCandidatePrices());

    //initialValues ne semble pas être actualiser et reste avec son état local dans la fonction
    //Donc on passe io en argument
    if (io === true) {
      getPrices(null, io);
    }
  };

  return (
    <>
      {!(
        (initialValues?.step10Alt?.filters?.region ||
          initialValues?.step10Alt?.filters?.department) &&
        initialValues?.step10Alt?.prices.length > 0
      ) && (
        <Col>
          <label className="mr-4">
            <input
              name="identicalOffer"
              checked={initialValues.identicalOffer === true}
              type="radio"
              value={true}
              onChange={() => handleIdenticalOfferChange(true)}
            />{" "}
            L’offre financière est identique pour tous les lots géographiques
            candidatés. Y compris dans l’hypothèse d’une infructuosité de
            certains lots sur lesquels vous n’auriez pas postulé dans votre
            catégorie, mais pour lesquels vous auriez montré votre intérêt à la
            rubrique 1.2.
          </label>
          <label className="mt-1">
            <input
              name="identicalOffer"
              checked={initialValues.identicalOffer === false}
              type="radio"
              value={false}
              onChange={() => handleIdenticalOfferChange(false)}
            />{" "}
            L’offre financière est différente pour chaque lot géographique
            candidaté. Dans ce cas, il convient de remplir chaque lot
            séparément.
          </label>
        </Col>
      )}
      <Col>
        {initialValues.identicalOffer === false ? (
          <div className="login">
            <div className="block">
              {zoneCode && initialValues.step10Alt.prices.length ? (
                <>
                  <div>Lot géographique actuel: {zoneName}</div>
                  <button
                    type="submit"
                    className="btn-green mt-3"
                    onClick={confirmChangeDepartment}
                  >
                    Changer de lot géographique
                  </button>
                </>
              ) : (
                <form onSubmit={getPrices}>
                  <FormGroup>
                    <Select
                      required={true}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      placeholder="Choisir le lot géographique"
                      onChange={onChangeDepartment}
                      options={zoneCodes}
                    />
                    <button type="submit" className="btn-green mt-3">
                      Charger
                    </button>
                  </FormGroup>
                </form>
              )}
            </div>
            {initialValues.step10Alt.prices.length > 0 && zoneCode && (
              <Step10Alt
                formRef={formRef}
                onSubmit={savePrices}
                prices={initialValues.step10Alt.prices}
                viewMode={viewMode}
              />
            )}
          </div>
        ) : initialValues.identicalOffer === true ? (
          <>
            {initialValues.step10Alt.prices.length > 0 && (
              <Step10Alt
                formRef={formRef}
                onSubmit={savePrices}
                prices={initialValues.step10Alt.prices}
                viewMode={viewMode}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </Col>
    </>
  );
};

Step10AltContainer.propTypes = {};

Step10AltContainer.defaultProps = {};

export default Step10AltContainer;
